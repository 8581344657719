import React, { FC } from 'react';
import { DescriptionRow, DescriptionContentLabel, DescriptionContentValue } from './style';

interface ICarDescriptionRow {
  label: string;
  value: string;
}

const CarDescriptionRow: FC<ICarDescriptionRow> = ({ label, value }) => {
  return (
    <>
      <DescriptionRow>
        <DescriptionContentLabel>{label}</DescriptionContentLabel>
        <DescriptionContentValue>{value}</DescriptionContentValue>
      </DescriptionRow>
    </>
  );
};

export default CarDescriptionRow;
