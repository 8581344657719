import { Layout } from '../components';
import Container from '../components/Shared/Container';
import { HistoryLine } from '../components/Shared/HistoryLine';
import PageNotFoundContent from '../components/static/PageNotFoundContent';

const NotFound = (props: any) => {
  const footerData = props?.footerData?.data?.attributes;
  const { isFromComponent } = props;

  return (
    <>
      {isFromComponent ? (
        <Container mt="50px">
          <PageNotFoundContent />
        </Container>
      ) : (
        <Layout footerData={footerData}>
          <HistoryLine />
          <PageNotFoundContent />
        </Layout>
      )}
      ;
    </>
  );
};

export default NotFound;
