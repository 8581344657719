import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';

export const RandomCarTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const RecommendationsHolder = styled.div`
  height: 570px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const RecommendationsSection = styled.div`
  border: 1px solid ${colors.mischka};
  border-radius: 11px;
  padding: 20px;
`;
