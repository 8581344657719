import Config from '@pickles/shared/config';
import { IItem } from '@pickles/shared/models';
import Image from 'next/image';
import { FC, MouseEventHandler, useMemo } from 'react';
import { UserHooks } from '../../../../../app/redux/hooks';
import { formatDate } from '../../../../../util/helpers';
import {
  DateTextWrapper,
  FieldText,
  IconWrapper,
  PaymentHistoryBlock,
  PaymentHistoryWrapper,
  StatusLine,
  StatusText,
  ValueText,
  ViewText,
  ViewTextWrapper,
} from './style';
import purchasesViewContractIcon from '/public/icons/purchasesViewContractIcon.svg';

interface IPaymentHistory {
  item: IItem.Item;
  setLink: (link: string) => void;
  setIsViewerOpen: (bool: boolean) => void;
}

const PaymentHistory: FC<IPaymentHistory> = ({ item, setLink, setIsViewerOpen }) => {
  const token = UserHooks.useUserToken();
  const purchase = useMemo(() => item.purchase, [item]);

  const handleViewContract: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    const link = `${Config.API_URL}/marketplace/moc/${item.id}?jwt=${token}`;
    setLink(link);
    setIsViewerOpen(true);
  };

  return (
    <>
      <PaymentHistoryWrapper>
        {/* <PaymentStatusBar>You have 10 days for lorem ipsum dolor sit.</PaymentStatusBar> */}
        <PaymentHistoryBlock>
          <StatusLine />
          <div>
            <StatusText id="item_status">Sold</StatusText>
            {purchase?.soldAt && (
              <DateTextWrapper>
                <FieldText>Sold date:</FieldText>
                <ValueText id="sold_date">{formatDate(purchase.soldAt, 'DD/MM/yyyy')}</ValueText>
              </DateTextWrapper>
            )}
            {purchase?.payBy && (
              <DateTextWrapper>
                <FieldText>Pay by date:</FieldText>
                <ValueText id="pay_by_date">{formatDate(purchase.payBy, 'DD/MM/yyyy')}</ValueText>
              </DateTextWrapper>
            )}
            <DateTextWrapper>
              <FieldText>Tow by date:</FieldText>
              <ValueText id="tow_by_date">
                {purchase?.payBy ? formatDate(purchase.payBy, 'DD/MM/yyyy') : 'N/A'}
              </ValueText>
            </DateTextWrapper>
            {purchase?.showDueAmt && (
              <DateTextWrapper>
                <FieldText>Amount due:</FieldText>
                <ValueText id="amount_due">{purchase?.dueAmt ? `RM ${purchase?.dueAmt}` : 'N/A'}</ValueText>
              </DateTextWrapper>
            )}
            <ViewTextWrapper onClick={handleViewContract}>
              <IconWrapper>
                <Image src={purchasesViewContractIcon} alt="contract" />
              </IconWrapper>
              <ViewText id="view_contract">View contract</ViewText>
            </ViewTextWrapper>
          </div>
        </PaymentHistoryBlock>
      </PaymentHistoryWrapper>
    </>
  );
};

export default PaymentHistory;
