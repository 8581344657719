import React, { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { fpxDepositLimit, molpayDepositLimit } from '@pickles/shared/utils/constants';
import { SUPPORT_PHONE } from '@pickles/shared/utils/config';
import { appActions, userActions } from '@pickles/shared/redux/actions';
import { numberToAmountLabel, numberToLocaleString } from '@pickles/shared/utils/helpers';
import { api } from '@pickles/shared/services/api';
import { FPXOrderResponseData } from '@pickles/shared/services/api/response.types';

import { UserHooks } from '../../app/redux/hooks';
import { closeImg, masterCardImg, maybankImg, visaImg, FPXLogoSmall } from '../Shared/Icons';
import { FilterTypes } from '../Shared/Select';
import { Select } from '../Shared/Select/Select';

import {
  CloseIcon,
  TopUpBalanceRow,
  TopUpContainer,
  TopUpDescription,
  TopUpInfoBalance,
  TopUpMethodsText,
  TopUpMethodsCard,
  TopUpRow,
  TopUpSubmit,
  TopUpTitle,
} from '../TopUpModal/styles';
import { PaymentGatewaySettings, PaymentMethods } from '@pickles/shared/models/general.types';
import PaymentMethod from './PaymentMethod';


const defaultPaymentMethods: PaymentMethods[] = [
  { title: 'Maybank2U', name: 'fpx_mb2u', icons: [maybankImg], disabled: false },
  { title: 'Credit Card', name: 'credit', icons: [visaImg, masterCardImg], disabled: false },
  { title: 'Online Banking', name: 'fpx', icons: [FPXLogoSmall], disabled: true },
];

interface TopUpContentProps {
  isMobile: boolean;
  isOpened: boolean;
  closeIconHandler?: () => void;
}

const TopUpContent: FC<TopUpContentProps> = ({ isMobile, isOpened, closeIconHandler }) => {
  const [paymentMethods, setActivePaymentMethods] = useState(defaultPaymentMethods);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter()

  const [topUpValue, setTopUpValue] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('fpx_mb2u');
  const [isFPXtopupLoading, setIsFPXtopupLoading] = useState(false);

  const user = UserHooks.useUserAccount();

  const isBtnDisabled = topUpValue === 0;

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isOpened]);

  useEffect(() => {
    configurePaymentMethods()
  }, []);

  const configurePaymentMethods = async () => {
    let paymentSettings: PaymentGatewaySettings
    try {
      paymentSettings = await api.getPaymentGatewaySettings();
    } catch (error) {
      console.error('failed to fetch payment gateway settings', error);
      return;
    }

    if (!paymentSettings?.id) {
      return;
    }

    let methods = defaultPaymentMethods;

    methods.forEach((method) => {
      if (['fpx_mb2u', 'credit'].includes(method.name)) {
        method.disabled = !paymentSettings.molpayEnabled;
      }

      if (['fpx'].includes(method.name)) {
        method.disabled = !paymentSettings.fpxEnabled;
      }
    });

    setActivePaymentMethods(methods);
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = +e.target.value.replace(/[^0-9]/g, '');
    setTopUpValue(num);
  };

  const checkPaymentOptionEnabled = async () => {
    const paymentSettings = await api.getPaymentGatewaySettings();
    if (!paymentSettings.fpxEnabled && paymentMethod === 'fpx') {
      return false;
    }

    if (!paymentSettings.molpayEnabled && ['credit', 'fpx_mb2u'].includes(paymentMethod)) {
      return false;
    }

    return true;
  }

  const onTopUpPress = async () => {
    // Fetch payment settings and check if payment disabled
    const isPaymentOptionEnabled = await checkPaymentOptionEnabled();
    if (!isPaymentOptionEnabled) {
      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('errors:payment_method_disabled'),
        }),
      );

      return;
    }

    if (paymentMethod === 'fpx') {
      return topUpViaFPX()
    }

    if (topUpValue >= molpayDepositLimit.min && topUpValue <= molpayDepositLimit.max) {
      dispatch(userActions.topUp(`${topUpValue}`, paymentMethod));
    } else {
      const fallbackValue = topUpValue < molpayDepositLimit.min ? molpayDepositLimit.min : molpayDepositLimit.max;
      setTopUpValue(fallbackValue);

      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('errors:transaction_limit', {
            minAmount: numberToAmountLabel(molpayDepositLimit.min),
            maxAmount: numberToAmountLabel(molpayDepositLimit.max),
          }),
        }),
      );
    }
  };

  const topUpViaFPX = async () => {
    if (topUpValue < fpxDepositLimit.min || topUpValue > fpxDepositLimit.max) {
      return dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('fpx:transaction_limit', {
            minAmount: numberToAmountLabel(fpxDepositLimit.min),
            maxAmount: numberToAmountLabel(fpxDepositLimit.max),
          }),
        }),
      );
    }

    setIsFPXtopupLoading(true);
    let order: FPXOrderResponseData;
    try {
      order = await api.createFPXOrder(topUpValue);
      router.push(`/payment/fpx?orderId=${order.externalOrderId}`);
    } catch (error) {
      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('errors:server_error', {
            phone: SUPPORT_PHONE,
          }),
        }),
      );
      return;
    } finally {
      setIsFPXtopupLoading(false);
    }
  }

  const renderPaymentMethods = () => {
    return paymentMethods.filter(method => !method.disabled).map((method) => {
      const { title, name, icons } = method;
      const isMethodActive = paymentMethod === name;

      return (
        <PaymentMethod
          key={name}
          activeType={isMethodActive}
          icons={icons}
          paymentMethod={name}
          setPaymentMethod={setPaymentMethod}
          text={title}
        />
      );
    });
  }

  return (
    <TopUpContainer>
      {!isMobile && (
        <CloseIcon onClick={closeIconHandler}>
          <Image src={closeImg} alt="close icon" />
        </CloseIcon>
      )}
      <TopUpTitle>{t('labels:add_money')}</TopUpTitle>
      <TopUpDescription>{t('labels:all_deposits_refundable')}</TopUpDescription>
      <TopUpInfoBalance>
        <TopUpBalanceRow>
          <Select options={['RM']} selectedIndex={0} type={FilterTypes.Select}></Select>
          <input
            type="text"
            ref={inputRef}
            value={numberToLocaleString(topUpValue)}
            onChange={onChange}
            placeholder="0"
          />
        </TopUpBalanceRow>
        <div>
          <div>
            {t('labels:available_balance')}: {numberToAmountLabel(user?.availableBalance)}
          </div>
          <TopUpRow>
            <div>
              {t('labels:blocked_balance_alt')}: {numberToAmountLabel(user?.blockedAmount)}
            </div>
            <div>{t('labels:no_fees')}</div>
          </TopUpRow>
        </div>
      </TopUpInfoBalance>
      <TopUpMethodsText>
        {t('labels:select_payments')}
      </TopUpMethodsText>
      <TopUpMethodsCard>
        {renderPaymentMethods()}
      </TopUpMethodsCard>
      <button id="molpay_btn" hidden />
      <TopUpSubmit onClick={onTopUpPress} type="button" disabled={isBtnDisabled || isFPXtopupLoading}>
        + {t('labels:top_up_securely')}
      </TopUpSubmit>
    </TopUpContainer>
  );
};

export default TopUpContent;
