import Image from 'next/image';
import { FC, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import { IAuction } from '@pickles/shared/models';
import { AUCTION_TYPES } from '@pickles/shared/models/auction.types';
import { imageUrlWithResizer } from '@pickles/shared/utils/helpers';
import {
  CarDetailSliderWrapper,
  ItemContainer,
  ImgBackBtn,
  ImgNextBtn,
  ImgCountLine,
  CarDetailStatus,
  CarDetailEditBtn,
  CarDetailCountWrapper,
  CountNum,
  ThumbnailBackBtn,
  ThumbnailNextBtn,
  CarImageContainer,
} from './style';

import useScreenSize from '../../../../hooks/useScreenSize';
import {
  backArrow,
  carDetailImgCountIcon,
  nextArrow,
  switchThumbMailIcon,
} from '../../../Shared/Icons';
import { PortalComponent } from '../../../Shared/Portal';

interface CarDetailSliderProps {
  images: string[];
  eventType?: IAuction.AuctionTypes;
  isMobile?: boolean;
  isImgGalleryOpen?: boolean;
  imgGalleryOpenHandler?: () => void;
  width?: string;
  height?: string;
  setSelectedImg: (num: number | null) => void;
  selectedImg: number | null;
  currentSlideIndex: number;
  useResizer?: boolean;
}

const CarDetailSlider: FC<CarDetailSliderProps> = ({
  images,
  eventType,
  isImgGalleryOpen,
  imgGalleryOpenHandler,
  width,
  height = '460px',
  setSelectedImg,
  selectedImg,
  useResizer = true,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useScreenSize();
  const [imgArray, setImgArray] = useState<ReactImageGalleryItem[]>([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [slide, setSlide] = useState<any>();

  useEffect(() => {
    slide && setCurrentSlideIndex(slide.state.currentIndex);
  }, [slide]);

  const nextArrowClickHandler = () => {
    if (currentSlideIndex !== images.length - 1) {
      slide.slideRight();
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const prevArrowClickHandler = () => {
    if (currentSlideIndex !== 0) {
      slide.slideLeft();
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const setSelectedImgIndex = (index: number | null) => {
    setSelectedImg(index);
    if (imgGalleryOpenHandler) {
      imgGalleryOpenHandler();
    }
  };

  useEffect(() => {
    const img: ReactImageGalleryItem[] = images?.map((image) => {
      return {
        original: useResizer ? imageUrlWithResizer(image, 750) : image,
        thumbnail: useResizer ? imageUrlWithResizer(image, 94) : image,
      };
    });
    setImgArray(img);
  }, [images]);

  const handleSlideClick = (item: ReactImageGalleryItem) => {
    const currentIndex = imgArray.findIndex((image) => image.original === item.original);
    setSlide((prev: any) => {
      prev.state.currentIndex = currentIndex;
      return prev;
    });
    setCurrentSlideIndex(currentIndex);
  };

  return (
    <CarDetailSliderWrapper width={width}>
      {isImgGalleryOpen && (
        <ImgCountLine>
          {currentSlideIndex + 1} / {imgArray.length}
        </ImgCountLine>
      )}
      <ImageGallery
        items={imgArray}
        showThumbnails={!isMobile}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={true}
        infinite={false}
        startIndex={currentSlideIndex}
        ref={(slide) => setSlide(slide)}
        onSlide={(currentIndex) => setCurrentSlideIndex(currentIndex)}
        renderLeftNav={(onClick, disabled) => {
          return (
            <ImgBackBtn
              onClick={(event: any) => {
                if (disabled) {
                  return;
                }
                prevArrowClickHandler();
                onClick(event);
              }}
            >
              <Image src={backArrow} alt="back arrow" />
            </ImgBackBtn>
          );
        }}
        renderRightNav={(onClick, disabled) => {
          return (
            <ImgNextBtn
              onClick={(event: any) => {
                if (disabled) {
                  return;
                }
                nextArrowClickHandler();
                onClick(event);
              }}
            >
              <Image src={nextArrow} alt="next arrow" />
            </ImgNextBtn>
          );
        }}
        renderThumbInner={(item) =>
          item.thumbnail && (
            <Image
              src={item.thumbnail}
              width="100%"
              height="63px"
              layout="responsive"
              onClick={() => handleSlideClick(item)}
              alt=""
            />
          )
        }
        renderItem={(image) => {
          return (
            <ItemContainer>
              {eventType && (
                <CarDetailStatus id="auction-type">
                  {eventType === AUCTION_TYPES.STATIC
                    ? t('status:e_bidding')
                    : t('status:live_auction')}
                </CarDetailStatus>
              )}

              {/* TODO: To implement notes function later */}
              {/* <CarDetailEditBtn id="car-detail-edit-button">
                <Image src={carDetailEditIcon} alt="car detail edit icon" />
              </CarDetailEditBtn> */}
              {!isImgGalleryOpen && (
                <CarDetailCountWrapper
                  id="car-detail-count-wrapper"
                  onClick={imgGalleryOpenHandler}
                >
                  <Image src={carDetailImgCountIcon} alt="counter icon" />
                  <CountNum>
                    {currentSlideIndex + 1}/{imgArray.length}
                  </CountNum>
                </CarDetailCountWrapper>
              )}

              <CarImageContainer
                width={width}
                height={height}
                isMobile={isMobile}
                id="car-image-container"
              >
                <Image
                  alt=""
                  src={image.original}
                  layout="fill"
                  objectFit="cover"
                  priority={true}
                  onClick={() => setSelectedImgIndex(slide.state.currentIndex)}
                />
              </CarImageContainer>
            </ItemContainer>
          );
        }}
      />
      {images && images.length && (
        <PortalComponent containerId="image-gallery-thumbnails">
          <ThumbnailBackBtn isDisable={currentSlideIndex === 0} onClick={prevArrowClickHandler}>
            <Image src={switchThumbMailIcon} alt="back-slide" />
          </ThumbnailBackBtn>
          <ThumbnailNextBtn
            isDisable={currentSlideIndex === images.length - 1}
            onClick={nextArrowClickHandler}
          >
            <Image src={switchThumbMailIcon} alt="next-slide" />
          </ThumbnailNextBtn>
        </PortalComponent>
      )}
    </CarDetailSliderWrapper>
  );
};

export default CarDetailSlider;
