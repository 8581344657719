import { object, string, boolean, InferType } from 'yup';

export const loginSchema = object({
  email: string().required('Email is required'),
  password: string()
    .min(8)
    .required('Password is required'),
  rememberMe: boolean().notRequired(),
});

export type ILoginForm = InferType<typeof loginSchema>;
