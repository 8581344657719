import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface ILiveAuctionStatusBadge {
  isCurrent?: boolean;
}

export const SuggestedCarItemWrapper = styled.div<ILiveAuctionStatusBadge>`
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 6px;
  border: ${({isCurrent}) => isCurrent? `2px solid ${colors.blumine_main_blue}`: 'none'};
  border-radius: 10px;

  
`;

export const SuggestedCarImgWrapper = styled.div<{isSold?: boolean}>`
  margin-right: 15px;
  opacity: ${({isSold = false}) => isSold ? '0.4': '1'};
  height: 96px;
  img {
    border-radius: 7px;
    object-fit: cover;
  }
`;

export const SuggestedCarInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div{
    line-height: 20px;
  }
  // justify-content: space-around;
`;

export const SuggestedCarTitle = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  line-height: 26px;
  color: #263a54;
  @media (max-width: ${sizes.XS}){
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 75%;
  }
`;

// export const SuggestedStockContainer = styled.div`
//   font-family: BasierCircle;
//   font-size: 12px;
//   color: #334d70;
//   margin-bottom: 3px;
//   line-height: 20px;
//   span{
//     font-size: 13px;
//     font-family: BasierCircle-Medium;
//   }
// `;

export const MileageAndState = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 13px;
  line-height: 20px;
  color: #334d70;
  margin-bottom: 3px;
  display: flex;
 
`;

export const LiveAuctionStatusBadge = styled.div<ILiveAuctionStatusBadge>`
  padding: 4px;
  text-transform: uppercase;
  color: ${({ isCurrent }) => (isCurrent ? `${colors.white}` : `${colors.waikawa_gres}`)};
  font-size: 12px;
  line-height: 14px;
  background: ${({ isCurrent }) =>
    isCurrent ? `${colors.blumine_main_blue}` : `${colors.zircon_light_blue}`};
  border-radius: 6px;
  flex-grow: 0;
  font-family: BasierCircle-Semibold;
  margin-right: ${({ isCurrent }) => (isCurrent ? '0' : '8px')};
`;

export const LiveAuctionStatusPrice = styled.div`
  font-size: 13px;
  color: ${colors.san_juan_grey};
  letter-spacing: -0.01em;
  font-family: BasierCircle;
  font-weight: 400;
`;
