import _ from 'lodash';
import moment from 'moment';
import Image from 'next/image';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { WEBAPP_ITEM_ROUTE_PATH } from '@pickles/shared/config';
import { getAuctionItemRef } from '@pickles/shared/utils/routes';
import { formatDurationDiff } from '@pickles/shared/utils/date';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import MobileHeaderLine from '../Shared/MobileHeaderLine';
import { AppHooks, UserHooks } from '../../app/redux/hooks';
import { getDefaultImage } from '../../util/helpers';

import {
  BlockedDepositAmount,
  BlockedDepositContent,
  BlockedDepositGridRow,
  BlockedDepositHead,
  BlockedDepositRemaining,
  BlockedDepositType,
  BlockedDepositTypeAssets,
  BlockedDepositTypeRow,
  CarImageWrapper,
  EmptyBlockedDeposit,
} from './BlockedDeposit.style';
import { TransactionsGrid } from './Transactions.style';

interface IBlockedDeposit {
  closeScreen?: () => void;
  isMobile?: boolean;
}

export const BlockedDeposit: FC<IBlockedDeposit> = ({ isMobile = false, closeScreen }) => {
  const { t } = useTranslation();
  const current = AppHooks.useApplicationTimer();
  const winningBids = UserHooks.useWinningBids();
  // TODO: Add eventCategory to endpoint so we can get corresponding default image
  const defaultImage = getDefaultImage('');
  const router = useRouter();

  const rows = winningBids.map((item) => {
    const duration = formatDurationDiff(current, item?.endAt);
    const auctionItemRef = getAuctionItemRef(item);
    const itemHref = `/${WEBAPP_ITEM_ROUTE_PATH}/${auctionItemRef}`;

    const handleBlockedDepositClick = () => {
      router.push(itemHref)
    };
  
    return (
      <BlockedDepositGridRow key={`transaction-${item.depositAmt}`}>
        <BlockedDepositContent onClick={() => handleBlockedDepositClick()} id="blocked_deposit_item">
          <BlockedDepositAmount>
            <CarImageWrapper>
              <Image
                src={item?.image || defaultImage}
                alt={item.name}
                layout="fill"
                objectFit="cover"
                id="item_image"
              />
            </CarImageWrapper>
            <div>
              <div id="item_name">{item.name}</div>
              <div id="item_info">
                {_.upperCase(item.saleEventType)} - {_.capitalize(item.conditionType)} -{' '}
                {moment(item.blockedDatetime).format('MMM DD, h:mm A')}
              </div>
            </div>
          </BlockedDepositAmount>
          <BlockedDepositType>
            <div>
              <BlockedDepositTypeAssets id="deposit_amount">
                {numberToAmountLabel(item.depositAmt)}
              </BlockedDepositTypeAssets>
              {moment(item?.endAt) > moment() && (
                <BlockedDepositTypeRow>
                  <div>Time remaining: </div>
                  <BlockedDepositRemaining id="timer">{duration}</BlockedDepositRemaining>
                </BlockedDepositTypeRow>
              )}
            </div>
          </BlockedDepositType>
        </BlockedDepositContent>
      </BlockedDepositGridRow>
    );
  });

  return (
    <>
      {isMobile && closeScreen && (
        <MobileHeaderLine
          hasShareBtn={false}
          onBackBtnClick={closeScreen}
          title={'Blocked deposits'}
        />
      )}
      <TransactionsGrid>
        <BlockedDepositHead>
          <div>{t('labels:item')}</div>
          <div>{t('labels:amount')}</div>
        </BlockedDepositHead>
        {rows.length ? (
          <div>{rows}</div>
        ) : (
          <EmptyBlockedDeposit>{t('labels:no_blocked_deposits')}</EmptyBlockedDeposit>
        )}
      </TransactionsGrid>
    </>
  );
};
