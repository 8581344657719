import sizes from './../../../util/sizes';
import styled from 'styled-components';

export const HistoryLineWrapper = styled.div`
  display: flex;
  margin: 24px auto;
  margin-bottom: 12px;
  max-width: ${sizes.XL};
  font-family: 'BasierCircle';
  overflow: hidden;

  div {
    margin-right: 10px;
    color: #596e8a;
    font-size: 14px;
    text-transform: capitalize;
    font-family: BasierCircle;
    cursor: pointer;
    white-space: nowrap;
  }
  > div:last-child {
    color: #263a54;
    font-family: BasierCircle-Medium;
  }
  > div:not(:last-child) {
    &:after {
      content: '';
      border-top: 2px solid #596e8a;
      border-right: 2px solid #596e8a;
      transform: rotate(45deg);
      margin-left: 5px;
      height: 6px;
      width: 6px;
      display: inline-block;
    }
  }
  @media (max-width: ${sizes.XL}) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (max-width: ${sizes.L}) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media (max-width: ${sizes.M}) {
    margin-left: 50px;
    margin-right: 50px;
    display: none;
  }
`;
