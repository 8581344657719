import { WEBAPP_ITEM_ROUTE_PATH } from '@pickles/shared/config';
import { IItem } from '@pickles/shared/models';
import { getLotInfo, numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { getAuctionItemRef } from '@pickles/shared/utils/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { LiveAuctionHooks } from '../../../app/redux/hooks';
import { StockAndLot } from '../../ItemDetails/components/StockAndLot';
import { PlaceMapMessage } from '../Messages/PlaceMapMessage';
import {
  LiveAuctionStatusBadge,
  LiveAuctionStatusPrice,
  MileageAndState,
  SuggestedCarImgWrapper,
  SuggestedCarInfoWrapper,
  SuggestedCarItemWrapper,
  SuggestedCarTitle,
} from './style';

interface ISuggestedCarsItem {
  item: IItem.Item;
  isFromLiveAuction?: boolean;
  isMobile?: boolean;
  onItemClick?: (item: IItem.Item) => void;
}

const parseLotNumber = (lotNum: string): number => Number(lotNum.replace(/[a-zA-Z]+/g, ''));

const SuggestedCarsItem: FC<ISuggestedCarsItem> = ({
  item,
  isFromLiveAuction = false,
  isMobile,
  onItemClick,
}) => {
  const router = useRouter();
  const liveAuctionCurrentItem = LiveAuctionHooks.useCurrentItem();

  const { minStartingBidAmt, image, name, stockNum, saleEvent } = item;

  const auctionItemRef = getAuctionItemRef(item);

  const conditionType = item?.conditionType || item?.sourceType;

  const isCurrentItem =
    (item.saleEvent?.lotNum &&
      liveAuctionCurrentItem?.ItemNum &&
      item.saleEvent?.lotNum === liveAuctionCurrentItem?.ItemNum) ||
    false;
  const isPreviousItem: boolean =
    (item.saleEvent?.lotNum &&
      liveAuctionCurrentItem?.ItemNum &&
      parseLotNumber(item.saleEvent.lotNum) < parseLotNumber(liveAuctionCurrentItem?.ItemNum)) ||
    false;
  const isUpcomingItem: boolean =
    (item.saleEvent?.lotNum &&
      liveAuctionCurrentItem?.ItemNum &&
      parseLotNumber(item.saleEvent.lotNum) > parseLotNumber(liveAuctionCurrentItem?.ItemNum)) ||
    false;

  const handleSuggestedCarInfoPage = useCallback(() => {
    if (onItemClick && isFromLiveAuction) {
      onItemClick(item);
    } else if (!isCurrentItem) {
      router.push(`/${WEBAPP_ITEM_ROUTE_PATH}/${auctionItemRef}`);
    }
  }, [auctionItemRef, isCurrentItem, isFromLiveAuction, item, onItemClick, router]);

  const renderLiveAuctionItemStatus = () => {
    if (isPreviousItem) {
      return (
        <>
          {/* <LiveAuctionStatusBadge isCurrent={false}>sold</LiveAuctionStatusBadge> */}
          {/* TODO: To display the sold amount accordingly instead of minStartingBidAmt */}
          <LiveAuctionStatusPrice>{numberToAmountLabel(minStartingBidAmt)}</LiveAuctionStatusPrice>
        </>
      );
    }

    if (isCurrentItem) {
      return <LiveAuctionStatusBadge isCurrent={true}>current</LiveAuctionStatusBadge>;
    }

    if (isUpcomingItem) {
      return (
        <LiveAuctionStatusPrice>{numberToAmountLabel(minStartingBidAmt)}</LiveAuctionStatusPrice>
      );
    }
  };

  return (
    <SuggestedCarItemWrapper
      isCurrent={isFromLiveAuction && isCurrentItem}
      onClick={() => !isPreviousItem && handleSuggestedCarInfoPage()}
      id="recommended_item"
    >
      <SuggestedCarImgWrapper isSold={isFromLiveAuction && isPreviousItem}>
        <Image
          src={image || '/images/defaultImage.png'}
          alt={'car'}
          width={isMobile ? '100px' : '121px'}
          height={isMobile ? '75px' : '96px'}
          layout="fixed"
        />
      </SuggestedCarImgWrapper>
      <SuggestedCarInfoWrapper>
        <SuggestedCarTitle>{name}</SuggestedCarTitle>

        <StockAndLot stockNum={stockNum} lotNum={saleEvent?.lotNum} />

        <MileageAndState>
          {getLotInfo(item.vehicle?.odometer, item.vehicle?.odoUnits, conditionType)}
        </MileageAndState>
        {!isFromLiveAuction && item.yard ? (
          <PlaceMapMessage clickable={false} name={item.yard.city} />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {renderLiveAuctionItemStatus()}
          </div>
        )}
      </SuggestedCarInfoWrapper>
    </SuggestedCarItemWrapper>
  );
};

export default SuggestedCarsItem;
