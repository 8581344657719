import React, { FC } from 'react';
import Image from 'next/image';
import {
  BackBtnImgWrapper,
  BidHistoryComponentWrapper,
  BidHistoryText,
  GreyWrapper,
  HistoryWrapper,
  TopWrapper,
} from './style';

import bidHistoryBackIcon from '/public/icons/bidHistoryBackIcon.svg';
import BidHistoryItem from './BidHistoryItem';
import { UserHooks, EbidAuctionHooks } from '../../../../app/redux/hooks';
import { IEbid } from '@pickles/shared/models';

interface iBidHistoryModal {
  itemId: number;
  onClose: () => void;
  isMobile?: boolean;
}

const BidHistoryModal: FC<iBidHistoryModal> = ({ itemId, onClose, isMobile }) => {
  const bidsHistory = EbidAuctionHooks.useBidHistory(itemId);
  const account = UserHooks.useUserAccount();

  return (
    <BidHistoryComponentWrapper>
      <GreyWrapper onClick={onClose} />
      <HistoryWrapper isMobile={isMobile}>
        <TopWrapper>
          <BackBtnImgWrapper>
            <Image alt='back' src={bidHistoryBackIcon} onClick={onClose} layout="intrinsic" />
          </BackBtnImgWrapper>
          <BidHistoryText>Bid history</BidHistoryText>
        </TopWrapper>
        <div
          style={{
            position: 'relative',
          }}
        >
          {bidsHistory.map((bid: IEbid.HistoryType, index) => {
            const isLatestBid = index === 0;
            const isMyBid = account?.id === +bid.clientUserId;
            return (
              <BidHistoryItem
                isFirstItem={index === 0}
                key={bid.id}
                date={bid.timestamp}
                isActive={isLatestBid}
                isFromUser={isMyBid}
                // location={bid.location}
                price={bid.amtAccepted}
              />
            );
          })}
          <div
            style={{
              position: 'absolute',
              height: 'calc(100% - 75px)',
              width: '2px',
              left: '13px',
              top: '0',
              transform: 'translateX(-50%)',
              border: '1px dashed #D1DCF9',
              zIndex: 3,
            }}
          ></div>
        </div>
      </HistoryWrapper>
    </BidHistoryComponentWrapper>
  );
};

export default BidHistoryModal;
