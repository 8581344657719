import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '../Shared/Container';
import { TimerIcon } from '../Shared/Icons';
import Typography from '../Shared/Typography';

const WaitingSection = () => {
  const { t } = useTranslation();
  return (
    <WaitingWrapper mb="40px">
      <Container flexDirection="column" maxWidth="420px">
        <TimerIcon />
        <Container mt="26px" mb="12px">
          <WaitingInfo variant="h2" algin="center">
            {t('labels:thanks_for_attend')}
          </WaitingInfo>
        </Container>
        <WaitingMessage>{t('labels:begin_shortly')}</WaitingMessage>
      </Container>
    </WaitingWrapper>
  );
};

export default WaitingSection;

const WaitingWrapper = styled(Container)`
  width: 100%;
  height: 70vh;
  display: flex;
  flex: 0.7;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.mischka};
  border-radius: 11px;
`;

const WaitingInfo = styled(Typography)`
  font-size: 38px;
  line-height: 40px;
`;

const WaitingMessage = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
`;
