import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';

import { SlidingRight } from '../../styles/animations';

type ProfileTabPanelContainerProps = {
  isActive?: boolean;
};

export const ProfileTabPanelContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.mischka};
  width: 100%;
  * {
    font-family: BasierCircle;
  }
`;

export const ProfileTabPanelItem = styled.div<ProfileTabPanelContainerProps>`
  color: ${({ isActive }) => (isActive ? '#263A54' : '#263A54')};
  opacity: ${({ isActive }) => (isActive ? 'none' : '0.7')};
  font-weight: ${({ isActive }) => (isActive ? '500' : 'none')};
  border-bottom: 2px solid ${({ isActive }) => (isActive ? COLORS.rhino_black : 'transparent')};
  font-size: 19px;
  line-height: 26px;
  height: 42px;
  margin-right: 28px;
  animation: ${({ isActive }) => (isActive ? SlidingRight : 'none')};
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  font-family: BasierCircle;
`;

export const ProfileTabGridHead = styled.div`
  display: grid;
  grid-template-columns: 60% 30% 10%;
  > div {
    text-align: start;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${COLORS.bali_hai_grey};
    font-family: BasierCircle;
    width: 100%;
  }
  > div:last-child {
    padding-left: 15px;
  }
`;
