import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';

interface IActiveItem {
  isActive: boolean;
}

interface IBidHistoryItemWrapper {
  isFirstItem: boolean;
}

export const BidHistoryItemWrapper = styled.div<IBidHistoryItemWrapper>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-top: ${({ isFirstItem }) => (isFirstItem ? '13px' : '0')};
  z-index: 999999;
`;

export const DotWrapper = styled.div<IActiveItem>`
  margin-top: 5px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: ${({ isActive = false }) => (isActive ? '2px solid #D1DCF9' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 19px;
  z-index: 9;
  background-color: white;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colors.blumine_main_blue};
  z-index: 9;
`;

export const InfoWrapper = styled.div<IActiveItem>`
  color: ${({ isActive }) => (isActive ? colors.blumine_main_blue : colors.rhino_black)};
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const PriceText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  margin-right: 6px;
`;
export const YouBadge = styled.div`
  padding: 3px 10px;
  background: ${colors.cerulean_blue};
  text-transform: uppercase;
  border-radius: 6px;
  color: ${colors.white};
  font-family: BasierCircle-Semibold;
  font-size: 12px;
`;

export const TextWrapper = styled.div`
  font-family: BasierCircle;
  font-size: 13px;
  line-height: 1.5;
  /* color: ${colors.blumine_main_blue}; */
`;

export const LocationText = styled.span`
  font-family: BasierCircle-Medium;
  font-size: 15px;
`;
