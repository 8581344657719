import colors from '@pickles/shared/utils/colors';
import styled, { StyledComponent } from 'styled-components';

export const BidHistoryComponentWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  position: fixed;
`;

export const GreyWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(49, 54, 62, 0.6);
`;

export const HistoryWrapper: any = styled.div`
  width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '330px'};
  flex-shrink: 0;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  padding-top: 23px;
  padding-left: 25px;
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const BidHistoryText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  color: ${colors.blumine_main_blue};
`;

export const BackBtnImgWrapper = styled.div`
  width: 8px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 29px;
  cursor: pointer;
`;
