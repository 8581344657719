import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

interface IAuctionTypePicker {
  isActive: boolean;
}

export const AuctionsContentWrapper = styled.div`
  max-width: ${sizes.XL};
  margin: 0 auto 112px;

  @media (max-width: ${sizes.S}) {
    margin: 10px;
  }
`;

export const TypePickerWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const AuctionsText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  color: ${colors.rhino_black};
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const AuctionTypePickerWrapper = styled.div`
  position: relative;
`;

export const ImageWrapper = styled.div`
  margin-left: 9px;
`;

export const AuctionTypePicker = styled.div<IAuctionTypePicker>`
  /* width: 137px; */
  padding: 12px 13px 12px;
  height: 46px;
  border: ${({ isActive }) =>
    isActive ? `1px solid ${colors.blumine_main_blue}` : `1px solid ${colors.mischka}`};
  border-radius: 12px;
  color: ${colors.san_juan_grey};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  margin-right: 12px;
  cursor: pointer;
  line-height: 20px;
`;

export const ItemTypePicker = styled.div`
  /* width: 115px; */
  padding: 12px 13px;
  height: 46px;
  border: 1px solid ${colors.mischka};
  border-radius: 12px;
  color: ${colors.san_juan_grey};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  cursor: pointer;
`;

export const AuctionGridWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 370px);
  gap: 30px;
  justify-content: center;
  justify-items: center;

  @media (max-width: ${sizes.XL}) {
    grid-template-columns: repeat(2, 370px);
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 1fr;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.mischka};
  padding-left: 38px;
  border-radius: 8px;
  position: relative;
  font-family: BasierCircle;
  font-size: 15px;
  color: ${colors.new_gray};

  &:focus {
    outline: none;
  }
`;
