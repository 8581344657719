import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';
import colors from '@pickles/shared/utils/colors';

interface IForm {
  isMobile?: boolean;
}

export const Form = styled.form<IForm>`
  width: 100%;
  max-width: ${sizes.XL};
  padding: 40px 53px 48px;
  margin: 0 auto;
  background: ${COLORS.zircon_light_blue};
  border-radius: 12px;

  @media (max-width: ${sizes.S}) {
    padding: 56px 20px;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.S}) {
    flex-wrap: no-wrap;
    flex-direction: column;
  }
`;

export const CheckboxBlock = styled.div`
  margin-bottom: 32px;
  max-width: ${sizes.XL};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const InsideHeader = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${colors.rhino_black};
  text-align: left;
  margin-bottom: 18px;
  max-width: ${sizes.XL};
`;

export const ContactTextWrapper = styled.div`
  max-width: 750px;
  text-align: center;

  > p {
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 42px;
    max-width: ${sizes.XL};
    padding-left: 20px;
    padding-right: 20px;
  }

  a {
    color: ${colors.cerulean_blue};
  }

  @media (max-width: ${sizes.M}) {
    > p {
      margin-top: 18px;
      margin-bottom: 22px;
    }
  }

  @media (max-width: ${sizes.S}) {
    width: 100%;
  }
`;
