import { FC, useMemo, useState } from 'react';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IItem } from '@pickles/shared/models';
import { checkAuctionEnded, numberToAmountLabel, numberToLocaleString } from '@pickles/shared/utils/helpers';
import { EbidAuctionHooks, LiveAuctionHooks, UserHooks } from '../../app/redux/hooks';
import colors from '@pickles/shared/utils/colors';
import useScreenSize from '../../hooks/useScreenSize';
import sizes from '../../util/sizes';

import BidNotification from './BidNotification';
import CustomBidModal from './CustomBidModal';

import { BalanceBadgeContainer } from './style';
import {
  BalanceCountText,
  BalanceWrapper,
  BidBalanceText,
  BidBalanceWrapper,
  BidComponentInput,
  BidComponentInputBtn,
  BidComponentInputWrapper,
  BidComponentText,
  BidComponentTitle,
  BidComponentWrapper,
  BiddingContainer,
  BiddingInputActionContainer,
  BidWrapper,
  Wrapper,
  ExtraInfoText,
  ExtraInfoRightText,
} from './BidComponent.style';

interface BiddingControlProps {
  item: IItem.Item;
  bidAmountLabel?: string;
  bidAmount?: number | string;
  allowIncrease?: boolean;
  onClickIncrease?: () => void;
  allowDecrease?: boolean;
  onClickDecrease?: () => void;
  biddingButton?: JSX.Element;
  switchLabel: string;
  isBiddingEnabled: boolean;
  isCurrentLiveItem?: boolean;
  disableToggleButton?: boolean;
  biddingEnabledHandler: () => void;
  disableCustomBid: boolean;
  hideNotification?: boolean;
}

const Bidding: FC<BiddingControlProps> = ({
  item,
  bidAmountLabel,
  bidAmount,
  allowIncrease = false,
  onClickIncrease,
  allowDecrease = false,
  onClickDecrease,
  biddingButton,
  switchLabel,
  isBiddingEnabled,
  isCurrentLiveItem = false,
  disableToggleButton,
  biddingEnabledHandler,
  disableCustomBid,
  hideNotification = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const [modalVisible, setModalVisible] = useState(false);

  const account = UserHooks.useUserAccount();
  const bidder = LiveAuctionHooks.useBidder();
  const liveBalance = LiveAuctionHooks.useBidderAvailableBalance();
  const balance = item.isLive ? liveBalance : account?.availableBalance;

  const isAuctionEnded = checkAuctionEnded(item);
  const isOffer = item.bidding?.isOffer;
  const socketId = EbidAuctionHooks.useSocketId();
  const socketLatency = EbidAuctionHooks.useSocketLatency();

  const handleCustomBidClick = () => {
    setModalVisible(true);
  };

  const EbidBalanceMaxBid = (
    <BidBalanceWrapper>
      <BalanceWrapper>
        <BidBalanceText>{t('labels:_balance')}:</BidBalanceText>
        <BalanceCountText id="balance_value">{numberToAmountLabel(balance)}</BalanceCountText>
      </BalanceWrapper>
      <BalanceWrapper>
        <BidBalanceText>{t('labels:max_bid')}:</BidBalanceText>
        <BalanceCountText id="max_bid_value">
          {numberToAmountLabel(item.bidding?.maxBidAmount)}
        </BalanceCountText>
      </BalanceWrapper>
      <BalanceWrapper>
        <ExtraInfoText>{t('labels:socket_id')}:</ExtraInfoText>
        <ExtraInfoRightText id="socket_id_value">{socketId}</ExtraInfoRightText>
      </BalanceWrapper>
      <BalanceWrapper>
        <ExtraInfoText>{t('socket:latency')}:</ExtraInfoText>
        <ExtraInfoRightText
          id="socket_latency_value"
          style={{
            color:
              socketLatency >= 1000
                ? colors.red
                : socketLatency >= 500
                ? colors.orange
                : socketLatency >= 200
                ? colors.yellow
                : colors.green,
          }}
        >
          {socketLatency && (socketLatency < 1000 ? socketLatency : socketLatency/1000)}
          {socketLatency && (socketLatency < 1000 ? 'ms' : 's')}
        </ExtraInfoRightText>
      </BalanceWrapper>
    </BidBalanceWrapper>
  );

  return (
    <BiddingContainer>
      {!hideNotification && (
        <BidNotification itemId={item.id} isCurrentLiveItem={isCurrentLiveItem} />
      )}
      <BidComponentWrapper id="bid-component-wrapper">
        <BidWrapper>
          <BiddingInputActionContainer>
            <Wrapper>
              {!isOffer && <BidComponentTitle>{t('fields:place_your_bid')}</BidComponentTitle>}

              <BidComponentInputWrapper>
                {bidAmountLabel && (
                  <BidComponentText id="bid-amount-label">{bidAmountLabel}</BidComponentText>
                )}

                <BidComponentInput
                  id="bid-amount-input"
                  value={
                    typeof bidAmount === 'number' ? numberToLocaleString(bidAmount || 0) : bidAmount
                  }
                  disabled={disableCustomBid}
                  onClick={() => handleCustomBidClick()}
                  style={{ padding: bidAmountLabel ? '21px 44px 8px' : 0 }}
                />

                {allowDecrease && (
                  <BidComponentInputBtn
                    id="bid-amount-input-minus-btn"
                    isLeft={true}
                    onClick={onClickDecrease}
                  >
                    -
                  </BidComponentInputBtn>
                )}
                {allowIncrease && (
                  <BidComponentInputBtn id="bid-amount-input-plus-btn" onClick={onClickIncrease}>
                    +
                  </BidComponentInputBtn>
                )}
              </BidComponentInputWrapper>

              {biddingButton}
            </Wrapper>

            {item.isEbid && !isMobile && EbidBalanceMaxBid}
          </BiddingInputActionContainer>

          <BidComponentBottomSectionWrapper>
            <ToggleContainer>
              <Switch
                id="bid-toggle"
                height={20}
                width={35}
                checked={isBiddingEnabled}
                onChange={biddingEnabledHandler}
                onColor={colors.toggleBlue}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                disabled={disableToggleButton}
              />
              <BidBalanceText id="bid-switch-label" hasLeftMargin={true}>
                {switchLabel}
              </BidBalanceText>
            </ToggleContainer>

            {item.isLive && (
              <BalanceBadgeContainer>
                <BalanceWrapper>
                  <BidBalanceText>{t('labels:_balance')}</BidBalanceText>
                  <BalanceCountText id="balance-label">
                    {numberToAmountLabel(balance || 0)}
                  </BalanceCountText>
                </BalanceWrapper>
                <BalanceWrapper>
                  {!isAuctionEnded && (
                    <>
                      <BidBalanceText>{t('labels:badge')}</BidBalanceText>
                      <BalanceCountText id="max-autobid-label">
                        {bidder?.BidderBadge}
                      </BalanceCountText>
                    </>
                  )}
                </BalanceWrapper>
              </BalanceBadgeContainer>
            )}

            {item.isEbid && isMobile && EbidBalanceMaxBid}
          </BidComponentBottomSectionWrapper>
        </BidWrapper>
      </BidComponentWrapper>
      <CustomBidModal item={item} visible={modalVisible} setModalVisible={setModalVisible} />
    </BiddingContainer>
  );
};

export default Bidding;

export const BidComponentBottomSectionWrapper = styled.div`
  width: 100%;
  height: 59px;
  background: ${colors.astronaut};
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  padding: 15px 24px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${sizes.M}) {
    border-radius: 0;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: ${sizes.S}) {
    flex: 1;
  }
`;
