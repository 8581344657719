import styled, { CSSProperties } from 'styled-components';
import sizes from '../../../../util/sizes';
import colors from '@pickles/shared/utils/colors';

interface ICarDetailSliderWrapper {
  width?: CSSProperties['width'];
}

export const CarDetailSliderWrapper = styled.div<ICarDetailSliderWrapper>`
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  width: ${({ width }) => width || '100%'};

  .image-gallery-thumbnail.active {
    border: 3px solid ${colors.blumine_main_blue} !important;
    border-radius: 12px;
    height: 65px;
    img {
      border-radius: 8px;
    }
  }
  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail:hover {
    border: 4px solid transparent !important;
  }
  .image-gallery-thumbnail img {
    cursor: pointer;
    border-radius: 8px;
    height: 59px;
    object-fit: cover;
  }
  .image-gallery-thumbnails-container {
    display: flex;
    gap: 5px;
    width: fit-content;
    margin: 0 auto;
  }

  @media (max-width: ${sizes.S}) {
    height: 290px;
    border-radius: 0;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ImgBackBtn = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 59px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
`;

export const ImgNextBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  width: 59px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
`;

export const ImgCountLine = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 16px;
  color: #ffffff;
  margin-top: 52px;
  text-align: center;
`;

export const CarDetailStatus = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 100;
  padding: 0 10px;
  color: ${colors.san_juan_grey};
  background: ${colors.zircon_light_blue};
  border-radius: 6px;
  text-transform: uppercase;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarDetailEditBtn = styled.div`
  position: absolute;
  bottom: 14px;
  left: 14px;
  z-index: 100;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.zircon_light_blue};
  border-radius: 8px;
  cursor: pointer;
`;

export const CarDetailCountWrapper = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 100;
  padding: 10px 16px;
  background: #242b34;
  opacity: 0.8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const CountNum = styled.span`
  font-family: BasierCircle-Semibold;
  font-size: 15px;
  color: ${colors.white};
  margin-left: 5px;
`;

interface IButtonProps {
  isDisable?: boolean;
}

export const ThumbnailBackBtn = styled(ImgBackBtn)<IButtonProps>`
  width: 28px;
  img {
    filter: ${({ isDisable }) => (isDisable ? 'none' : 'contrast(1) invert(1)')};
  }
`;

export const ThumbnailNextBtn = styled(ImgNextBtn)<IButtonProps>`
  width: 28px;
  img {
    transform: rotate(180deg);
    filter: ${({ isDisable }) => (isDisable ? 'none' : 'contrast(0.5) invert(1)')};
  }
`;

export const CarImageContainer = styled.div<{ width?: string; height: string; isMobile?: boolean }>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (!props.isMobile ? props.height : 'initial')};
  border-radius: 8px;
  max-width: 100%;
  position: relative;

  img {
    border-radius: 8px;
  }

  @media (max-width: ${sizes.S}) {
    img {
      border-radius: 0;
    }
  }
`;
