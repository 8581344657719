import styled from 'styled-components';
import { ArrowDown } from '../../Navbar/style';
import { Flex } from '../Layout/Flex';
type FilterSelectContainerProps = {
  isOpened?: boolean;
};
export const FilterSelectContainer = styled.div<FilterSelectContainerProps>`
  margin-right: 8px;
  color: #334d70;
  cursor: pointer;
`;

export const FilterList = styled('ul')`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const FilterItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
`;

export const FilterArrowDown = styled(ArrowDown)<{isOpened?:boolean}>`
  border-right: 2px solid #334d70;
  border-bottom: 2px solid #334d70;
  margin-top: ${({isOpened}) => !isOpened? 0 : '4px'};
  margin-bottom: ${({isOpened}) => isOpened? 0 : '4px'};
`;

export const ImageFilterArrowDown = styled(FilterArrowDown)`
  height: 7px;
  min-width: 7px;
  width: 7px;
`;

export const FilterOptions = styled.div`
  position: absolute;
  border: 1px solid #d6dadf;
  background-color: #fff;
  z-index: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: -2px;

  > div {
    padding: 15px 0;
    text-align: center;

    width: 76.8px;
    white-space: nowrap;
    overflow: hidden;
  }
  > div:not(:last-child) {
    border-bottom: 1px solid #d6dadf;
  }
`;

export const FilterImageOptions = styled.div`
  position: absolute;
  border: 1px solid #d6dadf;
  z-index: 1;
  border-radius: 12px;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #bec8d4;
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  color: #1c4d8e;
  > div {
    padding: 15px;
  }
`;

type FilterHeaderProps = {
  isOpened?: boolean;
};

export const FilterHeader = styled.div<FilterHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 5px 0px;
  padding: 15px; */
  padding: 12px 13px 12px;
  border: 1px solid #d6dadf;
  border-radius: ${({ isOpened }) => (!isOpened ? '12px' : 'none')};
  border-top-left-radius: ${({ isOpened }) => (isOpened ? '12px' : 'none')};
  border-top-right-radius: ${({ isOpened }) => (isOpened ? '12px' : 'none')};
  white-space: nowrap;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  line-height: 20px;
  > div:last-child {
    transform: ${({ isOpened }) => (isOpened ? 'rotate(225deg)' : 'rotate(45deg)')};
  }
`;

export const FilterImageHeader = styled.div<FilterHeaderProps>`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  padding: 15px;
  border-radius: 12px;
  white-space: nowrap;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  border: 1.5px solid #36609a;
`;

export const FilterDateContainer = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #d6dadf;
  border-radius: 12px;
`;

export const FilterDateInput = styled.input`
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  position: absolute;
  opacity: 0;
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;
type FilterDateInputLabelProps = {
  for?: string;
};
export const FilterDateInputLabel = styled.label<FilterDateInputLabelProps>`
  color: #334d70;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  > div:nth-child(2) {
    margin-left: 14px;
    font-family: BasierCircle-Medium;
    font-size: 15px;
  }
  > div:last-child {
    margin-right: 14px;
  }
  > input[type='date'] {
    padding: 14px;
    padding-right: 28px;
    width: 100%;
  }
`;

export const FilterDateArrowDown = styled(FilterArrowDown)`
  cursor: pointer;
`;

export const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  > div {
    // min-width: 100px;
  }
`;

export const FilterImageOption = styled(Flex)`
  span {
    margin-left: 15px;
  }
`;
