import React, { FC } from 'react';
import moment from 'moment';
import {
  BidHistoryItemWrapper,
  TextWrapper,
  Dot,
  DotWrapper,
  InfoWrapper,
  PriceText,
  PriceWrapper,
  YouBadge,
} from './style';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';

interface IBidHistoryItem {
  isFromUser: boolean;
  isActive: boolean;
  price: number;
  location?: string;
  date: number;
  isFirstItem: boolean;
}

const BidHistoryItem: FC<IBidHistoryItem> = ({
  isFromUser,
  isActive,
  price,
  // location,
  date,
  isFirstItem,
}) => {
  // let millisecs = moment(date).valueOf() / 1000;
  // millisecs = millisecs.toString().split('.')[1];
  const formatedTime = moment(date).format('MMM DD, yyyy hh:mm:ss A ');
  return (
    <BidHistoryItemWrapper isFirstItem={isFirstItem}>
      <DotWrapper isActive={isActive}>
        <Dot />
      </DotWrapper>
      <InfoWrapper isActive={isActive}>
        <PriceWrapper>
          <PriceText>{numberToAmountLabel(price)}</PriceText>
          {isFromUser ? <YouBadge>YOU</YouBadge> : null}
        </PriceWrapper>
        <TextWrapper>{formatedTime}</TextWrapper>
        {/* <TextWrapper>
          Badge <LocationText>{location}</LocationText>
        </TextWrapper> */}
      </InfoWrapper>
    </BidHistoryItemWrapper>
  );
};

export default BidHistoryItem;
