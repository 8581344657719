import { itemActions } from '@pickles/shared/redux/actions';
import { getIdsByAuctionItemRef } from '@pickles/shared/utils/routes';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ItemHooks, LoaderHooks, WebHooks } from '../../app/redux/hooks';
import ItemDetailContents from './ItemDetailContents';
import Error from '../../pages/404';
import { LoadingWrapper } from '../SearchContent/style';
import Loading from '../Shared/Loading';
import { IBidding } from '@pickles/shared/models';

export const ItemDetails = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const previousUrlPath = WebHooks.useSavedPath();
  const { itemRef } = router.query;
  const { itemId, saleEventId } = getIdsByAuctionItemRef(itemRef);

  const isRenderDone = useRef(false);
  const isLoading = LoaderHooks.useLoadingIndicator('item');
  const item = ItemHooks.useItem(itemId);
  const items = ItemHooks.useItemsByAuctionId(saleEventId);

  const { isAuctionStarted } = ItemHooks.useComputedValues(itemId);
  const prevVariableRef = useRef(isAuctionStarted);
  // If user comes from Items Listing page, we need to wait for the getItem action to complete before rendering the Item Details page
  const isFromItemsListingPage = previousUrlPath !== '/';
  const renderItemDetailsContent =
    (isFromItemsListingPage && isRenderDone.current) || !isFromItemsListingPage;

  useEffect(() => {
    if (itemId && typeof itemId === 'number') {
      dispatch(itemActions.getItem(itemId));
      isRenderDone.current = true;
    }
  }, [dispatch, itemId]);

  useEffect(() => {
    // dispatch getItem action when user stays in Item Details page right before auction starts
    if (prevVariableRef.current === false && isAuctionStarted === true) {
      dispatch(itemActions.getItem(itemId));
    }

    prevVariableRef.current = isAuctionStarted;
  }, [isAuctionStarted]);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (!item || item.bidding?.status === IBidding.STATUS.LOST) {
    return <Error isFromComponent />;
  }

  return renderItemDetailsContent && <ItemDetailContents item={item} items={items} />;
};
