import { IItem } from '@pickles/shared/models';
import { liveAuctionActions } from '@pickles/shared/redux/actions';
import { BidButtonType } from '@pickles/shared/utils/types';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DashboardHooks, ItemHooks, LiveAuctionHooks, LoaderHooks } from '../../app/redux/hooks';
import AttendBidding from './AttendBidding';
import { BidComponentBtn, BidComponentBtnComment } from './BidComponent.style';
import Bidding from './Bidding';
import SetMaxBid from './SetMaxBid';

interface SimplifiedLiveItemProps {
  item: IItem.Item;
}

const SimplifiedLiveItem: FC<SimplifiedLiveItemProps> = ({ item }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const auction = DashboardHooks.useAuction(item.saleEvent?.id);
  const isLoading = LoaderHooks.useLoadingIndicator(`bid_${item.id}`);
  const liveItem = LiveAuctionHooks.useCurrentItem();
  const [isBiddingEnabled, setIsBiddingEnabled] = useState(false);

  const isLiveAuctionPage = router.asPath.endsWith('/live');

  const {
    bidAmountLabel,
    bidButton,
    displayBidAmount,
    isAttend,
    isAuctionEnded,
    isAuctionRunning,
    isAuctionWaiting,
    isCurrentLiveItem,
    isPrebid,
    isSocketConnected,
    nextBidAmount,
  } = ItemHooks.useComputedValues(item.id, item.saleEvent!.id, isLiveAuctionPage);

  const disableToggleButton = !(isAuctionRunning && isSocketConnected);

  useEffect(() => {
    setIsBiddingEnabled(false);
  }, [item.id]);

  const onBidButtonPress = () => {
    if (liveItem && nextBidAmount && typeof displayBidAmount === 'number') {
      dispatch(liveAuctionActions.sendBid(liveItem.ID, displayBidAmount));
    }
  };

  const renderBiddingButton = () => {
    const disabled = !(isBiddingEnabled && isSocketConnected && !isLoading);

    switch (bidButton) {
      case BidButtonType.NewItem:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('buttons:new_item')}
          </BidComponentBtn>
        );
      case BidButtonType.BidNow:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={onBidButtonPress}
          >
            {disabled ? t('labels:bidding_disabled') : t('buttons:bid_now')}
          </BidComponentBtn>
        );
      case BidButtonType.SendingBid:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('labels:sending_bid')}
          </BidComponentBtn>
        );
      case BidButtonType.YouWin:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('labels:bidding_won')}
          </BidComponentBtn>
        );
      case BidButtonType.NoSale:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('buttons:bid_no_sale')}
          </BidComponentBtn>
        );
      case BidButtonType.Winning:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('labels:bidding_winning').toUpperCase()}
          </BidComponentBtn>
        );
      case BidButtonType.Offer:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            <BidComponentBtnComment>{item.bidding?.winnerBadge}</BidComponentBtnComment>
            {t('labels:bidding_offer')}
          </BidComponentBtn>
        );
      case BidButtonType.Sold:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            <BidComponentBtnComment>{item.bidding?.winnerBadge}</BidComponentBtnComment>
            {t('labels:sold')}
          </BidComponentBtn>
        );
      case BidButtonType.TopUp:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={() => router.push('/profile')}
          >
            <BidComponentBtnComment>{t('labels:insufficient_balance')}</BidComponentBtnComment>
            {t('buttons:top_up')}
          </BidComponentBtn>
        );
      default:
        return (
          <BidComponentBtn id="bid-btn" variant={'disabled'}>
            unknown
          </BidComponentBtn>
        );
    }
  };

  const biddingSwitchText: string =
    (isAuctionEnded && t('labels:auction_ended')) ||
    (!isSocketConnected && t('socket:live:disconnect')) ||
    (isBiddingEnabled && t('labels:bidding_enabled')) ||
    t('labels:bidding_disabled');

  if (isAttend && auction) {
    return <AttendBidding auction={auction} />;
  }

  if (isPrebid && auction) {
    return <SetMaxBid item={item} />;
  }

  if (isAuctionWaiting) {
    return (
      <Bidding
        item={item}
        bidAmount={t('labels:start_soon')}
        biddingButton={renderBiddingButton()}
        switchLabel={`${biddingSwitchText}`}
        isBiddingEnabled={isBiddingEnabled}
        biddingEnabledHandler={() => {}}
        disableCustomBid={true}
        disableToggleButton={disableToggleButton}
        hideNotification={true}
      />
    );
  }

  return (
    <Bidding
      allowDecrease={false}
      allowIncrease={false}
      bidAmount={displayBidAmount}
      bidAmountLabel={t(bidAmountLabel)}
      biddingButton={renderBiddingButton()}
      biddingEnabledHandler={() => setIsBiddingEnabled((state) => !state)}
      disableCustomBid={true}
      disableToggleButton={disableToggleButton}
      isBiddingEnabled={isBiddingEnabled}
      isCurrentLiveItem={isCurrentLiveItem}
      item={item}
      switchLabel={biddingSwitchText}
    />
  );
};

export default SimplifiedLiveItem;
