import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../../util/sizes';

export const CheckboxWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 14px;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.S}) {
    align-items: center;
  }
`;

export const CheckboxText = styled.span`
  font-family: BasierCircle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.san_juan_grey};
`;

export const BoldText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.san_juan_grey};
`;

export const TextWrapper = styled.div`
  text-align: left;
  max-width: ${sizes.XL};
`;
