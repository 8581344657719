import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '../..';
import errorCar from '../../../public/images/errorCar.png';
import Container from '../../Shared/Container';

import { BannerContainer, ImageWrapper, TextWrapper } from './style';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <BannerContainer>
      <Container>
        <ImageWrapper>
          <Image src={errorCar} alt="404 Error" width="600" height="260" />
        </ImageWrapper>
        <TextWrapper>
          <Title text={t('errors:error_oops')} fontColor="white" fontSize={56} />
          <Title
            text={t('errors:page_not_found')}
            fontColor="white"
            textAlign="center"
            fontSize={22}
            fontWeight={400}
            margin="15px"
          />
        </TextWrapper>
      </Container>
    </BannerContainer>
  );
};

export default Banner;
