import styled from 'styled-components';
import { ProfileTabGridHead } from './ProfileTabPanel.style';
import { TransactionType, TransactionsGridRow } from './Transactions.style';

export const BlockedDepositHead = styled(ProfileTabGridHead)`
  grid-template-columns: 1fr 1fr;
  div {
    text-transform: uppercase;
    font-size: 12px;
  }
  > div:nth-child(2) {
    justify-content: end;
  }
  div {
    color: #596e8a;
  }
`;

export const BlockedDepositContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'amount type ';
  cursor: pointer;
  > div {
    display: flex;
    font-family: BasierCircle;
    width: 100%;
  }

  > div:nth-child(2) {
    justify-content: end;
  }
`;

export const BlockedDepositData = styled.div`
  color: #334d70;
  display: flex;
  justify-content: end;
`;

export const BlockedDepositTypeRow = styled.div`
  display: flex;
`;

export const BlockedDepositType = styled(TransactionType)``;
export const BlockedDepositAmount = styled.div`
  display: flex;
  flex-direction: row;
  > div:first-child {
    margin-right: 18px;
  }
  > div:nth-child(2) {
    div:first-child {
      color: #263a54;
      font-size: 20px;
      margin-bottom: 6px;
      font-weight: 600;
    }
    font-size: 15px;
  }
  span {
    min-width: 28 px;
  }
`;
export const BlockedDepositGridRow = styled(TransactionsGridRow)`
  padding: 14px 0 20px;
  > div {
    color: #596e8a;
    font-size: 14px;
  }
`;

export const BlockedDepositRemaining = styled.div`
  color: #263a54;
  font-size: 14px;
  margin-left: 5px;
  display: flex;
  justify-content: end;
`;
export const BlockedDepositTypeAssets = styled(BlockedDepositRemaining)`
  font-size: 15px;
  font-weight: 600;
  margin-left: 0px;
  margin-bottom: 6px;
`;
export const EmptyBlockedDeposit = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
export const CarImageWrapper = styled.div`
  position: relative;
  height: 55px;
  width: 55px;

  img {
    border-radius: 6px;
  }
`;
