import { CSSProperties } from 'react';
import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

export interface ICustomTitleProps {
  fontSize?: CSSProperties['fontSize'];
  fontColor?: CSSProperties['color'];
  lineHeight?: CSSProperties['lineHeight'];
  fontWeight?: CSSProperties['fontWeight'];
  margin?: CSSProperties['margin'];
  fontFamily?: CSSProperties['fontFamily'];
  maxWidth?: CSSProperties['maxWidth'];
  width?: CSSProperties['width'];
  textAlign?: CSSProperties['textAlign'];
}
const defaultMobileFontSize = '28px';

export const CustomTitle = styled.p<ICustomTitleProps>`
  text-align: ${({ textAlign = 'left' }) => textAlign};
  font-size: ${({ fontSize = 36 }) => (typeof fontSize === 'string' ? fontSize : fontSize + 'px')};
  color: ${({ fontColor = COLORS.rhino_black }) => fontColor};
  font-family: ${({ fontFamily = 'BasierCircle' }) => fontFamily};
  font-weight: ${({ fontWeight = 600 }) => fontWeight};
  line-height: ${({ lineHeight = '130%' }) => lineHeight};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth = '100%' }) =>
    typeof maxWidth === 'string' ? maxWidth : maxWidth + 'px'};
  font-style: normal;
  @media (max-width: ${sizes.M}) {
    font-size: ${({ fontSize = 36 }) =>
      fontSize === 36 ? defaultMobileFontSize : Number(fontSize) / 1.2 + 'px'};
    line-height: ${({ lineHeight = 36 }) => lineHeight + 'px'};
  }
  @media (max-width: ${sizes.S}) {
    // width: ${({ width }) => width || 'calc(100% - 40px)'};
    text-align: ${({ textAlign = 'left' }) => textAlign};
  }
`;
