import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';

import { TransactionsHistoryBlock, TransactionsHistoryContainer } from './Transactions.style';

export const ProfileHistoryBlock = styled(TransactionsHistoryBlock)`
  width: 100%;
  color: ${({ isActive }) => (isActive ? colors.blumine_main_blue : colors.rhino_black)};
`;
export const ProfileHistoryContainer = styled(TransactionsHistoryContainer)``;

export const ProfileDetailsWrapper = styled.div`
  div:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
export const ProfileDetailRow = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.mischka};
  color: ${colors.rhino_black};
  > div {
    font-family: BasierCircle;
  }
`;
export const ProfileDetailsRowHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
`;
export const ProfileDetailsRowContent = styled.div`
  font-size: 17px;
`;
