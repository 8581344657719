import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

const position = '48px';

interface IBannerWrapperProps {
  height?: string;
  width?: string;
}

interface IInfoBlock {
  hasImg: boolean;
  contentWidth?: string;
}

export const BannerWrapper = styled.div<IBannerWrapperProps>`
  height: ${({ height = '470px' }) => height};
  /* width: ${({ width = '100%' }) => width}; */
  width: 100%;
  /* margin: 0 87px; */
  position: relative;
  background: linear-gradient(
    226.83deg,
    ${COLORS.blumine_main_blue} 25.8%,
    ${COLORS.blue_zodiak} 123.58%
  );
  border-radius: 24px;

  @media (max-width: ${sizes.S}) {
    border-radius: 0;
    padding: 40px 0;
    margin: 0;
    width: 100%;
  }
`;

export const BackGroundImgWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const InfoBlock = styled.div<IInfoBlock>`
  height: 100%;
  width: ${({ hasImg, contentWidth }) => {
    if (contentWidth) {
      return contentWidth;
    }
    if (hasImg) {
      return '35%';
    }

    return '100%';
  }};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding-left: 90px;
  position: relative;
  z-index: 2;

  @media (max-width: ${sizes.S}) {
    padding: 0 20px;
    height: auto;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 45%;
  height: 470px;
  top: ${position};
  right: ${position};
  overflow: hidden;
  border-radius: 24px;
  display: flex;
  justify-content: flex-end;
  img {
    object-fit: cover !important;
    min-height: auto !important;
    max-height: fit-content !important;
    height: auto !important;
    width: fit-content;
    position: relative;
    margin: 0 !important;
  }
  @media (max-width: ${sizes.S}) {
    left: 20px;
    top: initial;
    width: calc(100% - 40px);
    position: relative;
    margin-bottom: -88px;
    height: 75vw;
  }
`;

export const Title = styled.div`
  font-family: BasierCircle-Semibold;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 56px;
  /* line-height: 110%; */
  color: ${COLORS.white};
  margin-bottom: 16px;
  @media (max-width: ${sizes.S}) {
    font-size: 40px;
    margin: 0;
  }
`;

export const Description = styled.div`
  font-family: BasierCircle;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${COLORS.white};
  margin-bottom: 24px;

  @media (max-width: ${sizes.S}) {
    font-size: 20px;
  }
`;
