import Image from 'next/image';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appActions, userActions } from '@pickles/shared/redux/actions';
import {
  ChangePasswordContainer,
  ChangePasswordButton,
  ChangePasswordText,
  EmailSentBlock,
  EmailSentText,
} from './ChangePassword.style';
import changePasswordImg from '/public/images/changePass.png';
import { UserHooks } from '../../app/redux/hooks';
import { checkBlue } from '../Shared/Icons';
import MobileHeaderLine from '../Shared/MobileHeaderLine';

const changePassText = 'We will send you an email with instructions on how to reset your password.';
const changePassButtonText = 'Reset Password';
const emailSent = 'Email sent';

interface IChangePassword {
  closeScreen?: () => void;
  isMobile?: boolean;
}

export const ChangePassword: FC<IChangePassword> = ({ isMobile = false, closeScreen }) => {
  const user = UserHooks.useUserAccount();
  const isResetEmailSend = UserHooks.useUserResetPasswortStatus();
  const dispatch = useDispatch();

  const handleResetPassword = () => {
    if (user) {
      dispatch(userActions.resetPassword(user.email));
    }
  };

  useEffect(() => {
    dispatch(appActions.openResetPasswordForm());
  }, []);

  return (
    <>
      {isMobile && closeScreen && (
        <MobileHeaderLine
          hasShareBtn={false}
          onBackBtnClick={closeScreen}
          title={'Change password'}
        />
      )}
      <ChangePasswordContainer>
        <div>
          <Image src={changePasswordImg} alt="Change Password" />
          <ChangePasswordText>{changePassText}</ChangePasswordText>
          {isResetEmailSend ? (
            <EmailSentBlock>
              <EmailSentText>
                <Image src={checkBlue} alt="Email Sent"/>
                {emailSent}
              </EmailSentText>
            </EmailSentBlock>
          ) : (
            <ChangePasswordButton onClick={() => handleResetPassword()}>
              {changePassButtonText}
            </ChangePasswordButton>
          )}
        </div>
      </ChangePasswordContainer>
    </>
  );
};
