import { IItem } from '@pickles/shared/models';
import { ebidActions, liveAuctionActions } from '@pickles/shared/redux/actions';
import { DEFAULT_INCREMENT } from '@pickles/shared/redux/ebid-auction/utils';
import colors from '@pickles/shared/utils/colors';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { EbidAuctionHooks, UserHooks } from '../../app/redux/hooks';
import sizes from '../../util/sizes';
import { BaseModal } from '../Shared/Modal';

interface CustomBidModalProps {
  item: IItem.Item;
  visible: boolean;
  setModalVisible: (value: boolean) => void;
}

const CustomBidModal: FC<CustomBidModalProps> = (props) => {
  const { item, visible, setModalVisible } = props;
  const { t } = useTranslation();
  const calculator = EbidAuctionHooks.useCalculator();
  const dispatch = useDispatch();
  const account = UserHooks.useUserAccount();
  const [currentBidValue, setCurrentBidValue] = useState(0);

  let increment: number;
  let minBidValue!: number;
  let nextBidValue: number;

  if (item.isEbid && item.bidding) {
    const { currentBidAmount, nextBidAmount, maxBidAmount } = item.bidding;
    nextBidValue = nextBidAmount;
    increment = calculator.getIncrement(currentBidAmount)?.increment || DEFAULT_INCREMENT;
    minBidValue = nextBidValue > maxBidAmount ? nextBidValue : maxBidAmount;
  } else {
    minBidValue = calculator.increase(item.prebid?.amount || 0);
  }

  useEffect(() => {
    setCurrentBidValue(minBidValue || 0);
  }, [item, minBidValue]);

  const getCustomBidMessage = () => {
    if (item.isEbid) {
      return minBidValue === nextBidValue ? 'custom_bid_info' : 'custom_bid_info_more_than';
    }

    return 'custom_bid_info_prebid';
  };

  const isBidBtnDisabled = currentBidValue < minBidValue;

  const renderRowLabel = (left: string, right: string) => (
    <CustomBidRowContainer>
      <CustomBidRow>{left}</CustomBidRow>
      <CustomBidRow>{right}</CustomBidRow>
    </CustomBidRowContainer>
  );

  const renderBiddingDetails = () => {
    if (item.isEbid && item.bidding) {
      const { currentBidAmount, nextBidAmount, lastBidAmount, maxBidAmount } = item.bidding;

      return (
        <CustomBidRowWrapper>
          {renderRowLabel(t('labels:lot_current_bid'), numberToAmountLabel(currentBidAmount))}
          {renderRowLabel(t('labels:next_bid'), numberToAmountLabel(nextBidAmount))}
          {renderRowLabel(t('labels:my_last_bid'), numberToAmountLabel(lastBidAmount))}
          {renderRowLabel(t('labels:my_max_bid'), numberToAmountLabel(maxBidAmount))}
          {renderRowLabel(t('labels:bid_increment'), numberToAmountLabel(increment))}
        </CustomBidRowWrapper>
      );
    }

    return (
      <CustomBidRowWrapper>
        {renderRowLabel(t('labels:your_max_bid'), numberToAmountLabel(item.prebid?.amount))}
      </CustomBidRowWrapper>
    );
  };

  const handleCustomBidPress = () => {
    if (item.isEbid && account && currentBidValue && typeof currentBidValue === 'number') {
      dispatch(
        ebidActions.setAutobid({
          itemId: item.id,
          userId: account.id,
          bidAmount: currentBidValue,
          eventId: item.saleEvent?.id,
        }),
      );
    } else if (item.isLive && item.saleEvent) {
      dispatch(liveAuctionActions.addItemPrebid(item.id, item.saleEvent?.id, currentBidValue));
    }

    setModalVisible(false);
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentBidValue(parseInt(e.target.value) || 0);
  };

  return (
    <BaseModal
      isOpened={visible}
      onClickOutside={() => setModalVisible(false)}
      id="custom-bid-modal"
    >
      <CustomBidContainer id="custom-bid-container">
        <CustomBidTitle>{t('buttons:set_max_bid')}</CustomBidTitle>
        <CustomBidDescription>
          {t(`infos:${getCustomBidMessage()}`, {
            nextValue: numberToAmountLabel(minBidValue),
          })}
        </CustomBidDescription>
        <CustomBidDescription>{renderBiddingDetails()}</CustomBidDescription>
        <CustomBidInputWrapper>
          <CustomBidInput
            value={currentBidValue}
            placeholder={t('labels:enter_bid_amount')}
            onChange={handleTextChange}
          />
        </CustomBidInputWrapper>
        <CustomBidButton disabled={isBidBtnDisabled} onClick={() => handleCustomBidPress()}>
          {t('buttons:set_max_bid')}
        </CustomBidButton>
      </CustomBidContainer>
    </BaseModal>
  );
};

export default CustomBidModal;

const CustomBidRowWrapper = styled.div`
  width: 100%;
  padding-left: 30px;
`;

const CustomBidRowContainer = styled.div`
  display: flex;
`;

const CustomBidRow = styled.div`
  text-align: left;
  flex: 50%;
`;

const CustomBidInputWrapper = styled.div`
  padding: 0 40px;
  max-width: ${sizes.XL};
`;

const CustomBidInput = styled.input`
  margin-bottom: 20px;
  color: ${colors.new_gray};
  font-family: BasierCircle;
  font-size: 16px;
  text-align: center;
  outline: none;
  border-color: ${colors.lightGray};
  border-width: 0 0 1px;
  width: 100%;
  max-width: ${sizes.XL};
`;

const CustomBidContainer = styled.div`
  font-size: 13px;
  color: ${colors.new_gray};
  width: 380px;
  > * {
    font-family: BasierCircle;
  }

  @media (max-width: ${sizes.M}) {
    width: 100%;
    padding: 0 20px;
  }

  @media (max-width: ${sizes.S}) {
    padding: 0;
  }
`;

const CustomBidTitle = styled.div`
  font-weight: 600;
  font-size: 38px;
  text-align: center;
  color: ${colors.rhino_black};
  margin-top: 30px;
  margin-bottom: 14px;
`;

const CustomBidDescription = styled.div`
  display: flex;
  line-height: 150%;
  text-align: center;
  margin: 25px auto 30px auto;
  max-width: 300px;
  white-space: pre-line;
  color: ${colors.san_juan_grey};
  font-size: 16px;
  > div {
    margin-bottom: 4px;
  }
`;

const CustomBidButton = styled.button`
  background: ${colors.blumine_main_blue};
  border-radius: 8px;
  padding: 12px 28px 14px;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.white};
  border: none;
  width: 173px;
  cursor: pointer;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: ${sizes.M}) {
    width: calc(100% - 40px);
  }
`;
