import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

type StyleProps = {
  open?: boolean;
  bottom?: boolean;
  bgOnScroll?: boolean;
  navbarBgColor?: string;
  isInNavbar?: boolean;
};

export const RefundContainer = styled.div`
  font-size: 13px;
  color: ${COLORS.new_gray};
  width: 380px;
  > * {
    font-family: BasierCircle;
  }

  @media (max-width: ${sizes.M}) {
    width: 100%;
    padding: 0 20px;
  }
`;
export const RefundTitle = styled.div`
  font-weight: 600;
  font-size: 38px;
  text-align: center;
  color: ${COLORS.rhino_black};
  margin-top: 30px;
  margin-bottom: 14px;
`;
export const RefundDescription = styled.div`
  display: flex;
  line-height: 150%;
  text-align: center;
  margin: 25px auto 30px auto;
  width: 300px;
  white-space: pre-line;
  color: ${COLORS.san_juan_grey};
  font-size: 16px;
  > div {
    margin-bottom: 4px;
  }
`;

export const RefundOkButton = styled.button`
  background: ${COLORS.blumine_main_blue};
  border-radius: 8px;
  padding: 12px 28px 14px;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.white};
  border: none;
  width: 173px;
  cursor: pointer;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${sizes.M}) {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }
`;
