import config from '@pickles/shared/config';
import { UserTransaction, UserTransactionType } from '@pickles/shared/models/user.types';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import moment from 'moment';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppHooks, UserHooks } from '../../app/redux/hooks';
import PDFPortal from '../Portal/PDFPortal';
import {
  TransactionAction,
  TransactionAmount,
  TransactionAssets,
  TransactionsDetails,
  TransactionsGridContent,
  TransactionsGridDetails,
  TransactionsGridRow,
  TransactionType,
} from './Transactions.style';

interface TransactionHistoryItemProps {
  transaction: UserTransaction;
  index: number;
}

const TransactionHistoryItem: FC<TransactionHistoryItemProps> = ({ transaction, index }) => {
  const { t } = useTranslation();
  const token = UserHooks.useUserToken();
  const env = AppHooks.useEnvironment();

  const dateCreated = moment(transaction.dateCreated).format('MMM D, YYYY');
  const timeCreated = moment(transaction.dateCreated).format('h:mm A');
  const today = moment().format('MMM D, YYYY');
  const visibleDate = dateCreated === today ? t('labels:today_lowercase') : dateCreated;
  const [viewReceipt, setViewReceipt] = useState(false);

  const transactionReceiptUrl = useMemo(() => {
    if (!transaction.docId) {
      return;
    }
    let url = config.API_URL + '/marketplace/';

    const docType = UserTransactionType[transaction.docType as keyof typeof UserTransactionType];

    if (!docType) {
      return;
    }

    url += docType + transaction.docId + '?jwt=' + token;
    return url;
  }, [transaction]);

  const handleClick = useCallback(() => {
    if (transactionReceiptUrl) {
      setViewReceipt(true);
    }
  }, [transactionReceiptUrl]);

  let isPositive = false;
  let colorType: string;

  switch (transaction.docType) {
    case 'PAYMENT':
      colorType = 'payment';
      break;
    case 'DEPOSIT':
      isPositive = true;
      if (transaction.docId) {
        colorType = 'positive';
      } else {
        colorType = 'negative';
      }
      break;
    case 'INVOICE':
    case 'REFUND':
      isPositive = false;
      colorType = 'negative';
      break;
    default:
      colorType = 'negative';
      break;
  }

  return (
    <TransactionsGridRow key={`transaction-${index}`} onClick={() => handleClick()}>
      <TransactionsGridContent>
        <TransactionAmount>
          <TransactionAssets type={colorType}>
            {isPositive ? '+' : '-'} {numberToAmountLabel(parseInt(transaction.amount))}
          </TransactionAssets>
        </TransactionAmount>
        <TransactionType>{t(`transactions:${transaction.docType?.toLowerCase()}`)}</TransactionType>
        {transactionReceiptUrl && <TransactionAction>{t('buttons:view')}</TransactionAction>}
      </TransactionsGridContent>

      <TransactionsGridDetails>
        <TransactionsDetails>
          <div>{t('transactions:payment_method')}</div>
          <div>{t(`transactions:${transaction.payType?.toLowerCase()}`)}</div>
        </TransactionsDetails>
        <TransactionsDetails>
          <div>{t('labels:date')}</div>
          <div>
            {transaction.dateCreated && visibleDate}, {timeCreated}
          </div>
        </TransactionsDetails>
      </TransactionsGridDetails>
      {transactionReceiptUrl && viewReceipt && (
        <div onClick={() => setViewReceipt(false)}>
          <PDFPortal
            fileLocation={transactionReceiptUrl}
            onClickClose={() => setViewReceipt(false)}
          />
        </div>
      )}
    </TransactionsGridRow>
  );
};

export default TransactionHistoryItem;
