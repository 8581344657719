import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

export const TopUpContainer = styled.div`
  font-size: 13px;
  color: ${COLORS.new_gray};
  width: 380px;
  > * {
    font-family: BasierCircle;
  }

  @media (max-width: ${sizes.M}) {
    width: 100%;
    padding: 0 20px;
  }
`;
export const TopUpTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
  color: ${COLORS.rhino_black};
  margin-top: 10px;
  margin-bottom: 14px;
`;
export const TopUpDescription = styled.div`
  margin-bottom: 30px;
  color: ${COLORS.san_juan_grey};
  font-size: 16px;
  > div {
    margin-bottom: 4px;
  }
`;

export const TopUpRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  > div {
    white-space: nowrap;
    margin-top: 8px;
    gap: 6px;
  }
`;

export const TopUpBalanceRow = styled(TopUpRow)`
  margin-bottom: 8px;
  > div:first-child {
    > div:first-child {
      border: none;
      font-size: 19px;
      padding: 0;
      margin-bottom: 8px;
    }
    > div:last-child {
      width: initial;
      border: none;
      background: none;
    }
  }
  > input {
    border: none;
    color: ${COLORS.san_juan_grey};
    background: transparent;
    text-align: right;
    font-size: 19px;
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }
`;

export const TopUpInfoBalance = styled.div`
  margin-bottom: 20px;
  background: ${COLORS.zircon_light_blue};
  border-radius: 12px;
  padding: 12px 14px;
`;

export const TopUpMethodsText = styled(TopUpRow)`
  margin-bottom: 20px;
`;

export const TopUpMethodsCard = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const TopUpSubmit = styled.button`
  background: ${COLORS.blumine_main_blue};
  border-radius: 8px;
  padding: 12px 28px 14px;
  font-size: 17px;
  font-weight: 600;
  color: ${COLORS.white};
  border: none;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media (max-width: ${sizes.M}) {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 18px;
  cursor: pointer;
`;
