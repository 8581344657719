import { ItemDocument } from '@pickles/shared/models';
import Image from 'next/image';
import React, { FC } from 'react';
import { DescriptionContentItemView, DescriptionContentItemViewImageHolder } from '../../style';
import carDetailShowInfoIcon from '/public/icons/carDetailShowInfoIcon.svg';

interface IViewFilesComponent {
  documents?: ItemDocument[];
  setPdfLink: (link: string) => void;
}

const ViewFilesComponent: FC<IViewFilesComponent> = ({ documents, setPdfLink }) => {
  return (
    <>
      {Array.isArray(documents) &&
        documents?.map((document, index) => (
          <DescriptionContentItemView key={index} onClick={() => setPdfLink(document?.location)}>
            <DescriptionContentItemViewImageHolder>
              <Image src={carDetailShowInfoIcon} alt="carDetailShowInfoIcon" />
            </DescriptionContentItemViewImageHolder>
            View
          </DescriptionContentItemView>
        ))}
    </>
  );
};

export default ViewFilesComponent;
