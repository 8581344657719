import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

interface IAuctionGridComponentWrapper {
  isRunning: boolean;
}

export const AuctionCardStyle = styled.div<IAuctionGridComponentWrapper>`
  width: 370px;
  height: 100%;
  padding: 16px;
  border: ${({ isRunning }) => (isRunning ? `1.5px solid ${colors.cerulean_blue}` : 'none')};
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;

  @media (min-width: ${sizes.XL}) {
    width: 370px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex: 1;
  /* min-height: 165px; */
`;

export const CarImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  @media (max-width: ${sizes.XL}) {
    margin-right: 10px;
  }
`;

export const ImgWrapper = styled.div`
  width: 72px;
  height: 72px;
  margin-bottom: 6px;
  border-radius: 6px;
  overflow: hidden;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateDisplay = styled.p`
  font-weight: 600;
  font-size: 17px;
  color: ${colors.blumine_main_blue};
  text-align: center;
`;

export const TimeDisplay = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: ${colors.blumine_main_blue};
  letter-spacing: 5%;
  margin-top: 2px;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.rhino_black};
  text-align: left;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Description = styled.h4`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.san_juan_grey};
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const AuctionTypeContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
`;

export const AuctionType = styled.div`
  padding: 4px 6px;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  color: ${colors.san_juan_grey};
  text-transform: uppercase;
  background: ${colors.zircon_light_blue};
  border-radius: 6px;
  margin-right: 8px;
`;

export const UnitCount = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 14px;
  color: ${colors.san_juan_grey};

  @media (max-width: ${sizes.XL}) {
    margin-left: 5px;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const LocationText = styled.p`
  font-size: 14px;
  line-height: 20px;
  flex: 1;
  color: ${colors.san_juan_grey};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const PinIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6.5px;
  width: 16px;
  height: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid ${colors.mischka};
  margin-bottom: 16px;
  @media (max-width: ${sizes.S}) {
    display: none;
  }
`;

export const BottomSection = styled.div`
  align-items: center;
  justify-content: space-between;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div<{ isComputerIcon?: boolean }>`
  display: flex;
  align-items: center;
  font-family: BasierCircle;
  font-size: 15px;
  color: ${colors.san_juan_grey};
  margin-left: ${({ isComputerIcon = false }) => (isComputerIcon ? '17.5px' : '0')};
`;

export const IconImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5.5px;
`;
interface IButtonsWrapper {
  isAttend?: boolean;
}
export const ButtonsWrapper = styled.div<IButtonsWrapper>`
  display: flex;
  align-items: center;
  @media (max-width: ${sizes.S}) {
    > div {
      white-space: nowrap;
    }
    ${({ isAttend }) =>
      isAttend &&
      `> div:first-child{
      display: none;
    }`};
  }
`;

export const LocationCityName = styled.div`
  @media (max-width: ${sizes.S}) {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100vw - 312px);
    white-space: pre;
  }
`;

// TODO:: make default base button
export const ViewButton = styled.div`
  padding: 9px 20px;
  background: ${colors.selago};
  border-radius: 8px;
  color: ${colors.blumine_main_blue};
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
`;

export const AttendButton = styled.div`
  padding: 9px 18px;
  background: ${colors.blumine_main_blue};
  border-radius: 8px;
  color: ${colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  margin-left: 8px;
`;

export const LiveMark = styled.div`
  position: absolute;
  text-transform: uppercase;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 24px;
  top: -1px;
  right: -1px;
  border-bottom-left-radius: 11px;
  border-top-right-radius: 11px;
  overflow: hidden;
  background: ${colors.cerulean_blue};
  color: ${colors.white};
`;

export const WinningAndOutbidsWrapper = styled.div`
  margin-left: 26px;
  font-family: BasierCircle;
  font-size: 12px;
  color: ${colors.san_juan_grey};
  width: 62px;

  > :last-child {
    margin-top: 5px;
  }
`;

export const SmallTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColoredSpan = styled.span<{ color: string }>`
  font-family: BasierCircle-Semibold;
  font-size: 13px;
  color: ${({ color }) => color};
`;

export const LocationContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${sizes.S}) {
    > div {
      margin-left: 0;
    }
    > div:nth-child(2) {
      display: block;
    }
  }
`;

export const AuctionGridActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 10px;
`;
