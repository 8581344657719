import config from '@pickles/shared/config/env';
import React, { useState } from 'react';
import { FilterTypes } from '.';
import { FilterArrowDown, FilterHeader, FilterOptions, FilterSelectContainer } from './styles';

type PropsType = {
  options: string[];
  selectedIndex?: number;
  placeholder?: string;
  type: FilterTypes;
};
const { STRAPI_URL } = config;

export const Select = (props: PropsType) => {
  const { placeholder, type, selectedIndex, options } = props;
  const [selectedSelectIndex, setSelectedSelectIndex] = useState(
    selectedIndex !== undefined ? selectedIndex : -1,
  );
  const [isOpened, setIsOpened] = useState(false);
  const filterItem = selectedSelectIndex !== -1 ? options[selectedSelectIndex] : placeholder;
  const opts = options
    .filter((opt) => opt !== filterItem)
    .map((opt, index) => (
      <div key={`key-${index}-${opt}`} onClick={() => setSelectedSelectIndex(index)}>
        {type === FilterTypes.Image ? <img src={opt} /> : opt}
      </div>
    ));
  const onClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <FilterSelectContainer onClick={onClick} isOpened={isOpened}>
      <FilterHeader isOpened={isOpened}>
        <div>{type === FilterTypes.Image ? <img src={filterItem} /> : filterItem} </div>{' '}
        <FilterArrowDown isOpened={isOpened} />
      </FilterHeader>

      {isOpened && options.length && (
        <FilterOptions>
          {filterItem !== placeholder && (
            <div onClick={() => setSelectedSelectIndex(-1)}>{placeholder}</div>
          )}
          {opts}
        </FilterOptions>
      )}
    </FilterSelectContainer>
  );
};
