import styled from 'styled-components';
import sizes from '../../../../util/sizes';

export const ConnectAccountWrapper = styled.div`
  padding: 32px 0;
  background: linear-gradient(180deg, #1c4d8e 0%, #122e52 100%);
  width: 100%;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
  }
`;

export const ConnectAccountHeader = styled.div`
  margin-left: 24px;
  font-family: BasierCircle-Semibold;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px;
`;

export const ConnectAccountText = styled.div`
  line-height: 1.4;
  margin: 0 12px 0 24px;
  font-family: BasierCircle-Medium;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 20px;
`;

export const ConnectAccountBtnWrapper = styled.div`
  width: 100%;
  padding: 0 23px;
`;

export const ConnectAccountBtn = styled.div`
  width: 100%;
  height: 56px;
  color: #08aade;
  border-radius: 8px;
  background: #08aade;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  cursor: pointer;
`;
