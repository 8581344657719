import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../../util/sizes';

export const Wrapper = styled.div`
  margin-bottom: 42px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 24px;
  }
`;

export const ShortDetailWrapper = styled.div`
  padding: 24px 24px 30px;
  border-radius: 11px;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.09);
  /* margin-bottom: 52px; */
  @media (max-width: ${sizes.XL}) {
    padding: 48px 24px 30px;
  }

  @media (max-width: ${sizes.M}) {
    padding: 26px;
  }
`;

export const ShortDetailTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  > div {
    width: fit-content;
  }
`;

export const ViewsCountText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 13px;
  color: ${colors.san_juan_grey};
  line-height: 20px;
`;

export const CarMarkText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 32px;
  color: ${colors.rhino_black};
  margin-bottom: 10px;
  line-height: 36px;
`;

export const KmText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: ${colors.san_juan_grey};
  margin-bottom: 9px;
  line-height: 20px;
`;

export const LocationTextWrapper = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  color: ${colors.san_juan_grey};
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

export const LocationText = styled.span`
  margin-left: 6px;
`;

export const ShortDetailBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftColumnSyle = styled.div`
  margin-right: 25px;
`;

export const LeftColumnLabel = styled.div`
  font-family: BasierCircle;
  font-size: 13px;
  color: ${colors.san_juan_grey};
  margin-bottom: 2px;
`;

export const LeftColumnText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 23px;
  color: ${colors.blumine_main_blue};
`;

export const DepositReserveWrapper = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DepositWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DepositReserveText = styled.div<{ marginRight?: number }>`
  font-family: BasierCircle;
  font-size: 13px;
  color: ${colors.san_juan_grey};
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
`;

export const DepositReserveCountText = styled.div`
  display: flex;
  flex-direction: row;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: ${colors.rhino_black};
`;

export const StatusWrapper = styled.div`
  width: 133px;
  height: 33px;
  border: 1px solid #df2955;
  background: rgba(223, 41, 85, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: ${colors.san_juan_grey};
  line-height: 15.6px;
`;

export const StatusDot = styled.div`
  width: 7px;
  height: 7px;
  background: #df2955;
  border-radius: 50%;
  margin-right: 6px;
`;

export const CarStockRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 5px;

  > div {
    font-family: BasierCircle;
    font-size: 13px;
    color: ${colors.san_juan_grey};
    line-height: 20px;
  }

  span {
    font-size: 14px;
    font-family: BasierCircle-Medium;
  }
`;

export const AuctionText = styled.div`
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
`;

export const ReserveHoverableCircle = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${colors.bali_hai_grey};
  margin-left: 5px;

  :hover div {
    display: block;
  }

  div {
    position: relative;
    display: none;
    background: ${colors.white};
    border: 1px solid ${colors.mischka};
    border-radius: 6px;
    font-family: BasierCircle-Medium;
    font-size: 15px;
    color: ${colors.rhino_black};
    top: 5px;
    left: -76px;
    width: 170px;
    padding: 5px;
  }
`;
