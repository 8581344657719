import React, { FC } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import carDetailHashtagIcon from '/public/icons/carDetailHashtagIcon.svg';

import { BidHistoryWrapper, BidsCount, ImgWrapper, TotalBidsText, ViewHistoryBtn } from './style';

interface IBidHistory {
  bidCount: number;
  openBidHistory: () => void;
}

const BidHistory: FC<IBidHistory> = ({ bidCount = 0, openBidHistory }) => {
  const { t } = useTranslation();

  return (
    <BidHistoryWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ImgWrapper>
          <Image src={carDetailHashtagIcon} alt="Hashtag icon" />
        </ImgWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TotalBidsText>{t('labels:total_bids')}:</TotalBidsText>
          <BidsCount>{bidCount}</BidsCount>
        </div>
      </div>
      {bidCount > 0 && (
        <div>
          <ViewHistoryBtn
            onClick={() => {
              openBidHistory();
            }}
          >
            View History
          </ViewHistoryBtn>
        </div>
      )}
    </BidHistoryWrapper>
  );
};

export default BidHistory;
