import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { WebHooks } from '../../../app/redux/hooks';
import { HistoryLineWrapper } from './styles';
import _ from 'lodash';

const initialRouteNames: NavItemsType[] = ['home'];

const menuRoutes = {
  home: 'Home',
  news: 'News',
};

const hiddenRouteName = ['buy'];

type NavItemsType = keyof typeof menuRoutes;
type PropsType = {
  routeTitle?: string;
};

export const HistoryLine: FC<PropsType> = ({ routeTitle }) => {
  const router = useRouter();
  const headerLinks = WebHooks.useHeaderLinks();
  const navItems = router?.asPath.split('/').slice(1) || [];
  const allNavItems = [...initialRouteNames, ...navItems];

  const getRouteTitle = (sub: string) => {
    const flatSubRoutes = _.flatten(_.values(headerLinks));
    const _routeTitle = flatSubRoutes.find((route) => route.id === sub)?.title;
    return _routeTitle;
  };

  const navElements = allNavItems
    .filter((item) => !hiddenRouteName.includes(item))
    .map((item, index) => {
      const newsItem = item.indexOf('news') !== -1 ? menuRoutes.news : item;
      const currentRouteName = routeTitle || getRouteTitle(item);
      const navItem =
        currentRouteName && newsItem !== menuRoutes.news
          ? currentRouteName
          : newsItem.replace('-', ' ');
      const nav = item === initialRouteNames[0] ? item : navItem;

      return (
        <div
          key={index}
          onClick={() => {
            handleItemPress(item);
          }}
        >
          {nav}
        </div>
      );
    });

  const handleItemPress = (route: string) => {
    let _routeName = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allNavItems.length; i++) {
      if (route === menuRoutes.home.toLowerCase()) {
        break;
      } else if (allNavItems[i] !== menuRoutes.home.toLowerCase() && route !== allNavItems[i]) {
        _routeName += `${allNavItems[i]}/`;
      } else if (allNavItems[i] !== menuRoutes.home.toLowerCase() && route === allNavItems[i]) {
        _routeName += `${allNavItems[i]}`;
        break;
      }
    }
    router.replace(`/${_routeName}`);
  };
  return <HistoryLineWrapper>{navElements}</HistoryLineWrapper>;
};
