import COLORS from '@pickles/shared/utils/colors';
import styled, { CSSProperties } from 'styled-components';
import sizes from '../../util/sizes';

interface IDescriptionWrapper {
  marginBottom: number;
}

interface IDescriptionContentWrapper {
  width?: string;
}

interface IDescriptionColumn {
  size: number;
  color: string;
}

interface IDescriptionContent {
  isWithoutTitle?: boolean;
  hasFullDescription?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
}

interface IDescriptionContentTable {
  isWithoutTitle?: boolean;
  hasFullDescription?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
}

interface IRemarkWrapper {
  marginBottom?: number;
}

interface IDivider {
  marginBottom?: number;
}

export const CarDetailWrapper = styled.div`
  margin: 0 auto 112px;
  max-width: ${sizes.XL};
  position: relative;

  @media (max-width: ${sizes.XL}) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: ${sizes.M}) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: ${sizes.S}) {
    margin: 0;
  }
`;

export const TopLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
  width: 100%;

  #back-button-wrapper {
    padding-left: 0;
  }

  @media (max-width: ${sizes.S}) {
    padding: 20px;
    margin-bottom: 0;
  }
`;

export const IconsWrapper = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  padding-top: 15px;
  margin-right: ${({ marginRight = 0 }) => marginRight}px;

  @media (max-width: ${sizes.M}) {
    padding-top: 0;
    padding-bottom: 18px;
  }
`;

export const ShareIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;

  @media (max-width: ${sizes.S}) {
    display: none;
  }
`;

export const LikeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  width: 100%;

  @media (max-width: ${sizes.XL}) {
    column-gap: 30px;
  }
`;

export const MainBlock = styled.div`
  flex: 1;
  padding-bottom: 30px;
  min-width: 0;

  @media (max-width: ${sizes.XL}) {
    .image-gallery-slide > div > img {
      object-fit: cover !important;
    }
  }

  @media (max-width: ${sizes.M}) {
    margin-right: 0;
    flex: 1;
  }

  @media (max-width: ${sizes.S}) {
    width: 100%;
  }
`;

export const DescriptionWrapper = styled.div<IDescriptionWrapper>`
  display: flex;
  /* width: 740px; */
  column-gap: 50px;
  // align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  @media (max-width: ${sizes.M}) {
    /* flex-wrap: wrap; */
    margin-bottom: 0;
  }

  @media (min-width: ${sizes.M}) and (max-width: ${sizes.XL}) {
    column-gap: 15px;
  }

  @media (max-width: ${sizes.S}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const DescriptionContentWrapper = styled.div<IDescriptionContentWrapper>`
  width: ${({ width }) => width || '50%'};

  @media (max-width: ${sizes.M}) {
    width: 100%;
  }
`;

export const DescriptionContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  color: ${COLORS.rhino_black};
  line-height: 26px;
`;

export const DescriptionContentTitleImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
`;

export const DescriptionContent = styled.div<IDescriptionContent>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  margin-top: ${({ isWithoutTitle }) => (isWithoutTitle ? '24px' : '0')};

  @media (max-width: ${sizes.M}) {
    margin-top: 0;
    margin-bottom: ${({ hasFullDescription }) => (hasFullDescription ? '0' : '32px')} !important;
  }
`;

export const DescriptionColumn = styled.div<IDescriptionColumn>`
  display: flex;
  flex-direction: column;
  font-family: BasierCircle-Medium;
  font-size: ${({ size }) => size}px;
  color: ${({ color }) => color};
  width: 140px;
  @media (max-width: ${sizes.M}) {
    width: 50%;
  }
`;

export const DescriptionContentItem = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DescriptionContentItemView = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 20px;
  justify-content: center;
`;

export const DescriptionContentItemViewImageHolder = styled.div`
  margin-right: 6.5px;
  display: flex;
  align-items: center;
`;

export const DescriptionContentItemViewInactive = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: grey;
  line-height: 20px;
  justify-content: center;
`;

export const Divider = styled.div<IDivider>`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #d6dadf;
  margin-bottom: ${({ marginBottom = 24 }) => marginBottom}px;

  @media (max-width: ${sizes.S}) {
    // display: none;
    &.item-detail-divider {
      display: none;
    }
  }
`;

export const RemarkWrapper = styled.div<IRemarkWrapper>`
  padding: 18px 18px 22px 19px;
  width: 100%;
  background: ${COLORS.zircon_light_blue};
  border-radius: 12px;
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
`;

export const RemarkTitle = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  color: ${COLORS.rhino_black};
  display: flex;
  align-items: center;
  margin-bottom: 11px;
`;

export const RemarkText = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
  margin-top: 15px;
  color: ${COLORS.rhino_black};
`;
export const SideBlockHideButton = styled.div`
  @media (max-width: ${sizes.XL}) {
    position: absolute;
    right: 15px;
    top: 60px;
    width: fit-content !important;
    cursor: pointer;
    z-index: 102;
    img {
      filter: invert(0.7) contrast(0.5);
    }
  }
`;
export const SideBlock = styled.div<{ isHidden?: boolean }>`
  align-self: flex-start;
  flex: 1;
  /* width: 40%; */
  max-width: 380px;
  flex-shrink: 0;
  position: sticky;
  top: 20px;
  right: 0;
  z-index: 2;

  @media (min-width: ${sizes.L}) and (max-width: ${sizes.XL}) {
    max-width: 340px;
  }

  @media (min-width: ${sizes.M}) and (max-width: ${sizes.L}) {
    max-width: 300px;
  }

  @media (max-width: ${sizes.M}) {
    display: ${({ isHidden }) => isHidden && 'none'};
  }
`;

export const ShortDetailWrapper = styled.div`
  padding: 24px 24px 30px;
  padding-top: 50px;
  border-radius: 11px;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.09);
`;

export const ShortDetailTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const ToBeAnnouncedText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: ${COLORS.jaffa};
  display: flex;
  align-items: center;
`;

export const RandomCarTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const CarCountText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  color: ${COLORS.rhino_black};
`;

export const ViewAllText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 15px;
  color: ${COLORS.blumine_main_blue};
  cursor: pointer;
`;

export const DescriptionContentTable = styled.table<IDescriptionContentTable>`
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  margin-top: ${({ isWithoutTitle }) => (isWithoutTitle ? '24px' : '0')};
  @media (max-width: ${sizes.M}) {
    margin-top: 0;
    margin-bottom: ${({ hasFullDescription }) => (hasFullDescription ? '0' : '32px')} !important;
  }

  th,
  td,
  tr {
    border-collapse: collapse;
  }
`;

export const BalanceBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 45%;
`;
