import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../../../util/sizes';

export const BidHistoryWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 8px 20px 8px 15px;
  background: ${colors.zircon_light_blue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: ${sizes.M}) {
    /* display: none; */
    /* width: 94%; */
  }
`;

export const ViewHistoryBtn = styled.div`
  cursor: pointer;
  font-family: BasierCircle-Semibold;
  font-size: 15px;
  color: ${colors.blumine_main_blue};
`;

export const ImgWrapper = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 11px;
`;

export const TotalBidsText = styled.div`
  font-family: BasierCircle;
  font-size: 14px;
  color: ${colors.san_juan_grey};
  margin-right: 3px;
`;

export const BidsCount = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 16px;
  color: ${colors.san_juan_grey};
`;
