import { ChangeEvent, FC, useRef } from 'react';

import { BoldText, CheckboxText, CheckboxWrapper, TextWrapper } from './styles';
import Checkbox from '../../Checkbox/Checkbox';

interface ICheckboxProps {
  isChecked: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  bolderText?: string;
  id: string;
  name: string;
}

export const FormCheckbox: FC<ICheckboxProps> = ({
  isChecked,
  handleChange,
  text,
  bolderText,
  id,
  name,
}) => {
  const wrapperRef = useRef<HTMLInputElement>(null);

  const wrapperClickHandler = () => {
    wrapperRef?.current?.click();
  };

  return (
    <CheckboxWrapper>
      <Checkbox
        inputRef={wrapperRef}
        id={id}
        name={name}
        isChecked={isChecked}
        handleChecked={handleChange}
      />
      <TextWrapper onClick={wrapperClickHandler}>
        <CheckboxText>{text}</CheckboxText>&nbsp;
        {bolderText && <BoldText>{bolderText}</BoldText>}
      </TextWrapper>
    </CheckboxWrapper>
  );
};
