import React from 'react';
import Image from 'next/image';

import { PaymentTypeWrapper, PaymentTypeContentWrapper } from './PaymentMethod.style';

interface IPaymentTypeWrapper {
  activeType: boolean;
  icons: StaticImageData[];
  paymentMethod: string;
  setPaymentMethod: (text: string) => void;
  text: string;
}

const PaymentMethod = ({
  activeType,
  icons,
  paymentMethod,
  setPaymentMethod,
  text,
}: IPaymentTypeWrapper) => {
  const renderIcons = (icons: StaticImageData[]) => {
    return icons?.map((image, index) => (
      <Image src={image} alt={paymentMethod} key={`${index}-${paymentMethod}`} />
    ));
  };

  return (
    <PaymentTypeWrapper activeType={activeType} onClick={() => setPaymentMethod(paymentMethod)}>
      <PaymentTypeContentWrapper>
        {renderIcons(icons!)}
        <div>{text}</div>
      </PaymentTypeContentWrapper>
    </PaymentTypeWrapper>
  );
};

export default PaymentMethod;
