import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';

interface IWrapperProps {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  width?: string;
}

interface ITextareaProps {
  borderRadius?: number;
  color?: string;
  height?: number;
  paddingLeft?: number;
  paddingTop?: number;
  error?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  margin-bottom: ${({ marginBottom = 30 }) => marginBottom}px;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
  width: ${({ width = '100%' }) => `calc(${width})`};
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const FieldHeading = styled.div`
  font-family: BasierCircle-Medium;
  font-weight: 400;
  font-size: 15px;
  color: ${COLORS.rhino_black};
`;

export const TextareaField = styled.textarea<ITextareaProps>`
  border: 1px solid ${({ error }) => (error ? COLORS.cerise_red : COLORS.mischka)};
  border-radius: ${({ borderRadius = 8 }) => borderRadius}px;
  padding-left: ${({ paddingLeft = 42 }) => paddingLeft}px;
  padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
  color: ${COLORS.san_juan_grey};
  color: ${COLORS.san_juan_grey};
  height: ${({ height = 144 }) => height}px;
  padding-top: 12px;
  outline: none;
  font-family: BasierCircle-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background: transparent;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${COLORS.bali_hai};
    font-size: 15px;
    font-family: BasierCircle;
  }
`;
