import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BidComponentWrapper, BiddingContainer } from '../../BidComponent.style';
import {
  ConnectAccountWrapper,
  ConnectAccountHeader,
  ConnectAccountText,
  ConnectAccountBtn,
  ConnectAccountBtnWrapper,
} from './style';

const ConnectAccountComponent = () => {
  const router = useRouter();

  const { t } = useTranslation();

  const onSignInBtnHandler = useCallback(() => {
    router.push('/login');
  }, []);

  return (
    <BiddingContainer>
      <BidComponentWrapper>
        <ConnectAccountWrapper>
          <ConnectAccountHeader>{t('titles:connect_acc')}</ConnectAccountHeader>
          <ConnectAccountText>{t('infos:must_login_to_bid')}</ConnectAccountText>
          <ConnectAccountBtnWrapper>
            <ConnectAccountBtn onClick={onSignInBtnHandler}>Sign in</ConnectAccountBtn>
          </ConnectAccountBtnWrapper>
        </ConnectAccountWrapper>
      </BidComponentWrapper>
    </BiddingContainer>
  );
};

export default ConnectAccountComponent;
