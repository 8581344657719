import sizes from '../../../util/sizes';
import Container from '../../Shared/Container';
import Banner from './Banner';
import UsefulLinks from './UsefulLinks';

const PageNotFoundContent = () => {
  return (
    <Container>
      <Container style={{ maxWidth: sizes.XL }}>
        <Banner />
        <UsefulLinks />
      </Container>
    </Container>
  );
};

export default PageNotFoundContent;
