import React, { FC } from 'react';

import { ProfileTabPanelContainer, ProfileTabPanelItem } from './ProfileTabPanel.style';
import { ProfileTabNames } from '..';
import map from 'lodash/map';

interface PropsType {
  activeTab: ProfileTabNames;
  onClick: (tab: ProfileTabNames) => void;
}

export const ProfileTabPanel: FC<PropsType> = ({ activeTab, onClick }) => {
  const tabItems = map(Object.keys(ProfileTabNames), (tab, index) => (
    <ProfileTabPanelItem
      onClick={() => {
        onClick(tab as ProfileTabNames);
      }}
      isActive={tab === activeTab}
      key={`account-tab-${tab}`}
    >
      {tab}
    </ProfileTabPanelItem>
  ));

  return <ProfileTabPanelContainer>{tabItems}</ProfileTabPanelContainer>;
};
