import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config';
import Config from '@pickles/shared/config/env';
import { IAuction } from '@pickles/shared/models';
import { getAuctionRefByAuction } from '@pickles/shared/utils/routes';
import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useScreenSize from '../../hooks/useScreenSize';
import sizes from '../../util/sizes';
import LiveAuctionModal from '../LiveAuctionModal';
import {
  BidBalanceText,
  BidComponentBtn,
  BidComponentWrapper,
  BiddingContainer,
  BidWrapper,
  Wrapper,
} from './BidComponent.style';

interface AttendBiddingProps {
  auction: IAuction.Auction;
}

const AttendBidding: FC<AttendBiddingProps> = ({ auction }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const [isLiveModalVisible, setIsLiveModalVisible] = useState(false);

  const showLiveComingSoonModal = () => {
    !isLiveModalVisible && setIsLiveModalVisible(true);
  };

  const handleAttendButtonPress = () => {
    if (Config.canAttendLiveAuction()) {
      const auctionRef = getAuctionRefByAuction(auction);
      router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}/live`);
    } else {
      showLiveComingSoonModal();
    }
  };

  return (
    <BiddingContainer>
      <BidComponentWrapper>
        <AttendWrapper>
          <Wrapper>
            <BidBalanceText>{t('infos:auction_started')}</BidBalanceText>
          </Wrapper>
          <Wrapper>
            <BidComponentBtn
              id="attend-btn"
              variant="normal"
              fullWidth={isMobile}
              onClick={handleAttendButtonPress}
            >
              {t('buttons:attend')}
            </BidComponentBtn>
          </Wrapper>
        </AttendWrapper>
      </BidComponentWrapper>
      <LiveAuctionModal isOpened={isLiveModalVisible} setIsOpened={setIsLiveModalVisible} />
    </BiddingContainer>
  );
};

export default AttendBidding;

const AttendWrapper = styled(BidWrapper)`
  display: flex;
  flex-direction: column;

  @media (max-width: ${sizes.M}) {
    flex-direction: row;
    padding: 10px 16px;
    align-items: center;
  }

  @media (max-width: ${sizes.S}) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
