import Image from 'next/image';
import React, { useState } from 'react';
import { lockIcon, profileAccountIcon } from '../Shared/Icons';
import { ChangePassword } from './ChangePassword';
import { ProfileDetails } from './ProfileDetails';

import { ProfileHistoryBlock, ProfileHistoryContainer } from './ProfileDetails.style';

enum ProfileMenuItems {
  ProfileDetails = 'Profile details',
  ChangePassword = 'Change password',
}

const profileMenuData = [
  {
    icon: profileAccountIcon,
    text: ProfileMenuItems.ProfileDetails,
  },
  {
    icon: lockIcon,
    text: ProfileMenuItems.ChangePassword,
  },
];

export const Profile = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<ProfileMenuItems>(profileMenuData[0].text);

  const transactionMenuItems = profileMenuData.map((item) => (
    <ProfileHistoryBlock
      isActive={activeMenuItem === item.text}
      onClick={() => setActiveMenuItem(item.text)}
      key={`transaction-history-block-${item.text}`}
    >
      <Image src={item.icon} alt={item.text} />
      <div>{item.text}</div>
    </ProfileHistoryBlock>
  ));

  const renderActiveTab = (activeMenuItem: ProfileMenuItems) => {
    switch (activeMenuItem) {
      case ProfileMenuItems.ProfileDetails:
        return <ProfileDetails />;
      case ProfileMenuItems.ChangePassword:
        return <ChangePassword />;
      default:
        return <ProfileDetails />;
    }
  };

  return (
    <ProfileHistoryContainer>
      <div>{transactionMenuItems}</div>
      {renderActiveTab(activeMenuItem)}
    </ProfileHistoryContainer>
  );
};
