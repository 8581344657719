import Image from 'next/image';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TopUpModal } from '../TopUpModal';
import { RefundModal } from '../RefundModal';

import { SUPPORT_PHONE } from '@pickles/shared/utils/config';
import { appActions } from '@pickles/shared/redux/actions';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import {
  DetailsEmail,
  DetailsUserName,
  ProfileHeaderAssets,
  ProfileHeaderInfo,
  DetailsAvailable,
  DetailsHeader,
  DetailsTopUp,
  DetailsRefund,
  DetailsAvailableHoldOnContainer,
  DetailsActions,
  DetailsHoldHeader,
  DetailsAvailableHoldOn,
  ProfileHeaderContainer,
  AvatarContainer,
} from './style';
import placeholderImg from '/public/images/accountImg.png';
import { UserHooks } from '../../app/redux/hooks';

interface IProfileHeader {
  isMobile?: boolean;
  setScreenTypeHandler?: () => void;
}
export const ProfileHeader = ({ isMobile = false, setScreenTypeHandler }: IProfileHeader) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = UserHooks.useUserAccount();

  const token = UserHooks.useUserToken();
  const [isOpenedTopUp, setIsOpenedTopUp] = useState(false);
  const [isOpenedRefund, setIsOpenedRefund] = useState(false);

  const showTopUpModal = () => {
    if (user?.status === 'PENDING') {
      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pending Approval',
          message: t('errors:still_pending_approval', { phone: SUPPORT_PHONE }),
        }),
      );
      return;
    }
    if (isMobile && setScreenTypeHandler) {
      return setScreenTypeHandler();
    }
    !isOpenedTopUp && setIsOpenedTopUp(true);
  };

  const showRefundModal = () => {
    if (isMobile && setScreenTypeHandler) {
      return setScreenTypeHandler();
    }
    !isOpenedRefund && setIsOpenedRefund(true);
  };

  return (
    <ProfileHeaderContainer>
      <ProfileHeaderInfo>
        <div>
          <AvatarContainer>
            <Image
              alt="profile"
              // TODO: load the profile picture
              src={user?.profpicUrl && token ? placeholderImg : placeholderImg}
              width={58}
              height={58}
            />
          </AvatarContainer>
        </div>
        <div>
          <DetailsUserName>{user?.username}</DetailsUserName>
          <DetailsEmail>{user?.email}</DetailsEmail>
        </div>
      </ProfileHeaderInfo>
      <ProfileHeaderAssets>
        <div>
          <DetailsAvailable>{numberToAmountLabel(user?.availableBalance || 0)}</DetailsAvailable>
          <DetailsHeader>{t('labels:current_balance')}</DetailsHeader>
        </div>
        <DetailsAvailableHoldOnContainer>
          <DetailsAvailableHoldOn>
            {numberToAmountLabel(user?.blockedAmount || 0)}
          </DetailsAvailableHoldOn>
          <DetailsHoldHeader>{t('labels:balance_hold_new')}</DetailsHoldHeader>
        </DetailsAvailableHoldOnContainer>
        <DetailsActions>
          <DetailsRefund onClick={showRefundModal}>{t('transactions:refund')}</DetailsRefund>
          <DetailsTopUp onClick={showTopUpModal}>+ {t('buttons:top_up')}</DetailsTopUp>
        </DetailsActions>
      </ProfileHeaderAssets>
      {!isMobile && <RefundModal isOpened={isOpenedRefund} setIsOpened={setIsOpenedRefund} />}
      {!isMobile && (
        <TopUpModal isMobile={isMobile} isOpened={isOpenedTopUp} setIsOpened={setIsOpenedTopUp} />
      )}
    </ProfileHeaderContainer>
  );
};
