import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import { ClickButton } from '../Shared/styles';

export const ChangePasswordContainer = styled.div`
  font-family: BasierCircle;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 275px;
    margin-top: 116px;
  }
`;

export const ChangePasswordText = styled.div`
  margin: 30px 0 24px;
  font-size: 15px;
  color: ${colors.san_juan_grey};
  text-align: center;
  line-height: 20px;
`;

export const ChangePasswordButton = styled(ClickButton)`
  background: ${colors.blumine_main_blue};
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  color: ${colors.white};
  padding: 12px 35.5px;
  outline: none;
  border: none;
`;

export const EmailSentBlock = styled.div``;
export const EmailSentText = styled.div`
  font-family: BasierCircle-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-indent: 10px;

  display: flex;
  align-items: center;
  text-align: center;

  color: ${colors.san_juan_grey};
`;
