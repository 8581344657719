import React, { useState } from 'react';

import { BlockedDeposit } from './BlockedDeposit';
import { TransactionsHistory } from './TransactionsHistory';
import { TransactionsHistoryBlock, TransactionsHistoryContainer } from './Transactions.style';

enum TransactionMenuItems {
  TransactionsHistory = 'Transactions history',
  BlockedDeposit = 'Blocked deposit',
}
import transactionHistoryIcon from '/public/icons/transactionHistory.svg';
import blockedDepositIcon from '/public/icons/blockedDeposit.svg';
import Image from 'next/image';
const transactionItems = [
  {
    icon: transactionHistoryIcon,
    text: TransactionMenuItems.TransactionsHistory,
  },
  {
    icon: blockedDepositIcon,
    text: TransactionMenuItems.BlockedDeposit,
  },
];

export const Transactions = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<TransactionMenuItems>(
    transactionItems[0].text,
  );

  const transactionMenuItems = transactionItems.map((item) => (
    <TransactionsHistoryBlock
      isActive={activeMenuItem === item.text}
      onClick={() => setActiveMenuItem(item.text)}
      key={`transaction-history-block-${item.text}`}
    >
      <Image src={item.icon} alt={item.text} width={28} />
      <div>{item.text}</div>
    </TransactionsHistoryBlock>
  ));

  const renderActiveTab = (activeMenuItem: TransactionMenuItems) => {
    switch (activeMenuItem) {
      case TransactionMenuItems.BlockedDeposit:
        return <BlockedDeposit />;
      case TransactionMenuItems.TransactionsHistory:
        return <TransactionsHistory />;
      default:
        return <TransactionsHistory />;
    }
  };

  return (
    <TransactionsHistoryContainer>
      <div>{transactionMenuItems}</div>
      {renderActiveTab(activeMenuItem)}
    </TransactionsHistoryContainer>
  );
};
