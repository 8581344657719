import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config';
import { IItem, IUser } from '@pickles/shared/models';
import { isAuctionRunning } from '@pickles/shared/models/helpers';
import { dashboardActions, itemActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import { checkAuctionEnded, getLotInfo, numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { getAuctionRefByAuction } from '@pickles/shared/utils/routes';

import {
  AppHooks,
  DashboardHooks,
  EbidAuctionHooks,
  LiveAuctionHooks,
} from '../../../../app/redux/hooks';
import Container from '../../../Shared/Container';
import { EyeShowIcon } from '../../../Shared/Icons';
import { Flex } from '../../../Shared/Layout/Flex';
import { ClockMessage } from '../../../Shared/Messages/ClockMessage';
import { PlaceMapMessage } from '../../../Shared/Messages/PlaceMapMessage';
import { Divider } from '../../style';
import BidHistory from './BidHistory';
import {
  AuctionText,
  CarMarkText,
  CarStockRow,
  DepositReserveCountText,
  DepositReserveText,
  DepositReserveWrapper,
  DepositWrapper,
  KmText,
  LeftColumnLabel,
  LeftColumnSyle,
  LeftColumnText,
  LocationTextWrapper,
  ReserveHoverableCircle,
  ShortDetailBottom,
  ShortDetailTop,
  ShortDetailWrapper,
  StatusDot,
  StatusWrapper,
  ViewsCountText,
  Wrapper,
} from './style';

interface IShortDetail {
  account: IUser.Account | null;
  item: IItem.Item;
  openBidHistory?: () => void;
}

const ShortDetail: FC<IShortDetail> = ({ account, item, openBidHistory }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const auction = DashboardHooks.useAuction(item?.saleEvent?.id);
  const auctionRef = useMemo(() => auction && getAuctionRefByAuction(auction), [auction]);
  const totalBids = EbidAuctionHooks.useTotalBids(item.id);
  const now = AppHooks.useApplicationTimer();
  const liveStatus = LiveAuctionHooks.useAuctionStatus();
  const liveItem = LiveAuctionHooks.useCurrentItem();
  const startAt =
    (item.bidding?.startTime && new Date(item.bidding?.startTime).valueOf()) ||
    item.saleEvent?.startAt;
  const endAt =
    (item.bidding?.endTime && new Date(item.bidding?.endTime).valueOf()) || item.saleEvent?.endAt;
  const isRunning = isAuctionRunning(item.eventType, item.bidding?.isRunning, startAt, endAt, now); // TODO: We should look into fixing item.bidding.isRunning
  const purchase = item.purchase;

  /* For debugging only
  useEffect(() => {
    if (!Config.isProd() && isRunning) {
      const msg = new SpeechSynthesisUtterance();
      msg.text = 'Auction is running';
      const voice = window.speechSynthesis.getVoices();
      msg.voice = voice?.[17];
      window.speechSynthesis.speak(msg);
    }
  }, [isRunning]);
  */

  useEffect(() => {
    /** To get auction info after page refresh.
     * Refreshing live page remove the auction info from state */
    if (!auction && item?.saleEvent?.id) {
      dispatch(dashboardActions.getAuction(item?.saleEvent?.id));
    }
  }, [auction]);

  useEffect(() => {
    if (item.bidding?.isOffer && !item.offer) {
      dispatch(itemActions.getOffers());
    }
  }, [dispatch, item]);

  const { label, value } = useMemo(() => {
    const isLiveAuction = item?.isLive;
    const isEbidAuction = item?.isEbid;
    const includeSniperTime = true;
    const isEbidEnded = isEbidAuction && item && checkAuctionEnded(item, includeSniperTime);
    const isActiveLot =
      isLiveAuction && !!liveStatus?.Running && liveItem && +liveItem?.ExternalID === item.id;
    const isOfferItem = item?.bidding?.isOffer && !!item?.offer && !isNaN(item.offer?.offerAmount);
    const isSoldItem = !!item.purchase && !isNaN(item.purchase?.soldAmt);
    const hasBidForEbid = isEbidAuction && item?.bidding?.bidsCount;

    const showAskingBid =
      isLiveAuction &&
      isActiveLot &&
      !!auction?.simulcastLive &&
      liveStatus?.CurrentBidType === 'ASK';

    const showLiveCurrentBid =
      isLiveAuction &&
      !!auction?.simulcastLive &&
      isActiveLot &&
      liveStatus?.CurrentBidAmount &&
      !isNaN(liveStatus?.CurrentBidAmount);
    const showEbidCurrentBid =
      isEbidAuction && item.bidding && !isNaN(item?.bidding?.currentBidAmount);
    const showCurrentBid = showLiveCurrentBid || showEbidCurrentBid;
    const showEbidStartingBid = isEbidAuction && (!isEbidEnded && !hasBidForEbid);
    const showLiveStartingBid =
      isLiveAuction && item?.minStartingBidAmt && !isNaN(item.minStartingBidAmt);

      // For debugging only
      // console.table({
      //   isWinning: item.bidding?.isWinning,
      //   currentBidAmount: item.bidding?.currentBidAmount,
      //   nextBidAmount: item.bidding?.nextBidAmount,
      //   minStartingBidAmt: item?.minStartingBidAmt,
      // });

    const getLabel = () => {
      switch (true) {
        case isSoldItem:
          return t('labels:sold_amt');
        case isOfferItem:
          return t('labels:offer_amount');
        case showEbidStartingBid:
          return t('labels:start_price');
        case isEbidAuction && isEbidEnded:
          return t('labels:your_bid');
        case showAskingBid:
          return t('labels:asking_bid');
        case showCurrentBid:
          return t('labels:current_bid');
        default:
          return t('labels:start_price');
      }
    };

    const getValue = () => {
      switch (true) {
        case isSoldItem:
          return numberToAmountLabel(item?.purchase?.soldAmt);
        case isOfferItem:
          return numberToAmountLabel(item.offer?.offerAmount);
        case !account:
          return t('labels:at_auction');
        case isEbidAuction && isEbidEnded:
          return numberToAmountLabel(item.bidding?.lastBidAmount);
        case showAskingBid:
          return numberToAmountLabel(liveStatus?.CurrentBidAmount);
        case showLiveCurrentBid:
          return numberToAmountLabel(liveStatus?.CurrentBidAmount);
        case showEbidStartingBid:
          return numberToAmountLabel(item?.minStartingBidAmt);
        case showLiveStartingBid:
          return item?.minStartingBidAmt && !liveStatus?.Running
            ? numberToAmountLabel(item.minStartingBidAmt)
            : t('labels:at_auction');
        case showEbidCurrentBid:
          return numberToAmountLabel(item?.bidding?.currentBidAmount);
        default:
          return t('labels:at_auction');
      }
    };

    return { label: getLabel(), value: getValue() };
  }, [item, liveStatus, account, liveItem, isRunning]);

  return (
    <Wrapper id="short-details">
      <ShortDetailWrapper>
        {!purchase && (
          <ShortDetailTop>
            <ClockMessage item={item} />
            <Flex flexDirection="row" alignItems="center" gap="4px">
              <EyeShowIcon fillColor={colors.bali_hai_grey} width={12} height={12} />
              <ViewsCountText>{item?.viewsCount || 0} views</ViewsCountText>
            </Flex>
          </ShortDetailTop>
        )}
        <CarMarkText id="item_name">{item.name}</CarMarkText>
        <CarStockRow>
          <div>
            {t('labels:auction')}:{' '}
            <span id="auction_name">
              <AuctionText onClick={() => router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}`)}>
                {item.saleEvent?.name}
              </AuctionText>
            </span>
          </div>
        </CarStockRow>
        {item.stockNum && (
          <CarStockRow>
            <div>
              {t('labels:stock_number')} <span id="stock_number">{item.stockNum}</span>
            </div>
            <div>
              • {t('labels:lot_number')} <span id="lot_number">{item.saleEvent?.lotNum}</span>
            </div>
          </CarStockRow>
        )}
        <KmText>
          {getLotInfo(item?.vehicle?.odometer, item?.vehicle?.odoUnits, item?.sourceType)}
        </KmText>

        <LocationTextWrapper>
          <PlaceMapMessage name={item.yard?.city} />
        </LocationTextWrapper>
        <Divider marginBottom={24} />
        <ShortDetailBottom>
          <LeftColumnSyle>
            <LeftColumnLabel>{label}</LeftColumnLabel>
            <LeftColumnText>{value}</LeftColumnText>
          </LeftColumnSyle>
          {purchase?.needPayment ? (
            // TODO: ideally to read purchase.status dynamically instead of "PAYMENT"
            // but there is no such field currently
            <div>
              <LeftColumnLabel>Current status</LeftColumnLabel>
              <StatusWrapper>
                <StatusDot />
                PAYMENT
              </StatusWrapper>
            </div>
          ) : (
            <DepositReserveWrapper>
              <DepositWrapper>
                <DepositReserveText marginRight={6}>
                  {t('labels:deposit_amount')}
                </DepositReserveText>
                {/** TODO: check if prebid or min price */}
                <DepositReserveCountText>
                  {item.depositAmt
                    ? ` ${numberToAmountLabel(item.depositAmt)}`
                    : `${t('labels:no_price')}`}
                </DepositReserveCountText>
              </DepositWrapper>
              <DepositWrapper>
                <DepositReserveText marginRight={6}>
                  {item.bidding?.nextBidAmount && item.isEbid
                    ? t('labels:next_bid')
                    : t('labels:reserve')}
                </DepositReserveText>
                <DepositReserveCountText>
                  {item.bidding?.nextBidAmount && item.isEbid ? (
                    numberToAmountLabel(item.bidding?.nextBidAmount)
                  ) : (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {t('labels:no_price')}
                      </div>
                      <ReserveHoverableCircle>
                        ?<div>{t('messages:reserve_price_info')}</div>
                      </ReserveHoverableCircle>
                    </>
                  )}
                </DepositReserveCountText>
              </DepositWrapper>
            </DepositReserveWrapper>
          )}
        </ShortDetailBottom>
      </ShortDetailWrapper>
      {!purchase && openBidHistory && item.isEbid && isRunning && (
        <Container justifyContent="stretch" px="20px">
          <BidHistory bidCount={totalBids} openBidHistory={openBidHistory} />
        </Container>
      )}
    </Wrapper>
  );
};

export default ShortDetail;
