import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { IWeb } from '@pickles/shared/models';
import { appActions, webActions } from '@pickles/shared/redux/actions';

import { LoaderHooks, WebHooks } from '../../../app/redux/hooks';
import LoadingPortal from '../../Portal/LoadingPortal';
import Button from '../Button/Button';
import Container from '../Container';
import Input from '../Input/Input';
import { Textarea } from '../Textarea';
import { Title } from '../Title';
import { FormCheckbox } from './FormCheckbox';
import { CheckboxBlock, ContactTextWrapper, Form, InputsWrapper, InsideHeader } from './styles';

const inputMarginRight = 24;
const inputWidth = `50% - ${inputMarginRight / 2}px`;

type PropsType = {
  isHeader?: boolean;
  isMobile?: boolean;
  title?: string;
  description?: string;
};

const ContactUsSchema = Yup.object().shape({
  fullName: Yup.string()
    .max(100)
    .required('Full name is required'),
  phoneNumber: Yup.number()
    .typeError('You must specify numbers')
    .required('Phone number is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  hearFrom: Yup.string().optional(),
  message: Yup.string().required('Message is required'),
  industrialAuctionList: Yup.bool().default(true),
  salvageAuctionList: Yup.bool().default(true),
  usedCarAuctionList: Yup.bool().default(true),
  usedMotorAuctionList: Yup.bool().default(true),
});

export const ContactUsForm: FC<PropsType> = ({
  isHeader,
  isMobile = false,
  title,
  description = '',
}) => {
  const dispatch = useDispatch();
  const isLoading = LoaderHooks.useLoadingIndicator('submittingMailerContactUs');
  const contactUsForm = WebHooks.useContactUsForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();

  const {
    values: {
      fullName,
      phoneNumber,
      email,
      hearFrom,
      message,
      industrialAuctionList,
      salvageAuctionList,
      usedCarAuctionList,
      usedMotorAuctionList,
    },
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<IWeb.ContactUsForm>({
    initialValues: {
      type: 'CONTACT_US',
      fullName: '',
      phoneNumber: '',
      email: '',
      hearFrom: '',
      message: '',
      industrialAuctionList: true,
      salvageAuctionList: true,
      usedCarAuctionList: true,
      usedMotorAuctionList: true,
    },
    validationSchema: ContactUsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!executeRecaptcha) {
        dispatch(
          appActions.showAlert({
            alertType: 'dropdown',
            title: t('titles:failure'),
            message: t('infos:message_sent_error'),
            messageType: 'error',
          }),
        );
        return;
      }
      const token = await executeRecaptcha('contact_us');
      dispatch(webActions.submitMailerContactUs(values, token));
    },
  });

  useEffect(() => {
    if (contactUsForm?.status === 'reset') {
      resetForm();
    }
  }, [contactUsForm]);

  const headerName = title || 'Contact us today';

  return (
    <>
      <Container alignItems="center" margin={!isHeader ? '0 0 100px 0' : 0}>
        {!isHeader && <Title text={headerName} margin="0 0 18px" />}
        <ContactTextWrapper>
          {!isHeader && <ReactMarkdown>{description}</ReactMarkdown>}
          <Form onSubmit={handleSubmit} isMobile={isMobile}>
            {isHeader && <InsideHeader>{headerName}</InsideHeader>}
            <InputsWrapper>
              <Input
                id="fullName"
                name="fullName"
                title="Full name"
                placeholder="Full name"
                value={fullName}
                onChange={handleChange}
                width={isMobile ? '100%' : inputWidth}
                marginRight={!isMobile ? inputMarginRight : 0}
                borderRadius={8}
                paddingLeft={16}
                error={errors.fullName}
              />
              <Input
                id="phoneNumber"
                name="phoneNumber"
                title="Phone number"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={handleChange}
                width={isMobile ? '100%' : inputWidth}
                borderRadius={8}
                paddingLeft={16}
                error={errors.phoneNumber}
              />
              <Input
                name="email"
                title="Email"
                placeholder="Email address"
                id="email"
                value={email}
                onChange={handleChange}
                width={isMobile ? '100%' : inputWidth}
                marginRight={!isMobile ? inputMarginRight : 0}
                borderRadius={8}
                paddingLeft={16}
                error={errors.email}
              />

              <Input
                id="hearFrom"
                name="hearFrom"
                title="How did you hear about us? (optional)"
                placeholder="Select option"
                value={hearFrom}
                onChange={handleChange}
                width={isMobile ? '100%' : inputWidth}
                borderRadius={8}
                paddingLeft={16}
              />
            </InputsWrapper>

            <Textarea
              id="message"
              name="message"
              title="Message"
              value={message}
              onChange={handleChange}
              placeholder="Your message"
              borderRadius={8}
              paddingLeft={16}
              error={errors.message}
            />

            <CheckboxBlock>
              <Title text="Would you like to be on our lists?" fontSize={15} margin="0 0 16px" />

              <FormCheckbox
                id="industrialAuctionList"
                name="industrialAuctionList"
                isChecked={industrialAuctionList}
                handleChange={handleChange}
                text="Yes, sign me up for the weekly"
                bolderText="Industrial auction list"
              />
              <FormCheckbox
                id="salvageAuctionList"
                name="salvageAuctionList"
                isChecked={salvageAuctionList}
                handleChange={handleChange}
                text="Yes, sign me up for the weekly"
                bolderText="Salvage auction list"
              />
              <FormCheckbox
                id="usedCarAuctionList"
                name="usedCarAuctionList"
                isChecked={usedCarAuctionList}
                handleChange={handleChange}
                text="Yes, sign me up for the"
                bolderText="Used Car auction list"
              />
              <FormCheckbox
                id="usedMotorAuctionList"
                name="usedMotorAuctionList"
                isChecked={usedMotorAuctionList}
                handleChange={handleChange}
                text="Yes, sign me up for the"
                bolderText="Used Motorcycle auction list"
              />
            </CheckboxBlock>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Button type="submit" text={t('buttons:send_message')} height="52" />
            </div>
          </Form>
        </ContactTextWrapper>
      </Container>
      {isLoading && <LoadingPortal text="Submitting" />}
    </>
  );
};
