import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { appActions, dashboardActions, itemActions } from '@pickles/shared/redux/actions';
import { DashboardHooks, ItemHooks, LoaderHooks } from '../../app/redux/hooks';
import ItemsListing from '../AuctionDetails/ItemsListing';
import ItemFilters from '../Shared/ItemFilterHolder/ItemFilters';
import Loading from '../Shared/Loading';
import MetaTags from '../Metatags';

import {
  AuctionDetailItemsWrapper,
  LoadingWrapper,
  ResultCountWrapper,
  ResultLineWrapper,
  ResultText,
  SearchCarNameText,
  SearchContentWrapper,
} from './style';

export const SearchContent = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const queryObj = router.query;
  const searchedTerm = (queryObj.search as string) || '';

  const filterKey = ['year', 'make', 'model', 'condition', 'category'];
  const filterQueryObj = Object.keys(queryObj)
    .filter((key) => filterKey.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: typeof queryObj[key] === 'string' ? [queryObj[key]] : queryObj[key],
      };
    }, {});

  const searchResults = ItemHooks.useSearchResult();
  const searchResultCount = DashboardHooks.useSearchTotal();
  const searchFilter = DashboardHooks.useSearchFilter();
  const isLoading = LoaderHooks.useLoadingIndicator('searchResults');
  const page = DashboardHooks.useSearchPage();

  const shouldLoadMore = useMemo(() => {
    return !!page && !!searchResultCount && searchResultCount > searchResults.length && !isLoading;
  }, [page, searchResultCount, searchResults, isLoading]);

  useEffect(() => {
    dispatch(appActions.getMobileAppConfig());
  }, []);

  useEffect(() => {
    dispatch(
      dashboardActions.setSelectedSearchFilter({
        page: 1,
        search: searchedTerm,
        ...filterQueryObj,
      }),
    );
  }, [dispatch, searchedTerm]);

  const filters = {
    year: searchFilter.selected.year,
    make: searchFilter.selected.make,
    model: searchFilter.selected.model,
    condition: searchFilter.selected.condition,
    category: searchFilter.selected.category,
  };

  const onApplyFilter = (
    filters: string[],
    filterType: 'year' | 'make' | 'model' | 'location' | 'condition' | 'category',
  ) => {
    dispatch(
      dashboardActions.setSelectedSearchFilter({
        page: 1,
        search: searchedTerm,
        year: filterType === 'year' ? filters : searchFilter?.selected.year,
        make: filterType === 'make' ? filters : searchFilter?.selected.make,
        model: filterType === 'model' ? filters : searchFilter?.selected.model,
        condition: filterType === 'condition' ? filters : searchFilter?.selected.condition,
        category: filterType === 'category' ? filters : searchFilter?.selected.category,
      }),
    );
  };

  return (
    <SearchContentWrapper>
      <MetaTags
        title={searchedTerm}
        description={`${_.capitalize(searchedTerm)} for sale in Malaysia.`}
      />
      <SearchCarNameText id={'searched-car-name'}>{searchedTerm}</SearchCarNameText>
      <ResultLineWrapper>
        <ResultCountWrapper>
          <ResultText id={'search-results-length'}>Results ({searchResultCount})</ResultText>
        </ResultCountWrapper>
      </ResultLineWrapper>
      <ItemFilters filters={searchFilter} onApplyFilter={onApplyFilter} />
      <AuctionDetailItemsWrapper id="items">
        <InfiniteScroll
          dataLength={searchResults.length}
          next={() => {
            dispatch(
              itemActions.getSearchResults({
                page: page + 1,
                search: searchedTerm,
                ...filters,
              }),
            );
          }}
          hasMore={shouldLoadMore}
          loader={
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          }
          style={{ overflowY: 'hidden' }}
        >
          {searchResults.length ? <ItemsListing items={searchResults} /> : null}
        </InfiniteScroll>
      </AuctionDetailItemsWrapper>
    </SearchContentWrapper>
  );
};
