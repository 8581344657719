import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RefundContainer, RefundTitle, RefundDescription, RefundOkButton } from './styles';
import { BaseModal } from '../Shared/Modal';

interface IRefundModalProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const RefundModal: FC<IRefundModalProps> = ({ isOpened, setIsOpened }) => {
  const { t } = useTranslation();
  return (
    <BaseModal isOpened={isOpened} onClickOutside={() => setIsOpened(false)}>
      <RefundContainer>
        <RefundTitle>{t('transactions:refund')}</RefundTitle>
        <RefundDescription>{t('infos:refund')}</RefundDescription>
        <RefundOkButton onClick={() => setIsOpened(false)}>{t('buttons:continue')}</RefundOkButton>
      </RefundContainer>
    </BaseModal>
  );
};
