import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';
import background from '../../../public/images/background.png';

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  max-width: ${sizes.XL};
  width: 100%;
  height: 600px;
  background-image: url(${background.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 88px;

  @media (max-width: ${sizes.S}) {
    margin-bottom: 3em;
    margin-top: 0;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 30px;
`;

export const UsefulLinksHeader = styled.div`
  max-width: ${sizes.XL};
  color: ${colors.rhino_black};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 32px;
  text-align: center;
`;

export const UsefulLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 90%;

  @media (max-width: ${sizes.L}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 1fr;
  }
`;

export const UsefulLink = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  border: 1px solid #D6DADF;
  border-radius: 11px;
  padding: 30px 0;
`;

export const IconWrapper = styled.div`
  margin-bottom: 26px;
  width: 42px;
  height: 42px;
`;

export const LinkWrapper = styled.div`
  display: flex;

  > svg {
    margin-top: 5px;
  }
`;  

export const LinkTitle = styled.p`
  color: ${colors.san_juan_grey};
  font-family: BasierCircle;
  font-size: 16px;
  line-height: 1.5em;
  margin-right: 6px;
`;
