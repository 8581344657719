import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MobileHeaderLine from '../Shared/MobileHeaderLine';
import { UserHooks } from '../../app/redux/hooks';
import TransactionHistoryItem from './TransactionHistoryItem';

import {
  TransactionsContainer,
  TransactionsGridHeader,
  EmptyTransactionHistory,
} from './Transactions.style';

interface ITransactionHistory {
  closeScreen?: () => void;
  isMobile?: boolean;
}

export const TransactionsHistory: FC<ITransactionHistory> = ({ isMobile = false, closeScreen }) => {
  const { t } = useTranslation();
  const transactions = UserHooks.useUserTransactions();

  const transactionsRow = transactions.length ? transactions.map((transaction, index) => (
    <TransactionHistoryItem key={index} index={index} transaction={transaction} />
  )) : null;

  return (
    <>
      {isMobile && closeScreen && (
        <MobileHeaderLine
          hasShareBtn={false}
          onBackBtnClick={closeScreen}
          title={t('labels:transaction_history_lowercase')}
        />
      )}
      <TransactionsContainer>
        <TransactionsGridHeader>
          <div>{t('labels:amount_caps')}</div>
          <div>{t('labels:type')}</div>
          <div>{t('labels:actions')}</div>
        </TransactionsGridHeader>
        {transactions.length ? (
          <div>{transactionsRow}</div>
        ) : (
          <EmptyTransactionHistory>{t('labels:no_transactions')}</EmptyTransactionHistory>
        )}
        <EmptyTransactionHistory>{t('transactions:view_older')}</EmptyTransactionHistory>
      </TransactionsContainer>
    </>
  );
};
