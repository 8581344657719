import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { itemActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import { HeartIcon } from '../Shared/Icons';
import { ItemHooks } from '../../app/redux/hooks';

import styled from 'styled-components';
import { BidComponentWrapper, BiddingContainer } from './BidComponent.style';
import sizes from '../../util/sizes';

interface AddToWatchlistProps {
  itemId: number;
}

const AddToWatchListComponent: FC<AddToWatchlistProps> = ({ itemId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFavorite = ItemHooks.useItemFavorite(itemId);

  const handleOnClickAddToWatchList = () => {
    dispatch(itemActions.setFavorite(itemId, !isFavorite));
  };

  return (
    <BiddingContainer>
      <BidComponentWrapper>
        <AddToWatchListWrapper id="add-to-watchlist-wrapper">
          <AddToWatchListText>{t('infos:on_hold')}</AddToWatchListText>
          <AddToWatchListBtn onClick={handleOnClickAddToWatchList}>
            {isFavorite ? (
              <>
                <HeartIcon fillColor="white" strokeColor="white" />
                {t('buttons:remove_watchlist')}
              </>
            ) : (
              <>
                <HeartIcon strokeColor="white" />
                {t('buttons:add_watchlist')}
              </>
            )}
          </AddToWatchListBtn>
        </AddToWatchListWrapper>
      </BidComponentWrapper>
    </BiddingContainer>
  );
};

export default AddToWatchListComponent;

const AddToWatchListWrapper = styled.div`
  padding: 24px;
  background: linear-gradient(180deg, ${colors.blumine_main_blue} 0%, ${colors.blue_zodiak} 100%);
  width: 100%;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
  }
`;

const AddToWatchListText = styled.div`
  line-height: 1.4;
  font-family: BasierCircle;
  font-size: 14px;
  color: ${colors.white};
  margin-bottom: 20px;
`;

const AddToWatchListBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${colors.cerulean_blue};
  border-radius: 8px;
  font-family: BasierCircle-Semibold;
  font-size: 16px;
  height: 44px;
  cursor: pointer;
  text-indent: 7.5px;
`;
