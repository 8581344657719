import React, { FC, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

type PropsType = {
  containerId: string;
  children?: React.ReactChild[] | React.ReactChildren;
};

export const PortalComponent: FC<PropsType> = ({ containerId, children }) => {
  const el = useMemo(() => (process.browser ? document.createElement('div') : null), []);
  const rootContainer = useMemo(() => {
    if (process.browser) {
      return document.getElementById(containerId) || document.getElementsByClassName(containerId)[0]
    }
    return null;
  }, [containerId]);

  useEffect(() => {
    if (rootContainer && el) {
      rootContainer && rootContainer.appendChild(el);

      return () => {
        rootContainer && rootContainer.removeChild(el);
      };
    }
  }, [rootContainer, el]);

  return el ? ReactDOM.createPortal(children, el) : null;
};
