import { IItem } from '@pickles/shared/models';
import { ebidActions, itemActions } from '@pickles/shared/redux/actions';
import { BidButtonType } from '@pickles/shared/utils/types';
import { useRouter } from 'next/router';
import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  DashboardHooks,
  EbidAuctionHooks,
  ItemHooks,
  LiveAuctionHooks,
  LoaderHooks,
  UserHooks,
} from '../../app/redux/hooks';
import { BidComponentBtn, BidComponentBtnComment } from './BidComponent.style';
import Bidding from './Bidding';

interface SimplifiedEbidItemProps {
  item: IItem.Item;
}

const SimplifiedEbidItem: FC<SimplifiedEbidItemProps> = ({ item }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = UserHooks.useUserAccount();
  const calculator = EbidAuctionHooks.useCalculator();
  const isLoading = LoaderHooks.useLoadingIndicator(`bid_${item.id}`);
  const [isBiddingEnabled, setIsBiddingEnabled] = useState(false);

  const {
    bidAmountLabel,
    bidButton,
    displayBidAmount,
    isAuctionEnded,
    isBalanceSufficient,
    isSocketConnected,
    isWinning,
    maxBidInput,
    nextBidAmount,
  } = ItemHooks.useComputedValues(item.id, item.saleEvent?.id);

  const onBidButtonPress = () => {
    if (account) {
      dispatch(
        ebidActions.setAutobid({
          itemId: item.id,
          userId: account.id,
          bidAmount: displayBidAmount,
          eventId: item.saleEvent?.id,
        }),
      );
    }
  };

  const renderBiddingButton = () => {
    const disabled = !(isBiddingEnabled && !isLoading && isSocketConnected);

    switch (bidButton) {
      case BidButtonType.Winning:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('labels:bidding_highest_bidder').toUpperCase()}
          </BidComponentBtn>
        );
      case BidButtonType.Outbid:
        return (
          <BidComponentBtn id="bid-btn" variant="hint">
            {t('labels:bidding_outbid').toUpperCase()}
          </BidComponentBtn>
        );
      case BidButtonType.SendingBid:
        return (
          <BidComponentBtn id="bid-btn" variant="disabled">
            {t('labels:sending_bid').toUpperCase()}
          </BidComponentBtn>
        );
      case BidButtonType.UpdateMaxBid:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={onBidButtonPress}
          >
            {disabled ? t('labels:bidding_disabled') : t('buttons:update_max_bid')}
          </BidComponentBtn>
        );
      case BidButtonType.SetMaxBid:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={onBidButtonPress}
          >
            {disabled ? t('labels:bidding_disabled') : t('buttons:set_max_bid')}
          </BidComponentBtn>
        );
      case BidButtonType.BidNow:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={onBidButtonPress}
          >
            {disabled ? t('labels:bidding_disabled') : t('buttons:bid_now')}
          </BidComponentBtn>
        );
      case BidButtonType.PendingResult:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="hint"
          >
            {t('labels:auction_ended')}
          </BidComponentBtn>
        );
      case BidButtonType.Offer:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="hint"
          >
            {t('labels:bidding_bid_won')}
          </BidComponentBtn>
        );
      case BidButtonType.YouWin:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="hint"
          >
            {t('labels:bidding_bid_won')}
          </BidComponentBtn>
        );
      case BidButtonType.Lost:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="hint"
          >
            {t('labels:auction_ended')}
          </BidComponentBtn>
        );
      case BidButtonType.Sold:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="hint"
          >
            {t('labels:auction_ended')}
          </BidComponentBtn>
        );
      case BidButtonType.TopUp:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={disabled ? 'disabled' : 'normal'}
            onClick={() => router.push('/profile')}
          >
            <BidComponentBtnComment>{t('labels:insufficient_balance')}</BidComponentBtnComment>
            {t('buttons:top_up')}
          </BidComponentBtn>
        );
      case BidButtonType.CheckAuctions:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant="normal"
            onClick={() => router.push('/')}
          >
            {t('labels:bidding_view_auctions')}
          </BidComponentBtn>
        );
      default:
        return (
          <BidComponentBtn id="bid-btn" variant={'disabled'}>
            unknown
          </BidComponentBtn>
        );
    }
  };

  const canPressMinus = () => {
    const newValue = calculator?.decrease(displayBidAmount);
    return newValue && maxBidInput && newValue >= maxBidInput && newValue >= nextBidAmount;
  };

  const disablePlusButton =
    !isBiddingEnabled ||
    isLoading ||
    !isSocketConnected ||
    isAuctionEnded ||
    (!isBalanceSufficient && !isWinning);

  const disableMinusButton =
    !isBiddingEnabled ||
    isLoading ||
    !isSocketConnected ||
    !canPressMinus() ||
    !isBalanceSufficient;

  const disableToggleButton = !(isSocketConnected && !isAuctionEnded);

  const disableCustomBid =
    isAuctionEnded ||
    !isBiddingEnabled ||
    !isSocketConnected ||
    (!isBalanceSufficient && !isWinning);

  const biddingSwitchText: string =
    (isAuctionEnded && t('labels:auction_ended')) ||
    (!isSocketConnected && t('socket:ebid:disconnect')) ||
    (isBiddingEnabled && t('labels:bidding_enabled')) ||
    t('labels:bidding_disabled');

  const onPlusPress = () => {
    if (calculator) {
      dispatch(itemActions.setBidAmount(item.id, calculator.increase(displayBidAmount)));
    }
  };

  const onMinusPress = () => {
    if (canPressMinus() && calculator) {
      dispatch(itemActions.setBidAmount(item.id, calculator.decrease(displayBidAmount)));
    }
  };

  return (
    <Bidding
      allowDecrease={!disableMinusButton}
      allowIncrease={!disablePlusButton}
      bidAmount={displayBidAmount}
      bidAmountLabel={t(bidAmountLabel)}
      biddingButton={renderBiddingButton()}
      biddingEnabledHandler={() => setIsBiddingEnabled((value) => !value)}
      disableCustomBid={disableCustomBid}
      disableToggleButton={disableToggleButton}
      isBiddingEnabled={isBiddingEnabled}
      item={item}
      onClickDecrease={onMinusPress}
      onClickIncrease={onPlusPress}
      switchLabel={`${biddingSwitchText}`}
    />
  );
};

export default SimplifiedEbidItem;
