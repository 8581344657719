import { useRouter } from 'next/router';
import Image from 'next/image';
import React, { useState } from 'react';

import {
  CloseBtnWrapper,
  ComponentWrapper,
  ContentBlock,
  HeaderCloseBtn,
  ImgBg,
  Step,
  StepWrapper,
  Wrapper,
  WrapperHeader,
  LogoWrapper,
  AuthBackButtonWrapper,
} from './style';
import picklesIcon from '/public/logo/picklesLogo.svg';
import closeBtnIcon from '/public/logo/closeBtnIcon.svg';
import { BackButton } from '../Button/BackButton';

interface ILoginWrapper {
  children?: React.ReactElement | React.ReactElement[];
}

export const LoginSignupWrapper = ({ children }: ILoginWrapper) => {
  const router = useRouter();

  const [step, setStep] = useState<number>(0);
  const [isSignup, setIsSignup] = useState(false);

  const handleClose = () => {
    router.push('/');
  };

  return (
    <Wrapper>
      <ImgBg />
      <ContentBlock style={{ position: 'relative' }}>
        <WrapperHeader>
          <AuthBackButtonWrapper>
            <BackButton isHiddenText />
          </AuthBackButtonWrapper>
          <LogoWrapper>
            <Image src={picklesIcon} alt="pickles" />
          </LogoWrapper>
          {step < 4 && (
            <CloseBtnWrapper onClick={handleClose}>
              <Image src={closeBtnIcon} alt="cross" />
              <HeaderCloseBtn>Close</HeaderCloseBtn>
            </CloseBtnWrapper>
          )}
        </WrapperHeader>
        <ComponentWrapper>
          {React.Children.map(children, (child) => {
            if (child) {
              return React.cloneElement(child, {
                step,
                setStep,
                setIsSignup,
              });
            }
          })}
        </ComponentWrapper>
        {isSignup && (
          <StepWrapper>
            {step < 4 &&
              Array.from(Array(4).keys()).map((item) => {
                return <Step key={item} isActive={item === step} />;
              })}
          </StepWrapper>
        )}
      </ContentBlock>
    </Wrapper>
  );
};
