import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import fonts from '@pickles/shared/utils/fonts';

interface SupportFooterProps {
  onContactPress: () => void;
}

const SupportFooter: FC<SupportFooterProps> = ({ onContactPress }) => {
  const { t } = useTranslation();
  return (
    <FooterStyles>
      <SupportTitle>{'Questions about bidding?'}</SupportTitle>
      <ContactUsButton onClick={onContactPress}>{t('buttons:contact_us_now')}</ContactUsButton>
      <TermsText>{t('labels:live_auction_terms')}</TermsText>
    </FooterStyles>
  );
};

export default SupportFooter;

const FooterStyles = styled.div`
  color: ${colors.rhino_black};
  text-align: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

const SupportTitle = styled.p`
  padding: 6px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: none;
  font-weight: 600px;
  font-family: ${fonts.BC_BOLD_FONT};
`;

const ContactUsButton = styled.div`
  display: inline;
  align-self: center;
  margin: 3px 0px 3px 0px;
  padding: 10px 20px;
  background-color: ${colors.primary};
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  line-height: 50px;
  font-family: ${fonts.BC_MEDIUM_FONT};
`;

const TermsText = styled.p`
  color: ${colors.rhino_black};
  text-align: center;
  margin-top: 4px;
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 18px;
  font-family: ${fonts.BC_REGULAR_FONT};
`;
