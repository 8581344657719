import React from 'react';
import { Datepicker } from './Datepicker';
import { Select } from './Select';
import { FilterArrowDown, FilterHeader, FilterSelectContainer } from './styles';

export enum FilterTypes {
  Select = 'select',
  Date = 'date',
  Image = 'image',
}

type PropsType = {
  options: string[];
  selectedIndex?: number;
  placeholder: string;
  type: FilterTypes;
};

export const FilterType = (props: PropsType) => {
  const { placeholder, type, selectedIndex, options } = props;

  const renderFilter = (type: FilterTypes) => {
    switch (type) {
      case FilterTypes.Date:
        return <Datepicker {...props} />;
      case FilterTypes.Select:
      case FilterTypes.Image:
        return <Select {...props} />;
      default:
        return <Select {...props} />;
    }
  };

  return renderFilter(type);
};
