import React, { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { imageUrlWithResizer } from '@pickles/shared/utils/helpers';
import CarDetailSlider from '../CarDetailSlider/index';
import {
  BackArrowImgWrapper,
  BackBtnWrapper,
  CarDetailGallery,
  CloseBtnImgWrapper,
  CloseBtnLine,
  GalleryWrapper,
  ImgGalleryWrapper,
  ImgWrapper,
  MobileImagesWrapper,
  MobileImgWrapper,
  TopWrapper,
} from './style';
// import 'react-image-gallery/styles/css/image-gallery.css';

import whiteCloseBtn from '/public/icons/whiteCloseBtn.svg';
import backPageArrow from '/public/icons/backArrow.svg';
import mobileArrowBackBtn from '/public/icons/mobileArrowBackBtn.svg';

interface IImageGallery {
  images: string[];
  backBtnHandler: () => void;
  isMobile: boolean;
  width?: string;
  isImgGalleryOpen?: boolean;
  setSelectedImg: (num: number | null) => void;
  selectedImg: number | null;
  useResizer?: boolean;
  currentSlideIndex: number;
}

const ImgGallery: FC<IImageGallery> = ({
  images,
  backBtnHandler,
  isMobile,
  width,
  isImgGalleryOpen = false,
  setSelectedImg,
  selectedImg,
  useResizer = true,
  currentSlideIndex,
}) => {
  const [imgArray, setImgArray] = useState<ReactImageGalleryItem[]>([]);
  {
    isMobile ? (width = width) : (width = '750px');
  }

  useEffect(() => {
    const img: ReactImageGalleryItem[] = images?.map((image) => {
      return {
        original: useResizer ? imageUrlWithResizer(image, 750) : image,
        thumbnail: useResizer ? imageUrlWithResizer(image, 94) : image,
      };
    });
    setImgArray(img);
  }, [images]);

  const hideImagesSliderHandler = () => {
    setSelectedImg(null);
  };

  if (isMobile && typeof selectedImg === 'number') {
    return (
      <MobileImagesWrapper>
        <TopWrapper>
          <div onClick={hideImagesSliderHandler}>
            <Image src={mobileArrowBackBtn} alt={'mobileArrowBackBtn'} />
          </div>
        </TopWrapper>
        <MobileImgWrapper>
          <CarDetailSlider
            images={images}
            width={width}
            isImgGalleryOpen={isImgGalleryOpen}
            setSelectedImg={setSelectedImg}
            selectedImg={selectedImg}
            currentSlideIndex={currentSlideIndex}
          />
        </MobileImgWrapper>
      </MobileImagesWrapper>
    );
  }

  if (typeof selectedImg === 'number') {
    const currentImage = imgArray[selectedImg];

    return (
      <CarDetailGallery>
        <CloseBtnLine>
          <CloseBtnImgWrapper
            onClick={() => {
              setSelectedImg(null);
              backBtnHandler();
            }}
          >
            <Image src={whiteCloseBtn} alt="close" />
          </CloseBtnImgWrapper>
        </CloseBtnLine>
        <CarDetailSlider
          images={images}
          width={width}
          isImgGalleryOpen={isImgGalleryOpen}
          setSelectedImg={setSelectedImg}
          selectedImg={selectedImg}
          currentSlideIndex={currentSlideIndex}
        />
      </CarDetailGallery>
    );
  }

  return (
    <ImgGalleryWrapper>
      <BackBtnWrapper onClick={backBtnHandler}>
        <BackArrowImgWrapper>
          <Image src={backPageArrow} alt="backArrow" />
        </BackArrowImgWrapper>
        Back
      </BackBtnWrapper>
      <GalleryWrapper>
        {imgArray.map((img, index) => {
          return (
            <ImgWrapper
              isBigImg={index === 0 || index % 3 === 0}
              key={index}
              onClick={() => {
                setSelectedImg(index);
              }}
            >
              <Image
                src={img.original}
                width={index === 0 || index % 3 === 0 ? '599' : '279'}
                height={index === 0 || index % 3 === 0 ? '420' : '185'}
                alt={'Full Size Image'}
                // layout="fill"
                // objectFit="contain"
                // width="100%" height="100%" layout="responsive" objectFit="contain"
              />
              {/* <img src={img} alt={img} width={'100%'} /> */}
            </ImgWrapper>
          );
        })}
      </GalleryWrapper>
    </ImgGalleryWrapper>
  );
};

export default ImgGallery;
