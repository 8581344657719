import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config';
import Config from '@pickles/shared/config/env';
import { Auction } from '@pickles/shared/models/auction.types';
import { isAuctionRunning } from '@pickles/shared/models/helpers';
import { appActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import { imageUrlWithResizer } from '@pickles/shared/utils/helpers';
import { getAuctionRefByAuction } from '@pickles/shared/utils/routes';

import { AppHooks, EbidAuctionHooks, UserHooks } from '../../app/redux/hooks';
import LiveAuctionModal from '../LiveAuctionModal';
import { computerIcon, likeIcon, locationPinIcon, placeholderIcon } from '../Shared/Icons';

import {
  AttendButton,
  AuctionCardStyle,
  AuctionGridActionsWrapper,
  AuctionType,
  AuctionTypeContentWrapper,
  BottomSection,
  ButtonsWrapper,
  CarImageWrapper,
  ColoredSpan,
  DateDisplay,
  DateTimeWrapper,
  Description,
  Divider,
  IconImg,
  IconsWrapper,
  IconWrapper,
  ImgWrapper,
  LiveMark,
  LocationText,
  LocationWrapper,
  PinIcon,
  SmallTextWrapper,
  TimeDisplay,
  Title,
  TopSection,
  UnitCount,
  ViewButton,
  WinningAndOutbidsWrapper,
} from './style';

interface IAuctionGridComponent {
  auction: Auction;
}

const AuctionCard = ({ auction }: IAuctionGridComponent) => {
  const router = useRouter();
  const now = AppHooks.useApplicationTimer();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLiveModalVisible, setIsLiveModalVisible] = useState(false);

  const account = UserHooks.useUserAccount();
  const ebidCounts = EbidAuctionHooks.useCountsByEvent(auction.id);
  const auctionRef = getAuctionRefByAuction(auction);
  const isRunning = isAuctionRunning(
    auction.eventType,
    auction.isLiveStarted,
    auction.startAt,
    auction.endAt,
    now,
  ); // TODO: We should look into fixing item.bidding.isRunning

  const showLiveComingSoonModal = () => {
    !isLiveModalVisible && setIsLiveModalVisible(true);
  };

  const handleAttendButtonPress = () => {
    if (Config.canAttendLiveAuction()) {
      if (account) {
        if (account?.status === 'PENDING') {
          dispatch(
            appActions.showAlert({
              alertType: 'alert',
              title: 'Pickles',
              message: t('infos:your_account_has_not'),
            }),
          );
          return;
        }
        router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}/live`);
        return;
      }
      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('infos:not_logged'),
        }),
      );
    } else {
      showLiveComingSoonModal();
    }
  };

  const handleOnClickAttendOrBidNow = () => {
    if (auction.isLive) {
      handleAttendButtonPress();
    } else {
      router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}`);
    }
  };

  return (
    <AuctionCardStyle isRunning={isRunning} id={'auctions-item-component'}>
      <TopSection>
        <CarImageWrapper>
          <ImgWrapper>
            <Image
              width="100%"
              height="100%"
              objectFit="cover"
              src={auction.heroImage ? imageUrlWithResizer(auction.heroImage) : placeholderIcon}
              alt=""
            />
          </ImgWrapper>
          <DateTimeWrapper>
            <DateDisplay id="auction_date">
              {format(new Date(auction.startAt), 'MMM dd')}
            </DateDisplay>
            <TimeDisplay id="auction_time">{format(new Date(auction.startAt), 'p')}</TimeDisplay>
          </DateTimeWrapper>
        </CarImageWrapper>
        <div style={{ width: '100%' }}>
          <Title id="auction_name">{auction.name}</Title>
          {Config.isDev && <span>saleEventId={auction.id}</span>}
          <Description id="auction_desc">{auction.description}</Description>
          <AuctionTypeContentWrapper>
            <AuctionType id="auction_type">
              {auction.isLive ? t('status:live_auction') : t('status:e_bidding')}
            </AuctionType>
            <UnitCount id="auction_units_count">{`${auction.itemsCt || 0} ${t(
              'labels:units',
            )}`}</UnitCount>
          </AuctionTypeContentWrapper>
          {auction.city && (
            <LocationWrapper>
              <PinIcon>
                <Image
                  width="100%"
                  height="100%"
                  objectFit="fill"
                  src={locationPinIcon}
                  alt="pin"
                />
              </PinIcon>
              <LocationText id="auction_location">{auction.city}</LocationText>
            </LocationWrapper>
          )}
        </div>
      </TopSection>
      <Divider />
      <BottomSection>
        <AuctionGridActionsWrapper>
          <IconsWrapper>
            <IconWrapper>
              <IconImg>
                <Image src={likeIcon} alt="like" />
              </IconImg>{' '}
              <span
                id="auction_fav_count"
                style={{
                  fontFamily: 'BasierCircle',
                  fontSize: '15px',
                  color: colors.san_juan_grey,
                }}
              >
                {auction.favsCt || 0}
              </span>
            </IconWrapper>
            {auction.isLive && (
              <IconWrapper isComputerIcon={true}>
                <IconImg>
                  <Image src={computerIcon} alt="comp" />
                </IconImg>{' '}
                <span id="auction_maxbid_count">{auction.prebidsCt || 0}</span>
              </IconWrapper>
            )}
            {auction.isEbid && isRunning && ebidCounts && (
              <WinningAndOutbidsWrapper>
                <SmallTextWrapper>
                  {`${t('status:winning_lowercase')} `}
                  <ColoredSpan id="auction_win_count" color={colors.christi_green}>
                    {ebidCounts.winning}
                  </ColoredSpan>
                </SmallTextWrapper>
                <SmallTextWrapper>
                  {`${t('status:outbid_lowercase')} `}
                  <ColoredSpan id="auction_outbid_count" color={colors.cerise_red}>
                    {ebidCounts.outbid}
                  </ColoredSpan>
                </SmallTextWrapper>
              </WinningAndOutbidsWrapper>
            )}
          </IconsWrapper>
          <ButtonsWrapper>
            <Link passHref={true} href={`/auctions/${auctionRef}`}>
              <ViewButton id="auction_view">{t('buttons:view')}</ViewButton>
            </Link>
            {isRunning && (
              <AttendButton
                id={auction.isLive ? 'auction_attend' : 'auction_bidnow'}
                onClick={handleOnClickAttendOrBidNow}
              >
                {auction.isLive ? `+ ${t('buttons:attend')}` : t('buttons:bid_now')}
              </AttendButton>
            )}
          </ButtonsWrapper>
        </AuctionGridActionsWrapper>
      </BottomSection>
      {isRunning && <LiveMark id="auction_status">{t('labels:LIVE')}</LiveMark>}
      {<LiveAuctionModal isOpened={isLiveModalVisible} setIsOpened={setIsLiveModalVisible} />}
    </AuctionCardStyle>
  );
};

export default AuctionCard;
