import styled from 'styled-components';
import sizes from '../../../../util/sizes';

interface IImgWrapper {
  isBigImg: boolean;
}

export const ImgGalleryWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 100;
`;

export const BackBtnWrapper = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 16px;
  color: #1c4d8e;
  margin-left: 19.5px;
  margin-top: 23px;
  cursor: pointer;
  display: flex;
  /* flex-grow: 0; */
  width: fit-content;
  align-items: flex-start;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 15px;
  }
`;

export const BackArrowImgWrapper = styled.div`
  margin-right: 12.3px;
`;

export const GalleryWrapper = styled.div`
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${sizes.M}) {
    width: 100%;
  }
`;

export const ImgWrapper = styled.div<IImgWrapper>`
  width: ${({ isBigImg }) => (isBigImg ? '599px' : '279px')};
  height: ${({ isBigImg }) => (isBigImg ? '420px' : '185px')};
  margin-bottom: 19px;
  cursor: pointer;

  @media (max-width: ${sizes.M}) {
    width: ${({ isBigImg }) => (isBigImg ? '100%' : '45%')};
    height: auto;
  }
`;

export const CarDetailGallery = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 100;
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;

  div {
    margin-right: 0.5em;
    cursor: pointer;
    opacity: 0.7;

    &.active {
      opacity: 1;
    }
  }
`;

export const CloseBtnLine = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const CloseBtnImgWrapper = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

export const MobileImagesWrapper = styled.div`
  position: relative;
  background: #000000;
  height: 100vh;
`;

export const MobileImgWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const TopWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: fit-content;
  justify-content: space-between ;
  text-align: center;
  padding: 23px 20px 0 25px;
  align-items: center;
`;
