import { IItem } from '@pickles/shared/models';
import colors from '@pickles/shared/utils/colors';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import WaitingSection from '../LiveAuctionContent/WaitingSection';
import Container from '../Shared/Container';
import {
  AuctionsRemarkIcon,
  DocumentsIcon,
  InspectionRemarksIcon,
  IntakeIcon,
  ItemDetailsIcon,
  SpecificationsIcon,
} from '../Shared/Icons';
import CarDescriptionRow from './components/CarDescriptionRow/CarDescriptionRow';
import CarDetailSlider from './components/CarDetailSlider';
import ShortDetail from './components/ShortDetail';
import ViewFilesComponent from './components/ViewFilesComponent/ViewFilesComponent';
import {
  DescriptionColumn,
  DescriptionContent,
  DescriptionContentItem,
  DescriptionContentTable,
  DescriptionContentTitle,
  DescriptionContentTitleImgWrapper,
  DescriptionContentWrapper,
  DescriptionWrapper,
  Divider,
  IconsWrapper,
  MainBlock,
  RemarkText,
  RemarkTitle,
  RemarkWrapper,
} from './style';

interface IMainContentBlock {
  item: IItem.Item | undefined;
  // carId: string
  isMobile?: boolean;
  imgGalleryOpenHandler: () => void;
  setLink?: (link: string) => void;
  setIsViewerOpen?: (bool: boolean) => void;
  openBidHistory?: () => void;
  isLiveAuctionWaiting?: boolean;
  setSelectedImg: (num: number | null) => void;
  selectedImg: number | null;
}
const defaultImage = '/images/defaultImage.png';

const MainContentBlock: FC<IMainContentBlock> = ({
  item,
  imgGalleryOpenHandler,
  setLink,
  setIsViewerOpen,
  openBidHistory,
  isLiveAuctionWaiting,
  setSelectedImg,
  selectedImg,
}) => {
  const account = UserHooks.useUserAccount();
  const { t } = useTranslation();
  const { isTablet } = useScreenSize();
  const { isMobile } = useScreenSize();

  const setPdfLink = (link: string) => {
    if (setLink && setIsViewerOpen) {
      setLink(link);
      setIsViewerOpen(true);
    }
  };

  if (!item) {
    return null;
  }

  const stockLot = `${item.stockNum}/${item.saleEvent?.lotNum}`;

  if (isLiveAuctionWaiting) {
    return (
      <MainBlock id="main-content-block">
        <WaitingSection />
      </MainBlock>
    );
  }

  const IntakeOriginContent = () => {
    return (
      <>
        <DescriptionContentTitle>
          <DescriptionContentTitleImgWrapper>
            <IntakeIcon />
          </DescriptionContentTitleImgWrapper>{' '}
          {t('labels:intake_origin')}
        </DescriptionContentTitle>
        <DescriptionContentTable>
          <tbody>
            <CarDescriptionRow
              label={t('labels:source_type')}
              value={item.sourceType || 'N/A'}
            />
            <CarDescriptionRow
              label={t('labels:condition_type')}
              value={item.conditionType || 'N/A'}
            />
            <CarDescriptionRow
              label={t('labels:incident_type')}
              value={item.vehicle?.crIncidentType || 'N/A'}
            />
          </tbody>
        </DescriptionContentTable>
      </>
    )
  }

  const SpecificationContent = () => {
    return (
      <>
        <DescriptionContentTitle>
          <DescriptionContentTitleImgWrapper>
            <SpecificationsIcon />
          </DescriptionContentTitleImgWrapper>{' '}
          {t('labels:specifications')}
        </DescriptionContentTitle>
        <DescriptionContentTable>
          <tbody>
            <CarDescriptionRow
              label={t('labels:years_of_manufacture')}
              value={item.vehicle?.year || 'N/A'}
            />
            <CarDescriptionRow label={t('labels:make')} value={item.vehicle?.make || 'N/A'} />
            <CarDescriptionRow label={t('labels:model')} value={item.vehicle?.model || 'N/A'} />
            <CarDescriptionRow
              label={t('labels:variant')}
              value={item.vehicle?.trim || 'N/A'}
            />
          </tbody>
        </DescriptionContentTable>
      </>
    )
  }

  const ItemDetails1Content = () => {
    return (
      <>
        <DescriptionContentTitle>
          <DescriptionContentTitleImgWrapper>
            <ItemDetailsIcon />
          </DescriptionContentTitleImgWrapper>{' '}
          {t('labels:item_details')}
        </DescriptionContentTitle>
        <DescriptionContentTable hasFullDescription={true}>
          <tbody>
            <CarDescriptionRow
              label={t('labels:chassis_number')}
              value={item.vehicle?.vin || 'N/A'}
            />
            <CarDescriptionRow
              label={t('labels:engine_number')}
              value={item.vehicle?.engineRef || 'N/A'}
            />
            <CarDescriptionRow
              label={t('labels:reg_number')}
              value={item.vehicle?.regNo || 'N/A'}
            />
            <CarDescriptionRow label={t('labels:color')} value={item.vehicle?.color || 'N/A'} />
          </tbody>
        </DescriptionContentTable>
      </>
    )
  }

  const ItemDetails2Content = () => {
    return (
      <DescriptionContentTable isWithoutTitle={true}>
        <tbody>
          <CarDescriptionRow label={t('labels:stock_no')} value={stockLot} />
          <CarDescriptionRow
            label={t('labels:yard_location')}
            value={item.yard?.location || 'N/A'}
          />
          <CarDescriptionRow
            label={t('labels:vendor')}
            value={item.consignor?.name || 'N/A'}
          />
          <CarDescriptionRow
            label={t('labels:key')}
            value={item.vehicle?.keys?.toString() || 'N/A'}
          />
        </tbody>
      </DescriptionContentTable>
    )
  }

  return (
    <MainBlock id="main-content-block">
      <CarDetailSlider
        images={item.images?.length ? item.images : [defaultImage]}
        eventType={item.eventType}
        imgGalleryOpenHandler={imgGalleryOpenHandler}
        height={'460px'}
        isMobile={isMobile}
        setSelectedImg={setSelectedImg}
        selectedImg={selectedImg}
      />
      <Container alignItems="stretch" px={isMobile ? '16px' : 0}>
        {isTablet && <ShortDetail account={account} item={item} openBidHistory={openBidHistory} />}
        {
          isMobile
            ? <>
                <DescriptionWrapper marginBottom={24} id="description-wrapper">
                  <DescriptionContentWrapper id="description-intake-origin">
                    <IntakeOriginContent />
                  </DescriptionContentWrapper>
                  <DescriptionContentWrapper id="description-item-details">
                    <ItemDetails1Content />
                    <ItemDetails2Content />
                  </DescriptionContentWrapper>
                  <DescriptionContentWrapper id="description-specifications">
                    <SpecificationContent />
                  </DescriptionContentWrapper>
                </DescriptionWrapper>
              </> 
            : <>
                <DescriptionWrapper marginBottom={24} id="description-wrapper-1">
                  <DescriptionContentWrapper id="description-specifications">
                    <SpecificationContent />
                  </DescriptionContentWrapper>
                  <DescriptionContentWrapper id="description-intake-origin">
                    <IntakeOriginContent />
                  </DescriptionContentWrapper>
                </DescriptionWrapper>
                <Divider className='item-detail-divider'/>
                <DescriptionWrapper marginBottom={24} id="description-wrapper-2">
                  <DescriptionContentWrapper id="description-item-details-1">
                    <ItemDetails1Content />
                  </DescriptionContentWrapper>
                  <DescriptionContentWrapper id="description-item-details-2">
                    <ItemDetails2Content />
                  </DescriptionContentWrapper>
                </DescriptionWrapper>
                <Divider className='item-detail-divider' />
              </>
        }
        {item?.documents && (
          <DescriptionWrapper marginBottom={40}>
            <DescriptionContentWrapper width={'335px'}>
              <DescriptionContentTitle>
                <DescriptionContentTitleImgWrapper>
                  <DocumentsIcon />
                </DescriptionContentTitleImgWrapper>{' '}
                {t('labels:documents')}
              </DescriptionContentTitle>
              <DescriptionContent justifyContent="space-between">
                <DescriptionColumn size={14} color={colors.new_gray}>
                  {item?.documents?.map((document, index) => (
                    <DescriptionContentItem key={index}>{document.name}</DescriptionContentItem>
                  ))}
                </DescriptionColumn>
                <DescriptionColumn size={15} color={colors.rhino_black}>
                  <ViewFilesComponent setPdfLink={setPdfLink} documents={item.documents} />
                </DescriptionColumn>
              </DescriptionContent>
            </DescriptionContentWrapper>
          </DescriptionWrapper>
        )}
        <RemarkWrapper marginBottom={24}>
          <RemarkTitle>
            <IconsWrapper marginRight={8}>
              <AuctionsRemarkIcon />
            </IconsWrapper>
            {t('labels:auction_remarks')}
          </RemarkTitle>
          <RemarkText>{item.auctionRemarks}</RemarkText>
        </RemarkWrapper>
        <RemarkWrapper>
          <RemarkTitle>
            <IconsWrapper marginRight={8}>
              <InspectionRemarksIcon />
            </IconsWrapper>
            {t('labels:inspection_remarks')}
          </RemarkTitle>
          <RemarkText>{item.inspectionRemarks}</RemarkText>
        </RemarkWrapper>
      </Container>
    </MainBlock>
  );
};

export default MainContentBlock;
