import { ChangeEvent, FC } from 'react';
import { FieldError } from '../Input/style';

import { FieldHeading, HeadingWrapper, TextareaField, Wrapper } from './styles';

interface ITextareaProps {
  value?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  width?: string;
  height?: string;
  name?: string;
  title?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  placeholder?: string;
  borderRadius?: number;
  color?: string;
  id?: string;
  error?: string;
  paddingLeft?: number;
  paddingTop?: number;
  required?: boolean;
}

export const Textarea: FC<ITextareaProps> = ({
  id,
  name,
  title,
  error,
  value,
  onChange,
  placeholder,
  paddingLeft,
  paddingTop,
  required,
  ...resProps
}) => (
  <Wrapper {...resProps}>
    <HeadingWrapper>{title && <FieldHeading>{title}</FieldHeading>}</HeadingWrapper>
    <TextareaField
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      error={!!error}
      id={id}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    />
    {error && <FieldError>{error}</FieldError>}
  </Wrapper>
);
