import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

type TransactionsHistoryBlockProps = {
  isActive?: boolean;
};

type TransactionsAssetProps = {
  type?: string;
};

export const TransactionsHistoryBlock = styled.div<TransactionsHistoryBlockProps>`
  padding: 14px;
  padding-right: 60px;
  color: ${({ isActive }) => (isActive ? colors.blumine_main_blue : colors.rhino_black)};
  display: flex;
  border-radius: 8px;
  width: fit-content;
  white-space: nowrap;
  font-family: BasierCircle-Medium;
  background: ${({ isActive }) => (isActive ? colors.zircon_light_blue : 'none')};
  cursor: pointer;
  margin-bottom: 8px;

  align-items: center;
  > span {
    margin-right: 14px !important;
    min-width: 28px;
  }
`;

export const TransactionsHistoryContainer = styled.div`
  display: flex;
  padding-top: 32px;
  > div:nth-child(2) {
    width: 100%;
    margin-left: 64px;
  }
`;

export const TransactionAmount = styled.div`
  padding-top: 12px;
  padding: 20px 0;
  cursor: pointer;
  grid-area: amount;
`;

export const TransactionAssets = styled.div<TransactionsAssetProps>`
  color: ${({ type }) => {
    switch (type) {
      case 'payment':
        return colors.black;
      case 'positive':
        return colors.christi_green;
      case 'negative':
        return colors.cerise_red;
      default:
        break;
    }
  }};
  position: relative;
  font-weight: 400;
  font-size: 18px;
  font-family: BasierCircle-Medium;
  span {
    font-size: 14px;
    font-weight: 400;
    font-family: BasierCircle-Medium;
  }
`;

export const TransactionType = styled.div`
  grid-area: type;
`;

export const TransactionAction = styled.div`
  font-size: 15px;
  text-align: right;
  grid-area: actions;
  cursor: pointer;
`;

export const TransactionsContainer = styled.div<TransactionsAssetProps>`
  @media (max-width: ${sizes.M}) {
    padding: 0 20px;
  }
`;

export const TransactionsGrid = styled.div<TransactionsAssetProps>`
  @media (max-width: ${sizes.M}) {
    padding: 0 20px;
  }
`;

export const TransactionsGridHeader = styled.div<TransactionsAssetProps>`
  display: grid;
  grid-template-columns: 52% 38% 10%;

  > div {
    text-align: start;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #8393a8;
    font-family: BasierCircle;
    width: 100%;
  }
  > div:last-child {
    padding-left: 15px;
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 50% 30% 20%;
  }
`;

export const TransactionsGridRow = styled.div<TransactionsAssetProps>`
  border-bottom: 1px solid #d6dadf;
`;

export const TransactionsGridContent = styled.div<TransactionsAssetProps>`
  display: grid;
  grid-template-columns: 52% 38% 10%;
  grid-template-rows: auto;
  grid-template-areas:
    'amount type actions'
    'payment payment payment';
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;

    width: 100%;
  }
  > div:nth-child(2) {
    color: #263a54;
    font-family: BasierCircle-Medium;
    font-size: 15px;
    margin-top: 23px;
    justify-content: flex-start;
  }
  > div:nth-child(3) {
    color: #1c4d8e;
    flex-direction: row;
    align-items: center;
    font-family: BasierCircle-Medium;
    margin-top: 23px;
    align-items: flex-start;
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 50% 30% 20%;
  }
`;

export const TransactionsGridDetails = styled.div<TransactionsAssetProps>`
  display: grid;
  grid-template-columns: 52% 38% 10%;
  margin-bottom: 24px;

  @media (max-width: ${sizes.M}) {
    grid-template-columns: 50% 30%;
  }
`;

export const TransactionsDetails = styled.div<TransactionsAssetProps>`
  > div {
    font-size: 13px;
    color: ${colors.new_gray};
  }
  > div:nth-child(2) {
    margin-top: 5px;
    font-size: 14px;
    color: ${colors.rhino_black};
  }
`;

export const EmptyTransactionHistory = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin: 23px 0;
  display: flex;
  justify-content: center;
`;
