import { IItem } from '@pickles/shared/models';
import { itemActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FC } from 'react';
import { ItemHooks } from '../../app/redux/hooks';
import { useDispatch } from 'react-redux';
import { BackButton } from '../Shared/Button/BackButton';
import { HeartIcon } from '../Shared/Icons';
import { IconsWrapper, LikeIconWrapper, ShareIconWrapper, TopLineWrapper } from './style';
import shareIcon from '/public/icons/shareResultsIcon.svg';

interface ITopWrapper {
  item: IItem.Item;
  onSharePress?: () => void;
  isRunningLiveAuction?: boolean;
}

const DynamicWebAgora = dynamic(() => import('../AuctionDetails/WebAgora'), {
  ssr: false,
});

const TopWrapper: FC<ITopWrapper> = ({ item, onSharePress, isRunningLiveAuction }) => {
  const dispatch = useDispatch();
  const isFavorite = ItemHooks.useItemFavorite(item.id);

  const onLikeIconClickHandler = () => {
    dispatch(itemActions.setFavorite(item.id, !isFavorite, item.saleEvent?.id));
  };

  return (
    <TopLineWrapper id="top-line-wrapper">
      <div>
        <BackButton />
      </div>
      <IconsWrapper id="icons-wrapper">
        <ShareIconWrapper onClick={onSharePress}>
          <Image src={shareIcon} alt="share" />
        </ShareIconWrapper>
        {isRunningLiveAuction ? (
          <DynamicWebAgora />
        ) : (
          <LikeIconWrapper onClick={onLikeIconClickHandler}>
            <HeartIcon fillColor={isFavorite ? colors.blumine_main_blue : 'none'} />
          </LikeIconWrapper>
        )}
      </IconsWrapper>
    </TopLineWrapper>
  );
};

export default TopWrapper;
