import { useRouter } from 'next/router';
import React, { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { WEBAPP_AUCTION_ROUTE_PATH, WEBAPP_ITEM_ROUTE_PATH } from '@pickles/shared/config';
import { IItem } from '@pickles/shared/models';
import { isEbidAuctionRunning } from '@pickles/shared/models/helpers';
import { itemActions } from '@pickles/shared/redux/actions';
import { SUPPORT_PHONE } from '@pickles/shared/utils/config';

import { AppHooks, DashboardHooks, ItemHooks, LiveAuctionHooks, UserHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import useGoBack from '../../hooks/useGoBack';
import { getDynamicLInk } from '../../util/dynamicLink';
import ItemsListing from '../AuctionDetails/ItemsListing';
import MetaTags from '../Metatags';
import PDFPortal from '../Portal/PDFPortal';
import ShareModalPortal from '../Portal/ShareModalPortal';
import Container from '../Shared/Container';
import MobileHeaderLine from '../Shared/MobileHeaderLine';
import ScrollToTop from '../Shared/ScrollToTop';
import AddToWatchListComponent from './AddToWatchListComponent';
import BidHistoryModal from './components/BidHistoryComponent';
import ConnectAccountComponent from './components/ConnectAccountComponent/ConnectAccountComponent';
import ImgGallery from './components/ImageGallery';
import PaymentHistory from './components/ShortDetail/PaymentHistory';
import SupportFooter from './SupportFooter';
import EbidRunning from './EbidRunning';
import LiveRunning from './LiveRunning';
import MainContentBlock from './MainContentBlock';
import SideContentBlock from './SideContentBlock';
import { CarCountText, CarDetailWrapper, ContentWrapper } from './style';
import TopWrapper from './TopWrapper';
import SimplifiedEbidItem from './SimplifiedEbidItem';
import SimplifiedLiveItem from './SimplifiedLiveItem';

interface ItemDetailContentsProps {
  item: IItem.Item;
  items: IItem.Item[];
  handleItemSelected?: (item: IItem.Item) => void;
}

const ItemDetailContents: FC<ItemDetailContentsProps> = ({ item, items, handleItemSelected }) => {
  const router = useRouter();
  const goBack = useGoBack();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sharedUrl, setSharedUrl] = useState<string>('');
  const [selectedImg, setSelectedImg] = useState<number | null>(null);
  const [isSharedModalOpen, setIsSharedModalOpen] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { itemRef, auctionRef } = router.query;
  const { isTablet, isMobile } = useScreenSize();
  const [isImgGalleryOpen, setIsImgGalleryOpen] = useState(false);
  const [isBidHistoryOpen, setIsBidHistoryOpen] = useState(false);
  const isLiveAuctionPage = router.asPath.endsWith('/live');

  const account = UserHooks.useUserAccount();
  const now = AppHooks.useApplicationTimer();
  const isFavorite = item.favorite?.favorite;
  const auctionStatus = LiveAuctionHooks.useAuctionStatus();
  const auction = DashboardHooks.useAuction(item.saleEvent?.id);
  const isLiveAuctionRunning = auctionStatus?.Status === 'RUNNING' && auctionStatus?.Running === 1;
  const isLiveAuctionWaiting = !!(
    item.isLive &&
    isLiveAuctionPage &&
    auction?.simulcastLive &&
    !isLiveAuctionRunning
  );

  const { isAuctionStarted } = ItemHooks.useComputedValues(item?.id);

  const handleOpenBidHistory = useCallback(() => {
    setIsBidHistoryOpen((prevVal) => !prevVal);
  }, []);

  const renderComponent = useCallback(() => {
    if (account === null) {
      return <ConnectAccountComponent />;
    }

    if (item.purchase && setLink && setIsViewerOpen) {
      return <PaymentHistory item={item} setLink={setLink} setIsViewerOpen={setIsViewerOpen} />;
    }

    if (item.isLive) {
      return <SimplifiedLiveItem item={item} />;
    }

    if (item.isEbid && isAuctionStarted) {
      return <SimplifiedEbidItem item={item} />;
    }

    return <AddToWatchListComponent itemId={item.id} />;

    // TODO: If auction ended, return SaleEndedComponent (offer/sold/nosale)
  }, [account, isAuctionStarted, item]);

  const onBackBtnClick = useCallback(() => {
    if (isLiveAuctionPage) {
      return router.replace(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}`);
    }

    goBack();
  }, [router, goBack]);

  const onLikeBtnClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(itemActions.setFavorite(item.id, !isFavorite, item.saleEvent?.id));
    },
    [isFavorite, dispatch, item],
  );

  if (isImgGalleryOpen) {
    return (
      <ImgGallery
        backBtnHandler={() => {
          setIsImgGalleryOpen(false);
        }}
        isImgGalleryOpen={isImgGalleryOpen}
        images={item.images}
        isMobile={isMobile}
        setSelectedImg={setSelectedImg}
        selectedImg={selectedImg}
      />
    );
  }

  const openShareModal = async () => {
    let link = '';
    if (!!itemRef) {
      link = `/${WEBAPP_ITEM_ROUTE_PATH}/${itemRef}`;
    } else if (!!auctionRef) {
      link = `/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}`;
    }
    const url = await getDynamicLInk(link);
    setSharedUrl(url);
    setIsSharedModalOpen(true);
  };

  const handleContactPress = () => {
    window.location.href = `tel:${SUPPORT_PHONE}`;
  };

  return (
    <div>
      <ScrollToTop />
      <MetaTags title={item.name} image={item.image!} />
      <CarDetailWrapper id="car-detail-wrapper">
        {isMobile ? (
          <MobileHeaderLine
            onBackBtnClick={onBackBtnClick}
            title={item.name}
            hasLikeBtn={true}
            onLikeBtnClick={onLikeBtnClick}
            onShareBtnClick={openShareModal}
            isItemSelected={isFavorite}
            isRunningLiveAuction
          />
        ) : (
          <TopWrapper
            item={item}
            onSharePress={openShareModal}
            isRunningLiveAuction={isLiveAuctionRunning}
          />
        )}
        <Container>
          <ContentWrapper>
            <MainContentBlock
              item={item}
              imgGalleryOpenHandler={() => {
                setIsImgGalleryOpen(true);
              }}
              isMobile={isMobile}
              setIsViewerOpen={setIsViewerOpen}
              openBidHistory={handleOpenBidHistory}
              setLink={setLink}
              isLiveAuctionWaiting={isLiveAuctionWaiting}
              setSelectedImg={setSelectedImg}
              selectedImg={selectedImg}
            />

            {!isTablet ? (
              <SideContentBlock
                itemId={item.id}
                items={items}
                openBidHistory={handleOpenBidHistory}
                biddingComponent={renderComponent()}
                handleChooseAuctionItem={handleItemSelected}
                isFromLiveAuctionPage={isLiveAuctionPage}
                isLiveAuctionWaiting={isLiveAuctionWaiting}
              />
            ) : (
              renderComponent()
            )}
          </ContentWrapper>
        </Container>
      </CarDetailWrapper>

      {isTablet && (
        <Container pb="180px" id="contact-us">
          <SupportFooter onContactPress={handleContactPress}/>
          {items && items.length > 0 && (
            <> 
              <Container alignItems="flex-start" px={isMobile ? '16px' : '50px'}>
                <CarCountText>{t('titles:vehicle_list')}</CarCountText>
              </Container>
              <ItemsListing items={items} onClickItem={handleItemSelected} />
            </>
          )}
        </Container>
      )}

      {isBidHistoryOpen && (
        <BidHistoryModal itemId={item.id} onClose={handleOpenBidHistory} isMobile={isMobile} />
      )}

      <ShareModalPortal
        url={sharedUrl}
        onClickClose={() => setIsSharedModalOpen(false)}
        isOpen={isSharedModalOpen}
      />

      {!!link.length && isViewerOpen && (
        <PDFPortal
          fileLocation={link}
          onClickClose={() => {
            setIsViewerOpen(false);
            setLink('');
          }}
        />
      )}
    </div>
  );
};

export default ItemDetailContents;
