import { IItem } from '@pickles/shared/models';
import { itemActions, liveAuctionActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { EbidAuctionHooks, UserHooks } from '../../app/redux/hooks';
import sizes from '../../util/sizes';
import {
  BalanceCountText,
  BalanceWrapper,
  BidBalanceText,
  BidComponentBtn,
  BidComponentBtnComment,
  BidComponentTitle,
  BidComponentWrapper,
  BiddingContainer,
  BiddingInputActionContainer,
} from './BidComponent.style';
import BidNotification from './BidNotification';
import CustomBidModal from './CustomBidModal';

interface SetMaxBidProps {
  item: IItem.Item;
}

// Set to true when require blocking deposit for Set Max AutoBid and displaying balance value
const BLOCK_DEPOSIT_FOR_SET_MAX_BID: boolean = false;

const SetMaxBid: FC<SetMaxBidProps> = ({ item }) => {
  const { t } = useTranslation();
  const calculator = EbidAuctionHooks.useCalculator();
  const dispatch = useDispatch();
  const router = useRouter();
  const amount = item.prebid?.amount || 0;
  const initial = calculator.increase(item.prebid?.amount || 0);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState<number>(initial);

  const account = UserHooks.useUserAccount();
  const balance = account?.availableBalance || 0;
  const isBalanceSufficient = balance >= item.depositAmt;

  useEffect(() => {
    dispatch(itemActions.getPrebids());
  }, [dispatch]);

  useEffect(() => {
    setValue(calculator.increase(amount));
  }, [amount]);

  const onPlusPress = () => {
    setValue(calculator.increase(value));
  };

  const onMinusPress = () => {
    const newValue = calculator.decrease(value);
    if (newValue >= initial) {
      setValue(newValue);
    }
  };

  const onClickSetMaxBid = () => {
    if (item && item.saleEvent && (item.prebid ? value > item.prebid?.amount : value >= initial)) {
      dispatch(liveAuctionActions.addItemPrebid(item.id, item.saleEvent?.id, value));
      setValue(calculator.increase(value));
    }
  };

  const onClickCustomBid = () => {
    if (isBalanceSufficient) {
      setModalVisible(true);
    }
  };

  const renderBiddingButton = () => {
    const showTopUp = BLOCK_DEPOSIT_FOR_SET_MAX_BID && !isBalanceSufficient;
    switch (true) {
      case showTopUp:
        return (
          <BidComponentBtn
            id="bid-btn"
            variant={showTopUp ? 'disabled' : 'normal'}
            onClick={() => router.push('/profile')}
          >
            <BidComponentBtnComment>{t('labels:insufficient_balance')}</BidComponentBtnComment>
            {t('buttons:top_up')}
          </BidComponentBtn>
        );
      default:
        return (
          <BidComponentBtn id="max-bid-btn" variant="normal" onClick={onClickSetMaxBid}>
            {item.prebid?.amount ? t('buttons:update_max_bid') : t('buttons:set_max_bid')}
          </BidComponentBtn>
        );
        return;
    }
  };

  return (
    <BiddingContainer>
      <BidNotification itemId={item.id} />
      <Wrapper>
        <BidComponentTitle>{t('fields:place_your_bid')}</BidComponentTitle>
        <BiddingInputActionContainer>
          <InputWrapper>
            <LabelText>{t('labels:bid_this_amount')}</LabelText>
            <BidInput
              type="number"
              value={value}
              onClick={() => onClickCustomBid()}
              onChange={(e) => {
                const value = e.target.value.replace(',', '');
                setValue(Number(value));
              }}
            />
            {isBalanceSufficient && (
              <>
                <BidInputBtn onClick={onMinusPress} isLeft={true}>
                  -
                </BidInputBtn>
                <BidInputBtn onClick={onPlusPress}>+</BidInputBtn>
              </>
            )}
          </InputWrapper>
          {renderBiddingButton()}
        </BiddingInputActionContainer>
        {BLOCK_DEPOSIT_FOR_SET_MAX_BID && (
          <BalanceBadgeContainer id="balance-badge-container">
            <BalanceWrapper id="balance-wrapper">
              <BidBalanceText>{t('labels:_balance')}</BidBalanceText>
              <BalanceCountText id="balance-label">
                {numberToAmountLabel(balance || 0)}
              </BalanceCountText>
            </BalanceWrapper>
          </BalanceBadgeContainer>
        )}
      </Wrapper>

      <CustomBidModal item={item} visible={modalVisible} setModalVisible={setModalVisible} />
    </BiddingContainer>
  );
};

export default SetMaxBid;

const Wrapper = styled(BidComponentWrapper)`
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  @media (max-width: ${sizes.M}) {
    padding: 0;
    flex-direction: row;
  }
`;

const Title = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 24px;
  color: ${colors.white};
  margin-bottom: 8px;
  line-height: 40px;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 52px;
  margin-bottom: 16px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
    /* margin-right: 25px; */
  }
`;

const LabelText = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-family: BasierCircle-Medium;
  font-size: 11px;
  color: #596e8a;
`;

const BidInput = styled.input`
  width: 100%;
  height: 52px;
  background: ${colors.white};
  border-radius: 56px;
  margin-bottom: 16px;
  outline: none;
  border: none;
  padding: 8px 44px;
  text-align: center;
  padding-top: 21px;
  font-family: BasierCircle-Semibold;
  font-size: 20px;
  color: ${colors.blumine_main_blue};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

interface IBidInputBtn {
  isLeft?: boolean;
  isInActive?: boolean;
}

const BidInputBtn = styled.div<IBidInputBtn>`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ isLeft }) => (isLeft ? 'left: 8px' : 'right: 8px')};
  color: ${colors.blumine_main_blue};
  font-family: BasierCircle-Medium;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.catskill_white};
  border-radius: 50%;
  cursor: ${({ isLeft, isInActive }) => (isLeft && isInActive ? '' : 'pointer')};
  opacity: ${({ isLeft, isInActive }) => (isLeft && isInActive ? '0.7' : '1')};
`;

const SetMaxBidBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${colors.cerulean_blue};
  border-radius: 8px;
  font-family: BasierCircle-Semibold;
  font-size: 16px;
  height: 44px;
  cursor: pointer;
  text-indent: 7.5px;
  user-select: none;
  white-space: nowrap;
`;

const BalanceBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 150px;
  align-self: center;

  @media (max-width: ${sizes.M}) {
    min-width: 120px;
    order: 1;

    & ${BalanceWrapper} {
      flex-direction: column;
    }
  }

  @media (max-width: ${sizes.S}) {
    display: none;
  }
`;
