import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../../util/sizes';

export const StockAndLotWrapper = styled.div`
  font-family: BasierCircle;
  display: flex;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.san_juan_grey};
  white-space: nowrap;

  span {
    font-family: BasierCircle-Medium;
    font-size: 12px;
  }

  @media (max-width: ${sizes.XL}) {
    flex-direction: column;
    gap: 0;
  }
`;
