import { Dispatch, FC, SetStateAction } from 'react';
import TopUpContent from '../Profile/TopUpContent';
import { BaseModal } from '../Shared/Modal';

interface ITopUpModalProps {
  isMobile: boolean;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const TopUpModal: FC<ITopUpModalProps> = ({ isMobile, isOpened, setIsOpened }) => {
  return (
    <BaseModal isOpened={isOpened} onClickOutside={() => setIsOpened(false)}>
      <TopUpContent isMobile={isMobile} isOpened={isOpened} closeIconHandler={() => setIsOpened(false)} />
    </BaseModal>
  );
};
