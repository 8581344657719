import styled from 'styled-components';

import colors from '@pickles/shared/utils/colors';
import { smooth } from '../../styles/animations';
import sizes from '../../util/sizes';
import Container from '../Shared/Container';

interface IBidComponentInputBtn {
  isLeft?: boolean;
  isInActive?: boolean;
}
interface IBidBalanceText {
  hasLeftMargin?: boolean;
}

interface IBidComponentWrapper {
  isFromLiveAuction?: boolean;
}

export const BidComponentWrapper = styled.div<IBidComponentWrapper>`
  min-height: ${({ isFromLiveAuction }) => (isFromLiveAuction ? '402px' : 'initial')};
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, ${colors.blumine_main_blue} 0%, ${colors.blue_zodiak} 100%);
  width: 100%;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 60px;
  position: relative;
  z-index: 2;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Wrapper = styled.div`
  padding: 24px 24px 0;
  width: 100%;

  @media (max-width: ${sizes.M}) {
    display: flex;
    padding: 0;
    order: 2;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const BidComponentTitle = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 24px;
  color: ${colors.white};
  margin-bottom: 8px;
  line-height: 40px;

  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;

export const BidComponentInputWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 52px;
  margin-bottom: 16px;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
    margin-right: 25px;
    max-width: 200px;
  }
`;

export const BidComponentInput = styled.input`
  width: 100%;
  height: 52px;
  background: ${colors.white};
  border-radius: 56px;
  margin-bottom: 16px;
  outline: none;
  border: none;
  padding: 8px 44px;
  text-align: center;
  padding-top: 21px;
  font-family: BasierCircle-Semibold;
  font-size: 20px;
  color: ${colors.blumine_main_blue};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  @media (max-width: ${sizes.M}) {
    /* max-width: 200px; */
  }
`;

export const BidComponentInputBtn = styled.div<IBidComponentInputBtn>`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ isLeft }) => (isLeft ? 'left: 8px' : 'right: 8px')};
  color: ${colors.blumine_main_blue};
  font-family: BasierCircle-Medium;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.catskill_white};
  border-radius: 50%;
  cursor: ${({ isLeft, isInActive }) => (isLeft && isInActive ? '' : 'pointer')};
  opacity: ${({ isLeft, isInActive }) => (isLeft && isInActive ? '0.7' : '1')};
`;

export const BidComponentText = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-family: BasierCircle-Medium;
  font-size: 11px;
  color: #596e8a;
  white-space: nowrap;
`;

interface IBidComponentBtn {
  variant: 'normal' | 'disabled' | 'hint';
  fullWidth?: boolean;
}

export const BidComponentBtn = styled.div<IBidComponentBtn>`
  width: 100%;
  height: 56px;
  background: ${({ variant }) =>
    variant === 'hint'
      ? colors.white_005
      : variant === 'disabled'
      ? colors.bali_hai_grey
      : colors.cerulean_blue};
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  border-radius: 8px;
  margin-bottom: 16px;
  border: ${({ variant }) => (variant === 'hint' ? `1.5px dashed ${colors.white_04}` : null)};
  pointer-events: ${({ variant }) => (variant === 'disabled' ? 'none' : '')};
  cursor: ${({ variant }) => (variant === 'normal' ? 'pointer' : '')};
  user-select: none;
  text-align: center;

  @media (max-width: ${sizes.M}) {
    margin-bottom: 0;
    max-width: 180px;
    height: ${({ fullWidth }) => (fullWidth ? '44px' : '52px')};
  }

  @media (max-width: ${sizes.S}) {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 0;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '180px')};
  }
`;

export const BidComponentBtnComment = styled.div`
  font-weight: 600;
  font-size: 15px;
  /* line-height: 14px; */
`;

export const BidComponentDownWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: ${colors.astronaut};
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  padding: 15px 24px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${sizes.M}) {
    width: 100%;
    // padding: 0;
    height: auto;
    flex-shrink: 0;
  }
`;

export const BidBalanceWrapper = styled.div`
  margin-bottom: 16px;
  padding: 0 50px;
  width: 100%;

  @media (max-width: ${sizes.M}) {
    padding: 0;
    /* width: 50%; */
    order: 1;
    margin-bottom: 0;
    max-width: 200px;
  }

  @media (max-width: ${sizes.S}) {
    flex: 1;
  }
`;

export const BidBalanceText = styled.div<IBidBalanceText>`
  font-family: BasierCircle;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '6' : '0')}px;

  @media (max-width: ${sizes.M}) {
    font-size: 13px;
    margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '5' : '0')}px;
  }
`;

export const ExtraInfoText = styled.div<IBidBalanceText>`
  font-family: BasierCircle;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '6' : '0')}px;

  @media (max-width: ${sizes.M}) {
    font-size: 13px;
    margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '5' : '0')}px;
  }
`;
export const ExtraInfoRightText = styled.div`
  font-family: BasierCircle;
  font-size: 11px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`;

export const BalanceCountText = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  text-align: center;
  color: ${colors.white};
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  /* @media (max-width: ${sizes.M}) {
    flex-wrap: wrap; 
  }*/
`;

export const DeleteIconWrapper = styled.div`
  position: absolute;
  right: -18px;
  cursor: pointer;

  @media (max-width: ${sizes.M}) {
    left: -15px;
  }
`;

export const BidWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const AcceptedInfo = styled.div`
  position: absolute;
  left: 12px;
  top: 20px;
  z-index: 1;
  height: 34px;
  width: calc(100% - 24px);
  background: ${colors.christi_green};
  color: ${colors.white};
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 36px;
  border-radius: 7px;
  animation: ${smooth} 0.5s ease-in forwards;
`;

export const ImageWrapper = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
`;

export const BtnMessage = styled.div`
  width: 100%;
  height: 56px;
  border: 1.5px dashed rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 19px;
  font-family: BasierCircle-Semibold;
  margin-bottom: 31px;
`;

export const BiddingContainer = styled(Container)`
  @media (max-width: ${sizes.M}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
`;

export const BiddingInputActionContainer = styled(Container)`
  @media (max-width: ${sizes.M}) {
    display: flex;
    order: 2;
    flex-direction: row;
    padding: 10px 16px;
    column-gap: 30px;
    justify-content: space-between;
  }

  @media (max-width: ${sizes.S}) {
    column-gap: 20px;
  }
`;
