import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserHooks } from '../../app/redux/hooks';
import MobileHeaderLine from '../Shared/MobileHeaderLine';

import { TransactionsGrid } from './Transactions.style';
import {
  ProfileDetailsRowHeader,
  ProfileDetailsRowContent,
  ProfileDetailRow,
  ProfileDetailsWrapper,
} from './ProfileDetails.style';

interface IProfileDetails {
  closeScreen?: () => void;
  isMobile?: boolean;
}

export const ProfileDetails: FC<IProfileDetails> = ({ isMobile = false, closeScreen }) => {
  const user = UserHooks.useUserAccount();
  const { t } = useTranslation();
  const profileFields = [
    {
      key: 'username',
      field: user?.username,
      label: t('fields:username'),
    },
    {
      key: 'name',
      field: user?.lastName,
      label: t('fields:name'),
    },
    {
      key: 'email',
      field: user?.email,
      label: t('fields:email_address'),
    },
    {
      key: 'passport',
      field: user?.govId,
      label: t('fields:passport_number'),
    },
    {
      key: 'phone',
      field: user?.phone,
      label: t('fields:mobile_number'),
    },
  ];

  const profileDetailsElements = profileFields.map((detail) => (
    <ProfileDetailRow key={detail.key}>
      <ProfileDetailsRowHeader>{detail.label}</ProfileDetailsRowHeader>
      <ProfileDetailsRowContent>{detail.field}</ProfileDetailsRowContent>
    </ProfileDetailRow>
  ));

  return (
    <>
      {isMobile && closeScreen && (
        <MobileHeaderLine hasShareBtn={false} onBackBtnClick={closeScreen} title={'User details'} />
      )}
      <TransactionsGrid>
        <ProfileDetailsWrapper>{profileDetailsElements}</ProfileDetailsWrapper>
      </TransactionsGrid>
    </>
  );
};
