import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../../../util/sizes';

export const DescriptionRow = styled.tr`
  font-family: BasierCircle-Medium;
  /* width: 280px; */
  @media (max-width: ${sizes.M}) {
    width: 50%;
  }
`;

export const DescriptionContentLabel = styled.td`
  text-align: left;
  font-size: 14px;
  flex-direction: flex-start;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.new_gray};
  padding-top: 8px;
  padding-right: 10px;
  width: 160px;
`;

export const DescriptionContentValue = styled.td`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.rhino_black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
