import { IItem } from '@pickles/shared/models';
import { liveAuctionActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { BidMessageType } from '@pickles/shared/utils/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ItemHooks, LiveAuctionHooks } from '../../app/redux/hooks';
import Container from '../Shared/Container';
import { DeleteIcon, OutbidFilledIcon, WinningFilledIcon } from '../Shared/Icons';
import Typography from '../Shared/Typography';

interface IBidNotification {
  itemId: number;
  isCurrentLiveItem?: boolean;
}

type TMessageBannerColor = 'green' | 'lightBlue' | 'deepBlue' | 'blue' | 'red' | 'orange';

const renderWinningIcon = () => {
  return (
    <ImageWrapper>
      <WinningFilledIcon />
    </ImageWrapper>
  )
}

const renderOutbidIcon = () => {
  return (
    <ImageWrapper>
      <OutbidFilledIcon />
    </ImageWrapper>
  )
}

const BidNotification: FC<IBidNotification> = ({ itemId, isCurrentLiveItem }) => {
  type TMessageObj = {
    message: ReturnType<typeof t> | null;
    color?: TMessageBannerColor;
    icon: ReturnType<typeof renderWinningIcon> | ReturnType<typeof renderOutbidIcon> | null;
    label: string | null;
    value: ReturnType<typeof numberToAmountLabel> | null;
  };

  const item = ItemHooks.useItem(itemId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auction = LiveAuctionHooks.useAuctionStatus();
  const liveItem = LiveAuctionHooks.useCurrentItem();
  const [auctioneerMessage, setAuctioneerMessage] = useState<string | null>();

  const {
    isAuctionEnded,
    isOffer,
    isSold,
    getBidMessage,
    displayBidAmount,
    insufficientBalanceAmount,
  } = ItemHooks.useComputedValues(itemId);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (auction?.StatusMessage) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setAuctioneerMessage(null);
      setAuctioneerMessage(auction?.StatusMessage);
      timeoutId = setTimeout(() => {
        setAuctioneerMessage(null);
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [auction]);

  const showPrebid = !!item?.prebid && !item.bidding?.hasBids && !isCurrentLiveItem;
  const userHasBids = item?.bidding?.hasBids || item?.prebid || (!isCurrentLiveItem && item?.offer);
  const showMessage = (item?.isLive && userHasBids) || item?.isEbid;

  // TODO: Below messageObj variable was created from AI-5242 changes. To determine if possible to refactor code for optimization and better readability
  const messageObj: TMessageObj = useMemo(() => {
    if (item?.isLive) {
      switch (true) {
        case item?.bidding?.sendingState?.isSending:
          return {
            message: t('labels:sending_bid'),
            color: 'lightBlue',
            icon: null,
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        case item?.bidding?.isWon:
          return {
            message: t('labels:bidding_won'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        case item?.bidding?.isWinning:
          return {
            message: t('labels:bidding_winning'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        case item?.bidding?.isOutbid ||
          item?.bidding?.isLost ||
          (item?.bidding?.isOffer && !item?.bidding?.isOfferWinner):
          return {
            message: t('captions:outbid'),
            color: 'red',
            icon: renderOutbidIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.lastBidAmount),
          };
        case item?.bidding?.isOfferWinner:
          return {
            message: t('captions:eligible_for_offer'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        case showPrebid:
          return {
            message: t('labels:your_prebid_accepted', {
              amount: numberToAmountLabel(item?.prebid?.amount),
            }),
            color: 'green',
            icon: renderWinningIcon(),
            label: null,
            value: null,
          };
        default:
          return {
            message: null,
            color: undefined,
            icon: null,
            label: null,
            value: null,
          };
      }
    } else {
      switch (getBidMessage()) {
        case BidMessageType.Winning: {
          return {
            message: t('captions:highest_bidder'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        }
        case BidMessageType.Outbid: {
          return {
            message: t('captions:outbid'),
            color: 'red',
            icon: renderOutbidIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.lastBidAmount),
          };
        }
        case BidMessageType.LateBid: {
          return {
            message: t('captions:late_bid_placed'),
            color: 'red',
            icon: renderOutbidIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.rejectedBid?.rejectedBidAmount),
          };
        }
        case BidMessageType.BeFirstBidder: {
          return {
            message: t('captions:be_first_bidder'),
            color: 'lightBlue',
            icon: null,
            label: null,
            value: null,
          };
        }
        case BidMessageType.StartBidding: {
          return {
            message: t('captions:start_bidding'),
            color: 'lightBlue',
            icon: null,
            label: null,
            value: null,
          };
        }
        case BidMessageType.SendingBid: {
          return {
            message: t('labels:sending_bid'),
            color: 'lightBlue',
            icon: null,
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(displayBidAmount),
          };
        }
        case BidMessageType.SendingBidDelay: {
          return {
            message: t('captions:send_bid_delay'),
            color: 'red',
            icon: null,
            label: null,
            value: null,
          };
        }
        case BidMessageType.TopUp: {
          return {
            message: t('captions:top_up', {amountToTopUp: numberToAmountLabel(insufficientBalanceAmount)}),
            color: 'orange',
            icon: null,
            label: null,
            value: null,
          };
        }
        case BidMessageType.PendingResult: {
          return {
            message: t('captions:updating_result'),
            color: 'lightBlue',
            icon: null,
            label: null,
            value: null,
          };
        }
        case BidMessageType.YouWin: {
          return {
            message: t('captions:eligible_to_buy'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        }
        case BidMessageType.Offer:
          return {
            message: t('captions:eligible_for_offer'),
            color: 'green',
            icon: renderWinningIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.currentBidAmount),
          };
        case BidMessageType.Lost: {
          return {
            message: t('captions:outbid_by_someone'),
            color: 'red',
            icon: renderOutbidIcon(),
            label: `${t('labels:your_bid')}:`,
            value: numberToAmountLabel(item?.bidding?.lastBidAmount),
          };
        }
        case BidMessageType.CheckAuctions: {
          return {
            message: t('captions:check_auctions'),
            color: 'lightBlue',
            icon: null,
            label: null,
            value: null,
          };
        }
        default:
          return {
            message: null,
            color: undefined,
            icon: null,
            label: null,
            value: null,
          };
      }

    }
  }, [
    itemId,
    item?.bidding?.isLost,
    item?.bidding?.isOutbid,
    item?.bidding?.sendingState?.isSending,
    item?.bidding?.sendingState?.hasExceededDuration,
    item?.bidding?.isWinning,
    item?.bidding?.isWon,
    item?.bidding?.isOffer,
    item?.bidding?.isOfferWinner,
    item?.bidding?.isEnded,
    item?.bidding?.currentBidAmount,
    item?.bidding?.lastBidAmount,
    item?.prebid,
    showPrebid,
    isAuctionEnded,
    insufficientBalanceAmount,
    t,
  ])

  const onClickRemoveMaxBid = () => {
    if (item && item.prebid) {
      dispatch(liveAuctionActions.removeItemPrebid(item.id, item.prebid.id));
    }
  };

  if (item?.bidding?.isNoSale) {
    return null;
  }

  return (
    <Container
      padding="0 12px"
      flexDirection="column"
      height="78px"
      justifyContent="flex-end"
      id="bid-notification"
    >
      {showMessage && messageObj?.message && (
        <BidNotificationComponentStyle color={messageObj?.color} id={'bid_notify_bar'}>
          <Wrap>
            {messageObj?.icon}
            <Typography
              variant="body2"
              color="white"
              style={{ lineHeight: '100%' }}
              id={'bid_notify_bar_msg'}
            >
              {messageObj?.message}
            </Typography>
          </Wrap>
          {showPrebid && (
            <MaxBidRemoveButton onClick={onClickRemoveMaxBid}>
              <DeleteIcon width={16} height={16} strokeColor={colors.white} />
            </MaxBidRemoveButton>
          )}
          {messageObj?.label && messageObj?.value && (
            <Wrap>
              <YourBidText id={'bid_notify_bar_label'}>{messageObj?.label}</YourBidText>
              <PriceText id={'bid_notify_bar_value'}>{messageObj?.value}</PriceText>
            </Wrap>
          )}
        </BidNotificationComponentStyle>
      )}
      {auctioneerMessage && (
        <Container
          style={{
            background: colors.rhino_black,
            borderRadius: `${userHasBids ? '0' : '7px 7px 0 0'}`,
            color: colors.white_07,
          }}
          alignItems="flex-start"
          padding="10px 12px"
        >
          {auctioneerMessage}
        </Container>
      )}
    </Container>
  );
};

export default BidNotification;

interface IBidNotificationStyle {
  color?: TMessageBannerColor;
}

const BidNotificationComponentStyle = styled.div<IBidNotificationStyle>`
  width: 100%;
  padding: 10px 12px 9px;
  background: ${({ color }) => {
    switch (color) {
      case 'red':
        return colors.cerise_red;
      case 'green':
        return colors.christi_green;
      case 'deepBlue':
        return colors.rhino_black;
      case 'lightBlue':
        return colors.lightBlue;
      case 'orange':
        return colors.orange;
      default:
        return color;
    }
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* z-index: -1; */
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;

const YourBidText = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 3px;
`;

const PriceText = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  color: ${colors.white};
`;

const Wrap = styled.div`
  display: flex;
`;

const MaxBidRemoveButton = styled(Container)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${colors.white_02};
`;
