import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import useScreenSize from '../../../hooks/useScreenSize';
import sizes from '../../../util/sizes';
import Container from '../../Shared/Container';
import { aboutUs, ArrowRightIcon, auctionIcon, sellWithUs, services } from '../../Shared/Icons';

import {
  IconWrapper,
  LinkTitle,
  LinkWrapper,
  UsefulLink,
  UsefulLinksContainer,
  UsefulLinksHeader,
} from './style';

const usefulLinks = [
  {
    title: 'Auctions',
    route: '/auctions',
    icon: auctionIcon,
  },
  {
    title: 'Sell with us',
    route: '/sell',
    icon: sellWithUs,
  },
  {
    title: 'Services',
    route: '/services',
    icon: services,
  },
  {
    title: 'About us',
    route: '/about-us',
    icon: aboutUs,
  },
];

const UsefulLinks = () => {
  const { t } = useTranslation();
  const { maxWidth } = useScreenSize();
  const router = useRouter();

  return (
    <Container maxWidth={sizes.XL} mb={maxWidth.s ? 0 : '50px'}>
      <UsefulLinksHeader>{t('labels:useful_links')}</UsefulLinksHeader>
      <UsefulLinksContainer>
        {usefulLinks.map((item) => (
          <UsefulLink key={`useful-link-${item.title}`} onClick={() => router.push(item.route)}>
            <IconWrapper>
              <Image src={item.icon} alt={item.title} />
            </IconWrapper>

            <LinkWrapper>
              <LinkTitle>{item.title}</LinkTitle>
              <ArrowRightIcon height={13} />
            </LinkWrapper>
          </UsefulLink>
        ))}
      </UsefulLinksContainer>
    </Container>
  );
};

export default UsefulLinks;
