import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StockAndLotWrapper } from './style';
type PropsType = {
  stockNum?: string | number;
  lotNum?: string | number | null;
};
export const StockAndLot: FC<PropsType> = ({ stockNum, lotNum }) => {
  const { t } = useTranslation();

  return (
    <StockAndLotWrapper>
      <div>
        {t('labels:stock_number')} <span>{` ${stockNum || t('labels:n_a')}`}</span>
      </div>
      <div>
        • {t('labels:lot_number')} <span>{` ${lotNum || t('labels:n_a')}`}</span>
      </div>
    </StockAndLotWrapper>
  );
};
