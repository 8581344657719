import { FC } from 'react';
import { CSSProperties } from 'styled-components';

import { CustomTitle, ICustomTitleProps } from './styles';

export interface ITitleProps extends ICustomTitleProps {
  text: string;
  textAlign?: CSSProperties['textAlign'];
}

export const Title: FC<ITitleProps> = ({ text, ...rest }) => (
  <CustomTitle {...rest}>{text}</CustomTitle>
);
