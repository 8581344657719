import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';

export const PaymentHistoryWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const PaymentStatusBar = styled.div`
  width: 100%;
  padding: 9px 14.5px;
  border-radius: 6px;
  background: ${colors.cerise_red};
  font-family: BasierCircle;
  font-size: 15px;
  color: ${colors.white};
  margin-bottom: 10px;
  line-height: 1.4;
`;

export const PaymentHistoryBlock = styled.div`
  width: 100%;
  padding: 18px 39px 24px;
  background: ${colors.zircon_light_blue};
  border-radius: 12px;
  position: relative;
`;

export const StatusLine = styled.div`
  position: absolute;
  height: calc(100% - 12px);
  width: 2px;
  top: 8px;
  left: 20px;
  background: #d1dcf9;
`;

export const StatusText = styled.div`
  position: relative;
  margin-bottom: 10px;
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  color: ${colors.rhino_black};

  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 6px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${colors.cerise_red};
  }
`;

export const DateTextWrapper = styled.div`
  margin-bottom: 10px;
  width: 200px;
  display: flex;
  justify-content: space-between;
`;

export const FieldText = styled.div`
  font-family: BasierCircle;
  font-size: 14px;
  color: ${colors.san_juan_grey};
`;

export const ValueText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 14px;
  color: ${colors.san_juan_grey};
`;

export const IconWrapper = styled.div`
  margin-right: 10.5px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
`;

export const ViewTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
`;

export const ViewText = styled.div`
  font-family: BasierCircle-Semibold;
  color: ${colors.blumine_main_blue};
  font-size: 15px;
`;
